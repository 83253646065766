// @flow

export type ThemeType = {
  id: number,
  description: string,
  name: string,
  slug: string,
  page_liee: number,
  contact_lie: number,
  page_liee_link: string,
};

export type RawThemeType = {
  id: number,
  description: string,
  name: string,
  slug: string,
  meta: {
    page_liee: number,
    contact_lie: number,
  },
  page_liee_link: string,
};

export const nullTheme = {
  id: 0,
  description: '',
  name: '',
  slug: '',
  page_liee: 0,
  contact_lie: 0,
  page_liee_link: ''
};

export type QuestionType = {
  id: number,
  slug: string,
  title: string,
  content: string,
  meta_description: string,
  meta_title: string,
  theme: number,
  label: {
    id: number,
    name: string,
    slug: string,
  },
  link?: string,
  menu_order: number,
  typeFlow: string,
};

export type RawQuestionType = {
  id: number,
  slug: string,
  title: {
    raw: string
  },
  content: {
    rendered: string
  },
  meta_description: string,
  meta_title: string,
  label: {
    id: number,
    name: string,
    slug: string,
  },
  menu_order: number,
  theme: number[]
};

export const nullQuestion = {
  id: 0,
  slug: '',
  title: '',
  content: '',
  meta_description: '',
  meta_title: '',
  theme: 0,
  label: {
    id: 0,
    name: '',
    slug: '',
  },
  menu_order: 0,
  typeFlow: 'QuestionType',
};
