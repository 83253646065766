// @flow
import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import NewsletterApi from 'api/newsletter/NewsletterApi';
import { convertObjectToUrlEncoded } from 'utils/objectUtils';
import { postUserPref } from 'actions/userActions';
import { userHasSubscribedNewsletter } from 'actions/appActions';

import {
  FETCH_NEWSLETTER_OPTIONS_START,
  newsletterOptionsFetched,
  newsletterOptionsFetchError
} from 'actions/newsletterSubscriptionActions';
import { DISPLAY_MODAL } from "actions/modalActions";
import type {
  FETCH_NEWSLETTER_OPTIONS_START_ACTION,
  SUBSCRIBE_NEWSLETTER_START_ACTION
} from 'actions/newsletterSubscriptionActions';

import {
  SUBSCRIBE_NEWSLETTER_START,
  newsletterSubscribed,
  newsletterSubscribeError
} from 'actions/newsletterSubscriptionActions';
import { displayModal } from 'actions/modalActions';
import { increaseNewsletterPopInCount } from 'actions/appActions';

export default function(apiExecutor: ApiExecutorType) {
  const newsletterSubscriptionApi = new NewsletterApi(apiExecutor);

  return function* newsletterSaga(): GeneratorType {
    yield takeLatest(FETCH_NEWSLETTER_OPTIONS_START, fetchOptions);
    yield takeLatest(SUBSCRIBE_NEWSLETTER_START, subscribeToNewsletter);
    yield takeLatest(DISPLAY_MODAL, modalDisplayed);
  };

  function* fetchOptions(action: FETCH_NEWSLETTER_OPTIONS_START_ACTION): Saga<void> {
    try {
      const { campaignMonitorKey } = action.payload;
      const options = yield call(newsletterSubscriptionApi.fetchOptions, campaignMonitorKey);
      yield put(newsletterOptionsFetched(options));
    } catch (e) {
      yield put(newsletterOptionsFetchError());
    }
  }
  function* modalDisplayed(): Saga<void> {
    try {
      yield put(increaseNewsletterPopInCount());
    } catch (e) {
    }
  }

  function* subscribeToNewsletter(action: SUBSCRIBE_NEWSLETTER_START_ACTION): Saga<void> {
    try {
      const { subscription, campaignMonitorKey, typeNewsletter } = action.payload;
      const { Value: analyticsData } = action.payload.subscription.CustomFields[0];

      const request = yield call(
        newsletterSubscriptionApi.subscribeToNewsletter,
        convertObjectToUrlEncoded(subscription),
        campaignMonitorKey,
        typeNewsletter
      );

      if (request.error) {
        // Code 208 -> Email Address exists in unconfirmed list. Subscriber is not added.
        if (request.Code === 208) {
          return yield put(displayModal(21, {}, true));
        }

        return yield put(displayModal(2, {}, true));
      }

      yield put(newsletterSubscribed());
      yield put(userHasSubscribedNewsletter(true));

      if (action.payload.categories) {
        const { categories, token } = action.payload;
        yield put(postUserPref('change-newsletter', categories, token ? token : ''));
        return yield put(displayModal(1, { analyticsData }));
      }
      return yield put(displayModal(1, { analyticsData }));
    } catch (e) {
      yield put(displayModal(2, {}, true));
      return yield put(newsletterSubscribeError());
    }
  }
}
