// @flow
import type {
  RawStaffType,
  StaffType,
} from 'types/Staff';
import { nullStaff } from 'types/Staff';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertStaffFromApi(rawStaff: RawStaffType): StaffType {
  if (!rawStaff) {
    return nullStaff;
  }

  const {
    title,
    content,
    slug,
    featured_media,
    sidebar_content,
    meta: {
      dateNaissance,
      genre,
      numInternational,
      age,
    },
    link,
    meta_title,
    meta_description,
    poste,
    postes_groupe,
    nombre_selections,
    matchs_joues,
  } = rawStaff;

  return mergeIntoDefault(nullStaff, {
    title: title.raw,
    logo: typeof featured_media === 'number' ? null : featured_media.src,
    featured_media: featured_media,
    content: content.data,
    dateNaissance,
    genre,
    numInternational,
    age,
    poste: {id: poste.id, name: poste.name, ordre: poste.ordre},
    postes_groupe: {id: postes_groupe.id, name: postes_groupe.name, ordre: postes_groupe.ordre},
    sidebar: sidebar_content ? sidebar_content.data : [],
    slug,
    link,
    nombre_selections,
    matchs_joues,
    meta_title,
    meta_description,
  });
}
