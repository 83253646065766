// @flow
import React from 'react';
import { handleTagEvent, getGmtUserData }from 'utils/tagManagerUtils';
import type { liensRsType } from 'types/Settings';
import type { FullUserType } from 'types/User';
import NewsletterSubscriberContainer from 'containers/footer/NewsletterSubscriberContainer';

export type StateProps = {
  titleLigue: string,
  liens_rs: liensRsType,
  userPref: FullUserType
};

const FooterTop = (props: StateProps) => {
  const { 
    liens_rs: {
      fb_lien,
      twitter_lien,
      instagram_lien,
      youtube_lien
    },
    userPref
  } = props;
  if (fb_lien === '' && twitter_lien === '' && instagram_lien === '' && youtube_lien === '') {
    return null;
  }
  
  return (
    <div className="container footer__top">
      <div className="row">
        <div className="col col-lg-3">
          <h5 className="mb-1">Nous suivre</h5>
          <div className="mt-3 mb-5">
            {
              props.liens_rs.fb_lien &&
              <a
                className="link-icon is-dark"
                href={props.liens_rs.fb_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Facebook de ${props.titleLigue}`}
                onClick={handleTagEvent(
                  'footer',
                  'clic_icone_sociale',
                  `facebook ${userPref.profil.id === '' ? '' : `| ${getGmtUserData(userPref)}`}`
                )}>
                <i className="icon icon-facebook" />
              </a>
            }
            {
              props.liens_rs.twitter_lien &&
              <a
                className="link-icon is-dark"
                href={props.liens_rs.twitter_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Twitter de ${props.titleLigue}`}
                onClick={handleTagEvent(
                  'footer',
                  'clic_icone_sociale',
                  `twitter ${userPref.profil.id === '' ? '' : `| ${getGmtUserData(userPref)}`}`
                )}>
                <i className="icon icon-twitter" />
              </a>
            }
            {
              props.liens_rs.instagram_lien &&
              <a
                className="link-icon is-dark"
                href={props.liens_rs.instagram_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Instagram de ${props.titleLigue}`}
                onClick={handleTagEvent(
                  'footer',
                  'clic_icone_sociale',
                  `instagram ${userPref.profil.id === '' ? '' : `| ${getGmtUserData(userPref)}`}`
                )}>
                <i className="icon icon-instagram" />
              </a>
            }
            {
              props.liens_rs.youtube_lien &&
              <a
                className="link-icon is-dark"
                href={props.liens_rs.youtube_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Youtube de ${props.titleLigue}`}
                onClick={handleTagEvent(
                  'footer',
                  'clic_icone_sociale',
                  `youtube ${userPref.profil.id === '' ? '' : `| ${getGmtUserData(userPref)}`}`
                )}>
                <i className="icon icon-youtube" />
              </a>
            }
          </div>
        </div>
        <NewsletterSubscriberContainer />
      </div>
      <hr />
    </div>
  );
};

export default FooterTop;
