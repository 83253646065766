// @flow
import React, { PureComponent } from 'react';
import type { MenuElementType } from 'types/MenuElement';
import { Link } from 'react-router-dom';
import FooterSelect from 'components/footer/FooterSelect';

type Props = {
  footerElements: Array<MenuElementType>,
  logo_footer: string,
  title: string,
};

class FooterBottom extends PureComponent<Props> {
  static defaultProps = {
    footerElements: []
  };

  renderFooterCategory = () => {
    const { footerElements } = this.props;

    const categories = [];
    let categoriesCount = 0;
    footerElements.forEach((footerCategory, index) => {
      categories[categoriesCount] = categories[categoriesCount] || []; 
      categories[categoriesCount].push(
        <FooterSelect key={index} footerCategory={footerCategory} />
      );
      if ((index + 1) % 4 === 0) { 
        // the 4 here is how many items you want per row
        // if we can perfectly divide the current position by 4
        // we have filled a row, so now increment the row
        categoriesCount++;
      }
    });
    return categories;
  };

  render() {
    const { logo_footer, title } = this.props;
    const categories = this.renderFooterCategory();
    return (
      <React.Fragment>
        <div className="container footer__links">
          { categories.map((ar, i) => <div key={i} className="row"> { ar } </div>) }
          <div className="footer__logo mt-3 mt-lg-0">
            <img src={logo_footer} alt={`Logo officiel de la ${title}`} style={{filter: 'grayscale(100%)'}}/>
          </div>
        </div>
        <div className="footer__corporate">
          <div className="container">
            <div className="row">
              <div className="col col-lg-8">
                <ul className="footer__corporate-links">
                  <li>
                    <Link to="/contact" title={`Contacter la ${title} de Rugby`}>Nous contacter</Link>
                  </li>
                  <li>
                    <Link to="/cgu" title={`Consulter les Conditions générales d’utilisation de la ${title} de Rugby`}>Conditions générales d’utilisation</Link>
                  </li>
                  <li>
                    <Link to="/faq" title={`Consulter la Foire Aux Questions de la ${title} de Rugby`}>FAQ</Link>
                  </li>
                  <li>
                    <Link to="/mentions-legales" title={`Consulter les mentions légales de la ${title} de Rugby`}>Mentions légales</Link>
                  </li>
                  <li>
                    <Link to="/politique-de-cookies" title={`Consulter la politique de cookies de la ${title} de Rugby`}>Politique de cookies</Link>
                  </li>
                </ul>
              </div>
              <div className="col col-lg-4">
                {/* TBD Il faudrait dynamiser l'année pour faire 2018-20XX dès l'année prochaine */}
                <p className="footer__corporate-legal">© 2019 {title}</p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FooterBottom;
