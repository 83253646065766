// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';

import { convertTeamsListFromApi } from 'services/Teams';

function TeamsApi(executor: ApiExecutorType) {
  return { fetchTeamList };

  function fetchTeamList() {
    return executor
      .get('equipes.json')
      .then(response => response.map(teamlist => convertTeamsListFromApi(teamlist)));
  }
}

export default TeamsApi;
