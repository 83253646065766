// @flow
import { combineReducers } from 'redux';
import articleReducer from 'reducers/articleReducer';
import articlesReducer from 'reducers/articlesReducer';
import appReducer from 'reducers/appReducer';
import eventsPreviewReducer from 'reducers/eventsPreviewReducer';
import tagsReducer from 'reducers/tagsReducer';
import matchReducer from 'reducers/matchReducer';
import matchFutureTeamReducer from 'reducers/matchFutureTeamReducer';
import matchPastTeamReducer from 'reducers/matchPastTeamReducer';
import instagramReducer from 'reducers/instagramReducer';
import twitterReducer from 'reducers/twitterReducer';
import facebookReducer from 'reducers/facebookReducer';
import newsletterReducer from 'reducers/newsletterReducer';
import menuReducer from 'reducers/menuReducer';
import clubsReducer from 'reducers/clubsReducer';
import clubReducer from 'reducers/clubReducer';
import competitionsReducer from 'reducers/competitionsReducer';
import eventReducer from 'reducers/eventReducer';
import jobDetailReducer from 'reducers/jobDetailReducer';
import jobListReducer from 'reducers/jobListReducer';
import filterOptionsReducer from 'reducers/filterOptionsReducer';
import categoriesReducer from 'reducers/categoriesReducer';
import newsletterCategoryReducer from 'reducers/newsletterCategoryReducer';
import eventsReducer from 'reducers/eventsReducer';
import partnersReducer from 'reducers/partnersReducer';
import settingsReducer from 'reducers/settingsReducer';
import cardReducer from 'reducers/cardReducer';
import pageListReducer from 'reducers/pageListReducer';
import pageNodeReducer from 'reducers/pageNodeReducer';
import pageDetailReducer from 'reducers/pageDetailReducer';
import pageDetailPreviewReducer from 'reducers/pageDetailPreviewReducer';
import sidebarReducer from 'reducers/sidebarReducer';
import competitionReducer from 'reducers/competitionReducer';
import faqReducer from 'reducers/faqReducer';
import searchReducer from 'reducers/searchReducer';
import formReducer from 'reducers/formReducer';
import centenaireReducer from 'reducers/centenaireReducer';
import centenaireFormReducer from 'reducers/centenaireFormReducer';
import rematchFormReducer from 'reducers/rematchFormReducer';
import newsletterSubscriptionReducer from 'reducers/newsletterSubscriptionReducer';
import modalReducer from 'reducers/modalReducer';
import youtubeReducer from 'reducers/youtubeReducer';
import joueurReducer from './joueurReducer';
import auteurReducer from './auteurReducer';
import joueursTeamReducer from './joueursTeamReducer';
import staffReducer from './staffReducer';
import staffsTeamReducer from './staffsTeamReducer';
import seasonsReducer from './seasonsReducer';
import teamlistReducer from './teamlistReducer';
import rencontreReducer from './rencontreReducer';
import logeListReducer from './logeListReducer';
import logeDetailReducer from './logeDetailReducer';
import seminaireReducer from './seminaireReducer';
import youtubeLiveReducer from './ffr-tv/youtubeLiveReducer';
import youtubeSpotlightReducer from './ffr-tv/youtubeSpotlightReducer';
import matchEventsReducer from './matchEventsReducer';
import youtubePlaylistsChannelReducer from './ffr-tv/youtubePlaylistsChannelReducer';
import youtubeVideosChannelReducer from './ffr-tv/youtubeVideosChannelReducer';
import youtubePlaylistReducer from './ffr-tv/youtubePlaylistReducer';
import youtubeVideosPlaylistReducer from './ffr-tv/youtubeVideosPlaylistReducer';
import youtubeChannelReducer from './ffr-tv/youtubeChannelReducer';
import youtubeMostPopularVideosChannelReducer from './ffr-tv/youtubeMostPopularVideosChannelReducer';
import youtubeVideosPlaylistsReducer from './ffr-tv/youtubeVideosPlaylistsReducer';
import homeYoutubePlaylistsReducer from './ffr-tv/homeYoutubePlaylistsReducer';
import youtubeVideoPlayerReducer from './ffr-tv/youtubeVideoPlayerReducer';
import youtubeVideosChannelPlayerReducer from './ffr-tv/youtubeVideosChannelPlayerReducer';
import youtubeRandomVideosPlayerReducer from './ffr-tv/youtubeRandomVideosPlayerReducer';
import youtubePlaylistsLinkedReducer from './ffr-tv/youtubePlaylistsLinkedReducer';
import newVideosReducer from './ffr-tv/newVideosReducer';
import youtubeVideoPlayersReducer from './ffr-tv/youtubeVideoPlayersReducer';
import userReducer from './userReducer';
import tagArticleReducer from './tagArticleReducer';

export default combineReducers({
  articleState: articleReducer,
  articlesState: articlesReducer,
  appState: appReducer,
  eventsPreviewState: eventsPreviewReducer,
  tagsState: tagsReducer,
  matchState: matchReducer,
  matchFutureTeamState: matchFutureTeamReducer,
  matchPastTeamState: matchPastTeamReducer,
  instagramState: instagramReducer,
  facebookState: facebookReducer,
  twitterState: twitterReducer,
  newsletterState: newsletterReducer,
  menuState: menuReducer,
  clubsState: clubsReducer,
  clubState: clubReducer,
  jobListState: jobListReducer,
  jobDetailState: jobDetailReducer,
  competitionsState: competitionsReducer,
  eventState: eventReducer,
  joueurState: joueurReducer,
  auteurState: auteurReducer,
  tagArticleState: tagArticleReducer,
  joueursTeamState: joueursTeamReducer,
  staffState: staffReducer,
  staffsTeamState: staffsTeamReducer,
  filterOptionsState: filterOptionsReducer,
  categoriesState: categoriesReducer,
  newsletterCategoryState: newsletterCategoryReducer,
  eventsState: eventsReducer,
  partnersState: partnersReducer,
  settingsState: settingsReducer,
  cardState: cardReducer,
  pageListState: pageListReducer,
  pageNodeState: pageNodeReducer,
  pageDetailState: pageDetailReducer,
  pageDetailPreviewState: pageDetailPreviewReducer,
  sidebarState: sidebarReducer,
  competitionState: competitionReducer,
  faqState: faqReducer,
  searchState: searchReducer,
  formState: formReducer,
  centenaireState: centenaireReducer,
  centenaireFormState: centenaireFormReducer,
  rematchFormState: rematchFormReducer,
  newsletterSubscriptionState: newsletterSubscriptionReducer,
  modalState: modalReducer,
  youtubeState: youtubeReducer,
  seasonsState: seasonsReducer,
  teamlistState: teamlistReducer,
  rencontreState: rencontreReducer,
  logeListState: logeListReducer,
  logeDetailState: logeDetailReducer,
  seminaireState: seminaireReducer,
  youtubeLiveState: youtubeLiveReducer,
  youtubeSpotlightState: youtubeSpotlightReducer,
  matchEventsState: matchEventsReducer,
  youtubePlaylistsChannelState: youtubePlaylistsChannelReducer,
  youtubeVideosChannelState: youtubeVideosChannelReducer,
  youtubePlaylistState: youtubePlaylistReducer,
  youtubeVideosPlaylistState: youtubeVideosPlaylistReducer,
  youtubeChannelState: youtubeChannelReducer,
  youtubeMostPopularVideosChannelState: youtubeMostPopularVideosChannelReducer,
  youtubeVideosPlaylistsState: youtubeVideosPlaylistsReducer,
  homeYoutubePlaylistsState: homeYoutubePlaylistsReducer,
  youtubeVideoPlayerState: youtubeVideoPlayerReducer,
  youtubeVideosChannelPlayerState: youtubeVideosChannelPlayerReducer,
  youtubeRandomVideosPlayerState: youtubeRandomVideosPlayerReducer,
  youtubePlaylistsLinkedState: youtubePlaylistsLinkedReducer,
  newVideosState: newVideosReducer,
  youtubeVideoPlayersState: youtubeVideoPlayersReducer,
  userState: userReducer,
});
