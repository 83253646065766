// @flow
import React, { PureComponent } from 'react';
import * as Keycloak from "keycloak-js";
import { Link } from 'react-router-dom';
import TopBarDropdown from './TopBarDropdown';
import Label from 'components/fragments/text/Label';
import type { MenuElementType } from 'types/MenuElement';
import { handleTagEvent, getGmtUserData } from 'utils/tagManagerUtils';
import type { ArticleType } from 'types/Article';
import { getCasEndpoint, getKeycloakConfig } from 'utils/urlUtils';
import { ffrUserName } from 'utils/nameUtils';
import { dynamicClassName } from 'utils/dynamicClassName';
import type { RouterProps } from 'types/Router';
import type { FullUserType } from 'types/User';
import userAvatar from 'assets/img/placeholder-profil.png';

export type DispatchProps = {
  fetchArticlesBillboard: () => void,
  fetchUserPref: (full: boolean, token: string) => void,
};

type StateProps = {
  topBarElements: MenuElementType[],
  tickerElements: ArticleType[],
  userPref: FullUserType,
  login_url: string,
  logout_url: string,
};

type Props = DispatchProps & StateProps & RouterProps;

type State = {
  isAccordionOpen: boolean,
  loggedIn: boolean,
  redirection: string,
  keycloak: Object | null,
  keycloakData: Object,
  authenticated: boolean,
};

class TopBar extends PureComponent<Props, State> {
  static defaultProps = {
    topBarElements: []
  };

  state: State = {
    isAccordionOpen: false,
    loggedIn: false,
    redirection: this.props.location.pathname,
    keycloak: null,
    keycloakData: {},
    authenticated: false,
  };

  wrapperRef: any;

  handleClickOutside = (event: MouseEvent) => {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      // $FlowFixMe
      (!event.target.classList.contains('accordion-user-not-close'))
    ) {
      this.setState({ isAccordionOpen: false });
    }
  };

  keycloakConnect() {
    const { fetchUserPref } = this.props;
    const initSsoOptions = getKeycloakConfig();
    let keycloak = Keycloak(initSsoOptions);
    let options = {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: '',
    };
    if (window.location.hostname.match('ffr.fr')) {
      options.silentCheckSsoRedirectUri = window.location.origin + '/silent-check-sso.html';
    }
    keycloak.init(options).then(authenticated => {
      if (authenticated) {
        if (window.location.pathname.match('tableau-de-bord')) {
          fetchUserPref(true, keycloak.token);
        } else { 
          fetchUserPref(false, keycloak.token);
        }
        keycloak.loadUserInfo().then(keycloakData => {
          this.setState({keycloakData});
        }).catch(error => {

        });
      }  else {
        if (window.location.pathname.match('tableau-de-bord')) {
          if (keycloak && keycloak.createLoginUrl) {
            window.location.href = keycloak.createLoginUrl();
          } else {
            window.location.href = '/';
          }
        }
        this.setState({ keycloakData: {} });
      }
      this.setState({ keycloak: keycloak, authenticated: authenticated});
    }).catch(error => {
      console.error(error);
    });
  }

  componentDidMount() {
    const { tickerElements } = this.props;
    document.addEventListener('mousedown', this.handleClickOutside);

    if (tickerElements.length === 0) {
      this.props.fetchArticlesBillboard();
    }

    this.keycloakConnect();
  }

  componentDidUpdate(prevProps: Props) {
    const { pathname } = this.props.location;
    const { keycloak } = this.state;
    if (prevProps.location.pathname !== pathname) {
      if (keycloak && keycloak.token) {
        if (!prevProps.location.pathname.match('tableau-de-bord') && pathname.match('tableau-de-bord')) {
          const { fetchUserPref } = this.props;
          fetchUserPref(true, keycloak.token);
        }
      } else if (!keycloak || !keycloak.token) {
        this.keycloakConnect();
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { userPref, userPref : { identifie }, location: { pathname } } = props;
    const { redirection } = state;
    let newState = {};

    if (pathname !== redirection) {
      newState.redirection = pathname;
    };
    if (userPref) {
      newState.loggedIn = identifie;
    };
    if (newState.loggedIn || newState.loggedIn !== state.loggedIn || newState.redirection) {
      return newState;
    };
    return null;
  }

  onLinkClick = (userPref: FullUserType, link: string) => {
    return handleTagEvent(
      'menu_transverse',
      'clic_lien_sortant',
      `${link} ${userPref.profil.id === '' ? '' : `| ${getGmtUserData(userPref)}`}`
    );
  }

  setWrapperRef = (node: any) => {
    this.wrapperRef = node;
  };

  renderTopBarElements = () => {
    const { topBarElements, userPref } = this.props;
    const menus = topBarElements.filter(item => item.parent === 0);

    return menus.map((menu, index) => {
      if (menu.items && menu.items.length > 0) {
        return <TopBarDropdown
          key={index}
          subMenus={menu.items}
          title={menu.title}
          userPref={userPref}
        />;
      }

      return (
        <li key={index}>
          <a
            href={menu.url}
            title={`Se rendre sur le site officiel "${menu.title}" de la Fédération Française de Rugby (nouvel onglet)`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={this.onLinkClick(userPref, menu.url)}
          >
            {menu.title}
          </a>
        </li>
      );
    });
  };

  handleAccordionOpen = () => {
    this.setState((prevState) => (
      { isAccordionOpen: !prevState.isAccordionOpen}
    ));
  };

  render() {
    const { tickerElements, userPref: { avatar, profil: { nom, prenom } }, login_url, logout_url} = this.props;
    const { redirection, loggedIn, isAccordionOpen, keycloakData, authenticated } = this.state;
    const nomSso = nom ? nom : keycloakData.family_name;
    const prenomSso = prenom ? prenom : keycloakData.given_name;
    const avatarSso = avatar ? avatar : keycloakData.logo;
    const accordionTriggerDynamicClasses = dynamicClassName('accordion-trigger topbar__user');
    const topbarDynamicClasses = dynamicClassName('topbar');
    const accordionDynamicClasses = dynamicClassName('topbar__sub accordion-panel');

    if (isAccordionOpen) {
      topbarDynamicClasses.add('has-accordion-open');
      accordionDynamicClasses.remove('is-hidden');
      accordionTriggerDynamicClasses.add('is-selected');
    } else {
      topbarDynamicClasses.remove('has-accordion-open');
      accordionDynamicClasses.add('is-hidden');
      accordionTriggerDynamicClasses.remove('is-selected');
    };

    return (
      <nav className={topbarDynamicClasses.build()}>
        <ul className="topbar__left">
          {this.renderTopBarElements()}
          { tickerElements && tickerElements[0] &&
          <li className="topbar__news">
            <a href={tickerElements[0].link} title={tickerElements[0].title}>
              <Label
                isSmall
                isBlueAlt
                label={tickerElements[0].label ? tickerElements[0].label.name : 'la fédération'} />
              <span>{tickerElements[0].title}</span>
            </a>
          </li>}
        </ul>
        {(!loggedIn && !authenticated) ? (
          <ul className="topbar__right">
            <li>
              <a
                className="topbar__login"
                href={getCasEndpoint(login_url, '/tableau-de-bord')}
                onClick={handleTagEvent('menu_transverse', 'clic_connexion_inscription', '')}>
                Connexion / inscription
              </a>
            </li>
          </ul>
            ) : (
            <ul className="topbar__right">
              <li>
                <a
                  onClick={this.handleAccordionOpen}
                  className={accordionTriggerDynamicClasses.build()}
                  ref={this.setWrapperRef}
                  role='button'
                  tabIndex='0'
                >
                  {avatarSso && <img src={avatarSso} alt="" style={{ width: '24px', height: '24px' }} />}
                  {!avatarSso && <img src={userAvatar} alt="" style={{ width: '24px', height: '24px' }} />}
                  {(nomSso && prenomSso) && `${ffrUserName(prenomSso)} ${ffrUserName(nomSso)}`}
                </a>
                <ul className={accordionDynamicClasses.build()} id="accordion-user">
                  <li>
                    <Link
                      to='/tableau-de-bord'
                      className='accordion-user-not-close'
                      onClick={() => this.setState({ isAccordionOpen: false })}
                    >
                      <i className="icon icon-account is-inline" />
                        Espace personnel
                    </Link>
                  </li>
                  <li>
                    <a href={getCasEndpoint(logout_url, redirection.match('tableau-de-bord') ? '/' : redirection)} 
                      className='accordion-user-not-close'>
                      <i className="icon icon-logout is-inline" />
                      Se déconnecter
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            )
          }
      </nav>
    );
  }
}

export default TopBar;
