//@flow

import type { FullUserType, RawFullUserType } from 'types/User';
import { nullUserPrefs } from 'types/User';
import { mergeIntoDefault } from 'utils/objectUtils';
import { convertMatchEventFromApi } from 'services/Event';
import { convertArticleFromApi } from 'services/Article';

export function convertUserPrefFromApi(user: RawFullUserType): FullUserType {
  if (!user) {
    return nullUserPrefs;
  }

  const { alertes_billetterie_detail: events, newsletter_feed: feed } = user;

  const newsletter_feed = feed && feed.length > 0 && feed.map(article => convertArticleFromApi(article));
  const alertes_billetterie_detail = events && events.length > 0 &&
    events.map((event) => ({ ...convertMatchEventFromApi(event), title: event && event.title }));

  return mergeIntoDefault(nullUserPrefs, { ...user, newsletter_contenu: user.newsletter, alertes_billetterie_detail, newsletter_feed });
}
