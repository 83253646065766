/**
 * adnxs Pixie Module
 *
 * @package react-adnxs-pixel
 * @author  Zain Sajjad <zsajjad@fetchsky.com>
 */

//
let initialized = false;
let debug = false;

/**
 * Utilities
 */

const verifyInit = () => {
  if (!initialized) {
    console.warn('Pixie not initialized before using call ReactPixie.init with required params');
  }
  return initialized;
};

//
const log = (...args) => {
  console.info(...['[react-adnxs-pixie]'].concat(args));
};

const defaultOptions = {
    debug: false
};
//
export default {
  init(pixieId, options = defaultOptions) {
    /* eslint-disable */
    !function (e, i){
        if (!e.pixie) {
            var n = e.pixie = function (e, i, a) {
                n.actionQueue.push(
                    {action:e,actionValue:i,params:a}
                )
            };
            n.actionQueue=[];
            var a=i.createElement("script");
            a.async=!0,
            a.src="//acdn.adnxs.com/dmp/up/pixie.js";
            var t=i.getElementsByTagName("head")[0];
            t.insertBefore(a,t.firstChild)
        }
    }(window,document);
    /* eslint-enable */

    if (!pixieId) {
      console.warn('Please insert adnxs pixie ID for initializing');
    } else {
      pixie('init', pixieId); // eslint-disable-line no-undef

      initialized = true;
      debug = options.debug;
    }
  },

  pageView() {
    if (!verifyInit()) {
      return;
    }

    pixie('event', 'PageView'); // eslint-disable-line no-undef

    if (debug) {
      log('called pixie(\'event\', \'PageView\');');
    }
  },

  pixie(...args) {
    if (!verifyInit()) {
      return;
    }

    pixie(...args); // eslint-disable-line no-undef

    if (debug) {
      log(`called pixie('${args.slice(0, 2).join('\', \'')}')`);

      if (args[2]) {
        log('with data', args[2]);
      }
    }
  },
};
