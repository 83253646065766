import 'react-app-polyfill/ie11';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'unorm';

import React from 'react';
import ReactDOM from 'react-dom';
import { initWindowApp } from 'utils/windowUtils';
import App from 'components/app/AppProvider';
import * as Sentry from '@sentry/browser';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
import ReactPixie from './tracking/pixie';

import packageJson from '../package.json';

import 'ie-array-find-polyfill';
import 'raf/polyfill';
import 'utils/ieUtils';
import './polyfill-includes';
import 'assets/css/style.css';
import 'assets/css/topbar.css';
import 'assets/css/cookie.css';

Sentry.init({
  dsn: 'https://2acdaac2b5d245f68af8bf91252d7fb4@sentry.dev.ffr.fr/21',
  release: packageJson.version,
  environment: process.env.NODE_ENV
});

const PixieId = '40b83411-5959-4983-9824-d128b5604cd8';
  const PixieOptions = {
    debug: false, // enable logs
  };

ReactPixie.init(PixieId, PixieOptions);


const PixelAdvancedMatching = { }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const PixelOptions = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
};
const PixelId = '2274215805981007';

ReactPixel.init(PixelId, PixelAdvancedMatching, PixelOptions);
ReactPixel.fbq('consent', 'revoke');
initWindowApp();

const initCookies = () => {
  const tagManagerArgs = {
    gtmId: 'GTM-KQL4QNG',
    dataLayerName: 'dataLayer'
  };
  TagManager.initialize(tagManagerArgs);
  ReactPixel.fbq('consent', 'grant');
};


const root = document.getElementById('root');
const app = <App acceptCookies={initCookies}/>;
ReactDOM.render(app, root);


if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}
