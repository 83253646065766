// @flow

import type { TeamList } from 'types/TeamList';

export const FETCH_TEAMS_START = 'FETCH_TEAMS_START';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
export const FETCH_TEAMS_FAILURE = 'FETCH_TEAMS_FAILURE';


export type FETCH_TEAMS_START_ACTION = {
  type: 'FETCH_TEAMS_START'
};

export type FETCH_TEAMS_FAILURE_ACTION = {
  type: 'FETCH_TEAMS_FAILURE'
};

export type FETCH_TEAMS_SUCCESS_ACTION = {
  type: 'FETCH_TEAMS_SUCCESS',
  payload: {
    teamlist: Array<TeamList>
  }
};

export type Action = FETCH_TEAMS_START_ACTION | FETCH_TEAMS_FAILURE_ACTION | FETCH_TEAMS_SUCCESS_ACTION;

export function fetchTeamList(): FETCH_TEAMS_START_ACTION {
  return {
    type: FETCH_TEAMS_START
  };
}

export function teamListFetched(teamlist: Array<TeamList>): FETCH_TEAMS_SUCCESS_ACTION {
  return {
    type: FETCH_TEAMS_SUCCESS,
    payload: { teamlist }
  };
}

export function teamListFetchError(): FETCH_TEAMS_FAILURE_ACTION {
  return {
    type: FETCH_TEAMS_FAILURE
  };
}
