// @flow
import { connect } from 'react-redux';
import NewsletterOptinModal from 'components/footer/NewsletterOptinModal';

import type { StateType, DispatchType } from 'types/Actions';
import type { StateProps, DispatchProps } from 'components/footer/NewsletterOptinModal';

import { fetchNewsletterOptions, subscribeNewsletter } from 'actions/newsletterSubscriptionActions';
import { displayModal } from 'actions/modalActions';
import { userHasSubscribedNewsletter } from 'actions/appActions';
import type { NewsletterSubscriptionEntryType } from 'types/NewsletterSubscription';

export default connect(
  (state: StateType): StateProps => ({
    options: state.newsletterSubscriptionState.options,
    newsletterSetting: state.settingsState.newsletter,
  }),
  (dispatch: DispatchType): DispatchProps => ({
    fetchOptions: (campaignMonitorKey: string) => dispatch(fetchNewsletterOptions(campaignMonitorKey)),
    subscribeNewsletter: (
      subscription: NewsletterSubscriptionEntryType,
      campaignMonitorKey: string,
      typeNewsletter: string | boolean
    ) => dispatch(subscribeNewsletter(subscription, campaignMonitorKey, typeNewsletter)),
    displayModal: (modalNb: number, content: Object, force: boolean) => dispatch(displayModal(modalNb, content, force)),
    userHasSubscribedNewsletter: (newsletterSubscribed: boolean) => dispatch(userHasSubscribedNewsletter(newsletterSubscribed)),
  })
)(NewsletterOptinModal);
