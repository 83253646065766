//@flow

import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';

import { getPublicAssets } from 'utils/assetsUtils';
import { getCasEndpoint } from 'utils/urlUtils';
import { handleTagEvent, getGmtUserData } from 'utils/tagManagerUtils';
import { ffrUserName } from 'utils/nameUtils';

import type { FullUserType } from 'types/User';
import type { MenuElementType } from 'types/MenuElement';

export type StateProps = {
  userPref: FullUserType,
  menuElements: MenuElementType[],
};

type Props = {
  match?: Object,
  onCloseMenu?: Function,
  logout_url: string,
} & StateProps;

class MenuDashboard extends Component<Props> {
  renderQuickAccess = () => {
    const { userPref, menuElements } = this.props;
    if (menuElements) {
      return menuElements.map(element => {
        const {object_id, url, title } = element;
        return (
          <a
            key={object_id}
            className="link-competition link-competition--card link-competition--access box"
            href={url}
            onClick={handleTagEvent(
              'espace perso',
              'clic_lien_acces_rapide',
              `${title} ${userPref.profil.id === '' ? '' : `| ${getGmtUserData(userPref)}` }`
            )}
            target="_blank"
            rel="noopener noreferrer"
            title={title}
          >
            <div>
              <span className="link-competition__title ft-h4">
                {title}
                <i className="icon icon-external" />
              </span>
            </div>
          </a>
        );
      });
    }
    return null;
  };

  render() {
    const {
      userPref,
      userPref : { 
        avatar, 
        is_player,
        is_dirigeant,
        licence,
        profil: { 
          prenom
        },
        ligue: {
          website,
          nom
        },
        club: {
          website: clubWebsite,
          nom: clubNom
        }
      },
      onCloseMenu,
      logout_url
    } = this.props;

    const isAvatar = !avatar ? getPublicAssets('/img/placeholder/placeholder-profil.png') : avatar;

    return (
      <div className='dashboard-menu box'>
        <div className={'dashboard-menu__back'}>
          <Link to="/" title="Retour au site">
            <span className="ft-h5"><i className="icon is-inline icon-chevron-left" /> Retour au site</span>
          </Link>
        </div>
        <div className="dashboard-menu__header">
          <Link to="/tableau-de-bord" title="Mon profil">
            <img src={getPublicAssets('/img/logo/new_logo_ffr.svg')} alt="Logo FFR" />
            <h1 className="ft-up ft-h5">Espace perso</h1>
          </Link>
        </div>
        <div className='dashboard-menu__profil'>
          <i className="icon icon-close menu__close js-showMenu" role="button" tabIndex={0} onClick={onCloseMenu} />
          <div>
            <img src={isAvatar} alt="Profil utilisateur" />
          </div>
          <p className="ft-primary mt-2">{`Bienvenue ${ffrUserName(prenom)} !`}</p>
        </div>
        <div className='dashboard-menu__nav'>
          <p className="ft-700 mb-2 ft-h5">Pour vous</p>
          <NavLink
            exact
            activeClassName='active'
            onClick={() => onCloseMenu && onCloseMenu()}
            className='ft-up ft-700'
            to="/tableau-de-bord"
            title="Tableau de bord">
              <i className="icon icon-dashboard is-inline" />
              Tableau de bord
          </NavLink>
          {licence && is_player && 
          <NavLink
            activeClassName='active'
            className='ft-up ft-700'
            onClick={() => onCloseMenu && onCloseMenu()}
            to="/tableau-de-bord/mes-statistiques"
            title="Mes statistiques">
            <i className="icon icon-chart is-inline" />
            Mes statistiques
          </NavLink> }
          {licence && nom &&
          <NavLink
            activeClassName='active'
            className='ft-up ft-700'
            onClick={() => onCloseMenu && onCloseMenu()}
            to="/tableau-de-bord/ma-ligue"
            title="Ma ligue">
            <i className="icon icon-trophy is-inline" />
            Ma ligue
          </NavLink>}
          {licence && clubNom &&
          <NavLink
            activeClassName='active'
            className='ft-up ft-700'
            onClick={() => onCloseMenu && onCloseMenu()}
            to="/tableau-de-bord/mon-club"
            title="Mon club">
            <i className="icon icon-shield is-inline" />
            Mon club
          </NavLink>}
          {licence && is_dirigeant && 
          <NavLink
            activeClassName='active'
            className='ft-up ft-700'
            onClick={() => onCloseMenu && onCloseMenu()}
            to="/tableau-de-bord/mon-activite"
            title="Boîte à outils">
            <i className="icon icon-info is-inline" />
            Boîte à outils
          </NavLink>}
          <NavLink
            activeClassName='active'
            className='ft-up ft-700'
            onClick={() => onCloseMenu && onCloseMenu()}
            to="/tableau-de-bord/mon-profil"
            title="Mon profil">
            <i className="icon icon-account is-inline" />
            Mon profil
          </NavLink>
        </div>
        <div className='dashboard-menu__settings'>
          <p className="ft-700 mb-2 ft-h5">Général</p>
          <NavLink
            activeClassName='active'
            className='ft-up ft-700 ft-black-secondary'
            to='/tableau-de-bord/actualites'
            onClick={() => onCloseMenu && onCloseMenu()}
            title='Mes alertes billetterie'>
              <i className="icon icon-ball is-inline" />
              Actualites
              <i className="icon icon-record is-inline" style={{marginLeft: '1em', color: 'red', fontSize: '.5em', paddingBottom:'.5em'}} />
          </NavLink>
          <NavLink
            activeClassName='active'
            className='ft-up ft-700 ft-black-secondary'
            to='/tableau-de-bord/ma-boutique'
            onClick={() => onCloseMenu && onCloseMenu()}
            title='Boutique'>
              <i className="icon icon-gift is-inline" />
             Boutique
          </NavLink>
          <NavLink
            activeClassName='active'
            className='ft-up ft-700 ft-black-secondary'
            to='/tableau-de-bord/mes-alertes-billetterie'
            onClick={() => onCloseMenu && onCloseMenu()}
            style={{display:"none"}}
            title='Mes alertes billetterie'>
              <i className="icon icon-ticket is-inline" />
             Billetterie
          </NavLink>
          <NavLink
            activeClassName='active'
            className='ft-up ft-700 ft-black-secondary'
            to="/tableau-de-bord/mes-parametres"
            onClick={() => onCloseMenu && onCloseMenu()}
            title="FFR.FR">
            <i className="icon icon-gear is-inline" />
              Paramètres généraux
          </NavLink>
          
          <a className="ft-up ft-700 ft-black-secondary" href={getCasEndpoint(logout_url, '/')} title="Me déconnecter">
            <i className="icon icon-logout is-inline" />
            Me déconnecter 
          </a>
        </div>
        <div className='dashboard-menu__access'>
          <p className="ft-700 mb-2 ft-h5">Accès rapides</p>
          {this.renderQuickAccess()}
          {website &&  
          <a
            href={website}
            className="link-competition link-competition--card link-competition--access box"
            onClick={handleTagEvent(
              'espace perso',
              'clic_lien_acces_rapide',
              `Ligue ${nom} ${ userPref.profil.id === '' ? '' : `| ${getGmtUserData(userPref)}` }`
            )}
            target="_blank"
            rel="noopener noreferrer"
            title={website}>
              <span className="link-competition__title ft-h4">
              {'Site de votre ligue'}
                <i className="icon icon-external" />
              </span>
          </a>}
          {clubWebsite && 
          <a
            href={clubWebsite}
            className="link-competition link-competition--card link-competition--access box"
            onClick={handleTagEvent(
              'espace perso',
              'clic_lien_acces_rapide',
              `Club ${clubNom} ${ userPref.profil.id === '' ? '' : `| ${getGmtUserData(userPref)}` }`
            )}
            target="_blank"
            rel="noopener noreferrer"
            title={clubWebsite}>
              <span className="link-competition__title ft-h4">
              {'Site de votre club'}
                <i className="icon icon-external" />
              </span>
          </a>}
        </div>
      </div>
    );
  }
}

export default MenuDashboard;
