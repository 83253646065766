// @flow
import type {
  RawArticleType,
  ArticleType,
  RawCategoryType,
  CategoryType,
  RawCategoryArticleType,
  CategoryArticleType,
  RawNewsletterFavoritesArticleType,
} from 'types/Article';
import { nullArticle, nullCategory, nullCategoryArticle } from 'types/Article';
import { mergeIntoDefault, convertToJsDate } from 'utils/objectUtils';
import { convertEncodedHTML } from 'utils/convertUtils';
import { europeanStringToDate } from 'utils/dateUtils';

export function convertArticleFromApi(rawArticle: RawArticleType | RawNewsletterFavoritesArticleType): ArticleType {
  if (!rawArticle) {
    return nullArticle;
  }

  if (rawArticle.category) {
    const { title, date, featured_media, link, category } = rawArticle;
    return mergeIntoDefault(nullArticle, {
      category: category,
      date: europeanStringToDate(date),
      title: title,
      srcset: typeof featured_media === 'number' ? null : featured_media && featured_media.sizes,
      illustration: typeof featured_media === 'number' ? null : featured_media && featured_media.src,
      link,
    });
  }

  const { 
    date, 
    title, 
    content, 
    tags_list, 
    slug,
    link,
    featured_media,
    featured_video,
    label, 
    sidebar_content, 
    meta_title, 
    meta_description,
    author: {
      author_link, name, description, editing_role, avatar_urls
    },
    redirect,
  } = rawArticle;


  return mergeIntoDefault(nullArticle, {
    date: new Date(convertToJsDate(date)),
    label: {
      name: convertEncodedHTML(label.name),
      slug: label.slug
    },
    caption: typeof featured_media === 'number' ? null : convertEncodedHTML(featured_media.legend),
    title: title.raw,
    srcset: typeof featured_media === 'number' ? null : featured_media.sizes,
    illustration: typeof featured_media === 'number' ? null : featured_media.src,
    tags: tags_list,
    blocs: content.data,
    illustration_video:featured_video.data,
    twitter: null,
    facebook: null,
    sidebar: sidebar_content ? sidebar_content.data : [],
    slug,
    link,
    auteur: {
      author_link: author_link,
      nom: name,
      description: description,
      role_edition: editing_role,
      avatar_urls
    },
    meta_title,
    meta_description,
    redirection: redirect && redirect.url,
  });
}

export function convertCategoryFromApi(rawCategory: RawCategoryType): CategoryType {
  if (!rawCategory) {
    return nullCategory;
  }

  const { id, name, slug } = rawCategory;

  return mergeIntoDefault(nullCategory, {
    id,
    name: convertEncodedHTML(name),
    slug
  });
}

export function convertCategoryArticleFromApi(rawCategory: RawCategoryArticleType): CategoryArticleType {
  if (!rawCategory) {
    return nullCategoryArticle;
  }

  const { id, name, slug, items } = rawCategory;

  return mergeIntoDefault(nullCategoryArticle, {
    id,
    name: convertEncodedHTML(name),
    slug,
    items
  });
}
