// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import { FETCH_TEAMS_START, FETCH_TEAMS_SUCCESS, FETCH_TEAMS_FAILURE } from 'actions/teamlistActions';

import type { TeamList } from 'types/TeamList';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  teamlist: Array<TeamList>,
  status: Status
};

const initialState: State = {
  teamlist: [],
  status: STATUS_LOADING
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type)
  {
    case FETCH_TEAMS_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_TEAMS_SUCCESS:
      return {
        ...state,
        teamlist: action.payload.teamlist,
        status: STATUS_SUCCESS
      };
    case FETCH_TEAMS_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
