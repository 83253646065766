// @flow

import { FETCH_SETTINGS_SUCCESS } from 'actions/appActions';

import type { BlocFaq, liensRsType, HomePratiques, ClubSemaine } from 'types/Settings';
import type { RencontreType } from 'types/Rencontre';
import type { ActionType } from 'types/Actions';

export type State = {
  ligue: string,
  title: string,
  description: string,
  url: string,
  logo: string,
  logo_footer: string,
  logo_transparent: string,
  cat_calendrier_equipes: string[],
  saison_en_cours: number,
  class_ligue: string,
  titre_liste_cards: string,
  match_semaine: number,
  breadcrumb_offres_entreprise: Array<any>,
  blocs_faq: BlocFaq[],
  blocs_trouver_club: BlocFaq[],
  bloc_trouver_club: {
    featured_image: string,
    featured_media: Object,
  },
  accreditations: Array<{ label: string, value: string }>,
  clubs_semaine: ClubSemaine[],
  trouver_pratiques: HomePratiques,
  bloc_entreprise: {
    object_id: number,
    type_name: string,
    title: string,
    description: string,
    texte_cta: string,
    featured_image: string,
    slug_complet: string,
    featured_media: Object,
  },
  contacts: {
    adresse: string,
    adresse_map: string,
    telephone: string,
    email: string,
    horaires: string
  },
  favicons: {
    'android-192': string,
    'android-256': string,
    'apple-touch-icon-152': string,
    'apple-touch-icon-180': string,
    'favicon-16': string,
    'favicon-32': string,
    'mstile-150': string
  },
  liens_rs: liensRsType,
  newsletter: { type: false | 'campaign_monitor' | 'lien' | 'salesforce', lien?: string, key?: string },
  cookies: number,
  actualite_topbar: {
    link: string,
    title: string,
    label: string
  },
  cookies: number;
  push_rencontre?: RencontreType,
  boutique: {
    title: string,
    description: string,
    cta: string,
    link: string,
    image: string,
    team_home: boolean,
    banner_only: boolean,
  },
  pratiques_clubs: string[], 
  private_boutique: {
    lien: string,
    title: string,
    titleproduit: string,
    detailproduit: string,
    image: string,
    cta: string,
    discount: string,
  },
  private_billetterie: {
    lien: string,
    locaux: string,
    visiteur: string,
    detail: string,
    image: string,
    cta: string,
    discount: string,
    competition: string,
  },
  private_maboutique: {
    title: string,
    lien: string,
    cta: string,
    products: {
      title: string,
      url: string,
      image: string,
      discount: string,
    }[]
  },
  login_url: string,
  logout_url: string
};

const initialState: State = {
  ligue: '',
  title: '',
  description: '',
  url: '',
  logo: '',
  logo_footer: '',
  breadcrumb_offres_entreprise: [],
  logo_transparent: '',
  cat_calendrier_equipes: [],
  saison_en_cours: 0,
  class_ligue: '',
  titre_liste_cards: '',
  match_semaine: 0,
  accreditations: [],
  clubs_semaine: [],
  trouver_pratiques: {
    titre_liste: 'Trouver le rugby qui vous correspond',
    bloc_principal: {
      object_id: 0,
      type_name: '',
      slug_complet: '',
      title: '',
      description: '',
      featured_image: {},
      texte_cta: ''
    },
    pratiques: {
      pratique_5: {
        object_id: 0,
        type_name: '',
        slug_complet: ''
      },
      pratique_7: {
        object_id: 0,
        type_name: '',
        slug_complet: ''
      },
      pratique_10: {
        object_id: 0,
        type_name: '',
        slug_complet: ''
      },
      pratique_15: {
        object_id: 0,
        type_name: '',
        slug_complet: ''
      },
    }
  },
  bloc_entreprise: {
    object_id: 0,
    type_name: '',
    title: '',
    description: '',
    texte_cta: '',
    featured_image: '',
    featured_media: {},
    slug_complet: '',
  },
  bloc_trouver_club: {
    featured_image: '',
    featured_media: {},
  },
  blocs_faq: [
    {
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_image: {},
      parent_slugs: [''],
      texte_cta: ''
    }
  ],
  blocs_trouver_club: [
    {
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_image: {},
      parent_slugs: [''],
      texte_cta: ''
    }
  ],
  contacts: {
    adresse: '',
    adresse_map: '',
    telephone: '',
    email: '',
    horaires: ''
  },
  favicons: {
    'android-192': '',
    'android-256': '',
    'apple-touch-icon-152': '',
    'apple-touch-icon-180': '',
    'favicon-16': '',
    'favicon-32': '',
    'mstile-150': ''
  },
  liens_rs: {
    fb_lien: '',
    instagram_lien: '',
    twitter_lien: '',
    youtube_lien: ''
  },
  newsletter: { type: false },
  cookies: 0,
  actualite_topbar: {
    link: '',
    title: '',
    label: ''
  },
  boutique: {
    title: '',
    description: '',
    cta: '',
    link: '',
    image: '',
    team_home: false,
    banner_only: false,
  },
  pratiques_clubs: [],
  private_boutique: {
    lien: '',
    title: '',
    titleproduit: '',
    detailproduit: '',
    image: '',
    cta: '',
    discount: '',
  },
  private_maboutique: {
    title: '',
    lien: '',
    cta: '',
    products: [{
      title: '',
      url: '',
      image: '',
      discount: '',
    }]
  },
  private_billetterie: {
    lien: '',
    locaux: '',
    visiteur: '',
    detail: '',
    image: '',
    cta: '',
    discount: '',
    competition: '',
  },
  login_url: '',
  logout_url: ''
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        ligue: action.payload.settings.ligue,
        title: action.payload.settings.title,
        description: action.payload.settings.description,
        url: action.payload.settings.url,
        logo: action.payload.settings.logo,
        logo_footer: action.payload.settings.logo_footer,
        logo_transparent: action.payload.settings.logo_transparent,
        cat_calendrier_equipes: action.payload.settings.cat_calendrier_equipes,
        saison_en_cours: action.payload.settings.saison_en_cours,
        accreditations: action.payload.settings.accreditations,
        clubs_semaine: action.payload.settings.clubs_semaine,
        trouver_pratiques: action.payload.settings.trouver_pratiques,
        bloc_entreprise: action.payload.settings.bloc_entreprise,
        blocs_faq: action.payload.settings.blocs_faq,
        blocs_trouver_club: action.payload.settings.blocs_trouver_club,
        bloc_trouver_club: action.payload.settings.bloc_trouver_club,
        contacts: action.payload.settings.contacts,
        favicons: action.payload.settings.favicons,
        liens_rs: action.payload.settings.liens_rs,
        newsletter: action.payload.settings.newsletter,
        cookies: action.payload.settings.cookies,
        breadcrumb_offres_entreprise: action.payload.settings.breadcrumb_offres_entreprise,
        actualite_topbar: action.payload.settings.actualite_topbar,
        pratiques_clubs: action.payload.settings.pratiques_clubs,
        push_rencontre: action.payload.settings.push_rencontre,
        boutique: action.payload.settings.boutique,
        private_boutique: action.payload.settings.private_boutique,
        private_maboutique: action.payload.settings.private_maboutique,
        private_billetterie: action.payload.settings.private_billetterie,
        login_url: action.payload.settings.login_url,
        logout_url: action.payload.settings.logout_url
      };
    default:
      return state;
  }
}
