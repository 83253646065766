// @flow
import type { BlocContent } from 'types/NewsletterBloc';
import type { SourceSetType } from 'types/Article';
import type { YoutubeVideoType } from 'types/PageList';
import type { SEOMetaType } from 'types/SEOType';
import { nullSeoMeta } from 'types/SEOType';

export type MatchSimple = {
  adversaire: string,
  reception: boolean,
  france_logo: string,
  adversaire_logo: string,
  france_score: number,
  adversaire_score: number,
  date_rencontre: string,
  link: string
}

export type ClubJoueur = {
  age: string,
  club: string,
  embleme: string,
  link: string,
  saisons: string
}

export type EquipeJoueur = {
  id: number,
  title: string,
  link: string,
  matchs: Array<number>,
  essais: number,
  transformations: number,
  penalites: number,
  drops: number,
  type_rugby: string,
  classe_age: string,
  afficher_stats: boolean,
  matchs_joues: Array<MatchSimple>,
}

export type JoueurType = SEOMetaType & {
  id: number,
  slug: string,
  title: string,
  link: string,
  content: Array<BlocContent>,
  numInternational: number,
  poste: { id: number, name: string },
  age: number,
  dateNaissance: string,
  genre: string,
  logo: string,
  featured_media: {
    src: string,
    legend: string,
    sizes: SourceSetType
  },
  sidebar: Array<BlocContent>,
  nombre_selections: number,
  nombre_transformations: number,
  nombre_penalites: number,
  nombre_essais: number,
  nombre_drops: number,
  matchs_joues: Array<MatchSimple>,
  historique: Array<ClubJoueur>,
  equipes: Array<EquipeJoueur>,
  videos: Array<YoutubeVideoType>,
};

export type RawJoueurType = SEOMetaType & {
  id: number,
  date: string,
  modified: string,
  slug: string,
  status: string,
  type: string,
  link: string,
  title: {
    rendered: string,
    raw: string,
    data: [
      {
        type: string,
        text: string
      }
    ]
  },
  content: {
    rendered: string,
    protected: boolean,
    raw: string,
    data: Array<any>
  },
  featured_media: {
    src: string,
    legend: string,
    sizes: SourceSetType
  },
  excerpt: {
    rendered: string,
    protected: boolean,
    raw: string,
    data: Array<any>
  },
  template: string,
  meta: {
    age: number,
    numInternational: number,
    dateNaissance: string,
    genre: string,
  },
  historique: Array<ClubJoueur>,
  poste: { id: number, name: string },
  nombre_selections: number,
  nombre_transformations: number,
  nombre_penalites: number,
  nombre_essais: number,
  nombre_drops: number,
  sidebar_content: {
    data: Array<BlocContent>
  },
  matchs_joues: Array<MatchSimple>,
  equipes: Array<EquipeJoueur>,
  youtube_playlist: Array<YoutubeVideoType>,
};


export type JoueurFiltersSearchType = {
  competitions: Array<number>,
  equipe: string,
  poste: Array<number>,
  season?: number | null,
};

export const nullFeatureMediaType = {
  featured_media: {
    src: '',
    legend: '',
    sizes: {}
  },
};

export const nullJoueur: JoueurType = {
  ...nullSeoMeta,
  title: 'Chargement',
  poste: {id: 0, name: ''},
  slug: '',
  age: 0,
  dateNaissance: '',
  genre: '',
  numInternational: 0,
  content: [],
  logo: '',
  link: '',
  featured_media: {
    src: '',
    legend: '',
    sizes: {}
  },
  historique: [],
  nombre_selections: 0,
  nombre_transformations: 0,
  nombre_penalites: 0,
  nombre_essais: 0,
  nombre_drops: 0,
  matchs_joues: [],
  equipes: [],
  videos: [],
  sidebar: [],
  id: 0,
};

export const nullPlayersFiltersSearch: JoueurFiltersSearchType = {
  competitions: [],
  equipe: '',
  poste: [],
};
