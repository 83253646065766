// @flow
import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import UserApi from 'api/user/UserApi';
import { 
  FETCH_USER_PREF_START,
  POST_USER_PREF_START, 
  fetchUserPref,
  userPrefFetched,
  userPrefFetchError,
  postUserPrefSuccess,
  postUserPrefFailure,
} from 'actions/userActions';
import type { POST_USER_PREF_START_ACTION, FETCH_USER_PREF_START_ACTION } from 'actions/userActions';

export default function(apiExecutor: ApiExecutorType) {
  const userApi = new UserApi(apiExecutor);

  return function* userSaga(): GeneratorType {
    yield takeLatest(FETCH_USER_PREF_START, fetchUserPrefs);
    yield takeLatest(POST_USER_PREF_START, changeUserPrefs);
  };

  function* fetchUserPrefs(action: FETCH_USER_PREF_START_ACTION): Saga<void> {
    try { 
      const { full, token } = action.payload;
      const userPrefs = yield call(userApi.fetchUserPref, full, token);
      yield put(userPrefFetched(userPrefs));
    } catch (e) {
      if (e.response) {
        yield put(userPrefFetchError(e.response.data.code));
      } else {
        yield put(userPrefFetchError(e.message));
      }
    }
  }

  function* changeUserPrefs(action: POST_USER_PREF_START_ACTION): Saga<void> {
    try {
      const { action: postAction, value, token } = action.payload;      
      const userPrefsPosted = yield call(userApi.changeUserProfile, postAction, value, token);
      yield put(fetchUserPref(true, token));
      yield put(postUserPrefSuccess(userPrefsPosted));
      window.snack && window.snack.classList.add('is-valid');
      setTimeout(() => {
        window.snack && window.snack.classList.remove('is-valid');       
      }, 3000);
    } catch (e) {
      yield put(postUserPrefFailure(e.response.data.code));
    }
  }
}