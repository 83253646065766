// @flow
import { connect } from 'react-redux';

import ModalFormCentenaireEmail from 'components/centenaire/ModalFormCentenaireEmail';

import type { DispatchType, StateType } from 'types/Actions';
import type { StateProps, DispatchProps } from 'components/centenaire/ModalFormCentenaireEmail';

import { displayModal } from 'actions/modalActions';

export default connect(
  (state: StateType): StateProps => ({
  }),
  (dispatch: DispatchType): DispatchProps => ({
    displayModal: (modalNb: number, content: Object, force: boolean) => dispatch(displayModal(modalNb, content, force)),
  })
)(ModalFormCentenaireEmail);
