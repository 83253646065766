//@flow 

import type { FullUserType, EditProfileValueType } from 'types/User';

export const FETCH_USER_PREF_START = 'FETCH_USER_PREF_START';
export const FETCH_USER_PREF_SUCCESS = 'FETCH_USER_PREF_SUCCESS';
export const FETCH_USER_PREF_FAILURE = 'FETCH_USER_PREF_FAILURE';
export const POST_USER_PREF_START = 'POST_USER_PREF_START';
export const POST_USER_PREF_SUCCESS = 'POST_USER_PREF_SUCCESS';
export const POST_USER_PREF_FAILURE = 'POST_USER_PREF_FAILURE';


export type FETCH_USER_PREF_START_ACTION = {
  type: 'FETCH_USER_PREF_START',
  payload: {
    full: boolean,
    token: string
  }
};

export type FETCH_USER_PREF_SUCCESS_ACTION = {
  type: 'FETCH_USER_PREF_SUCCESS',
  payload: {
    userPref: FullUserType,
  },
};

export type FETCH_USER_PREF_FAILURE_ACTION = {
  type: 'FETCH_USER_PREF_FAILURE',
  payload: {
    error: string,
  }
};
export type POST_USER_PREF_START_ACTION = {
  type: 'POST_USER_PREF_START',
  payload: {
    action: string,
    value: EditProfileValueType,
    token: string
  }
};

export type POST_USER_PREF_SUCCESS_ACTION = {
  type: 'POST_USER_PREF_SUCCESS',
  payload: {
    response: FullUserType,
  },
};

export type POST_USER_PREF_FAILURE_ACTION = {
  type: 'POST_USER_PREF_FAILURE',
  payload: {
    error: string,
  }
};

export type Action = 
  | FETCH_USER_PREF_START_ACTION
  | FETCH_USER_PREF_SUCCESS_ACTION
  | FETCH_USER_PREF_FAILURE_ACTION
  | POST_USER_PREF_START_ACTION
  | POST_USER_PREF_SUCCESS_ACTION
  | POST_USER_PREF_FAILURE_ACTION;

export const fetchUserPref = (full: boolean, token: string): FETCH_USER_PREF_START_ACTION => ({
  type: FETCH_USER_PREF_START,
  payload: {
    full,
    token
  },
});

export const userPrefFetched = (userState: FullUserType): FETCH_USER_PREF_SUCCESS_ACTION => {  
  return {
    type: FETCH_USER_PREF_SUCCESS,
    payload: { 
      userPref: userState,
    },
  };
};

export const userPrefFetchError = (error: string): FETCH_USER_PREF_FAILURE_ACTION => ({
  type: FETCH_USER_PREF_FAILURE,
  payload: {
    error,
  },
});

export const postUserPref = (
  action: string,
  value: EditProfileValueType,
  token: string
): POST_USER_PREF_START_ACTION => {  
  return {
    type: POST_USER_PREF_START,
    payload: {
      action,
      value,
      token
    },
  };
};

export const postUserPrefSuccess = (response: FullUserType): POST_USER_PREF_SUCCESS_ACTION => {  
  return {
    type: POST_USER_PREF_SUCCESS,
    payload: {
      response,
    },
  };
};

export const postUserPrefFailure = (error: string): POST_USER_PREF_FAILURE_ACTION => ({
  type: POST_USER_PREF_FAILURE,
  payload: {
    error,
  },
});
