// @flow

import { fork, spawn, all } from 'redux-saga/effects';
import { BASE_URL, STATIC_BASE_URL, YOUTUBE_BASE_URL, SPORTAGRAPH_BASE_URL } from 'constants/api';
import api from 'infrastructure/http/index';
import hitTagsSaga from 'sagas/hitTagsSaga.js';
import eventsSaga from 'sagas/eventsSaga.js';
import tagsSaga from 'sagas/tagsSaga.js';
import matchSaga from 'sagas/matchSaga.js';
import articleSaga from 'sagas/articleSaga.js';
import newsletterSaga from 'sagas/newsletterSaga.js';
import menuSaga from 'sagas/menuSaga';
import clubSaga from 'sagas/clubSaga.js';
import jobSaga from 'sagas/jobSaga.js';
import joueurSaga from 'sagas/joueurSaga.js';
import auteurSaga from 'sagas/auteurSaga.js';
import tagArticleSaga from 'sagas/tagArticleSaga.js';
import staffSaga from 'sagas/staffSaga.js';
import competitionsSaga from 'sagas/competitionsSaga.js';
import newsletterCategorySaga from 'sagas/newsletterCategorySaga.js';
import partnerSaga from 'sagas/partnerSaga.js';
import settingsSaga from 'sagas/settingsSaga.js';
import pageSaga from 'sagas/pageSaga.js';
import sidebarSaga from 'sagas/sidebarSaga';
import competitionSaga from 'sagas/competitionSaga';
import faqSaga from 'sagas/faqSaga';
import searchSaga from 'sagas/searchSaga';
import formSaga from 'sagas/formSaga';
import centenaireFormSaga from 'sagas/centenaireFormSaga';
import centenaireSaga from 'sagas/centenaireSaga';
import rematchFormSaga from 'sagas/rematchFormSaga';
import newsletterSubscriptionSaga from 'sagas/newsletterSubscriptionSaga';
import instagramSaga from 'sagas/instagramSaga';
import facebookSaga from 'sagas/facebookSaga';
import twitterSaga from 'sagas/twitterSaga';
import youtubeSaga from 'sagas/youtubeSaga';
import youtubeTvSaga from 'sagas/youtubeTvSaga';
import seasonsSaga from 'sagas/seasonsSaga';
import teamlistSaga from 'sagas/teamlistSaga';
import rencontreSaga from 'sagas/rencontreSaga';
import logeSaga from 'sagas/logeSaga';
import seminaireSaga from 'sagas/seminaireSaga';
import matchEventsSaga from 'sagas/matchEventsSaga';
import youtubeVideosChannelSaga from 'sagas/youtubeVideosChannelSaga';
import youtubePlaylistSaga from 'sagas/youtubePlaylistSaga';
import youtubeVideosPlaylistSaga from 'sagas/youtubeVideosPlaylistSaga';
import youtubeMostPopularVideosChannelSaga from 'sagas/youtubeMostPopularVideosChannelSaga';
import monthlyYoutubeVideosPlaylistSaga from 'sagas/monthlyYoutubeVideosPlaylistSaga';
import homeYoutubePlaylistsSaga from 'sagas/homeYoutubePlaylistsSaga';
import youtubeVideoPlayerSaga from 'sagas/youtubeVideoPlayerSaga';
import youtubeVideosChannelPlayerSaga from 'sagas/youtubeVideosChannelPlayerSaga';
import youtubeRandomVideosSaga from 'sagas/youtubeRandomVideosSaga';
import newVideosSaga from 'sagas/newVideosSaga';
import youtubeVideoPlayersSaga from 'sagas/youtubeVideoPlayersSaga';
import youtubeChannelPlaylistsSaga from 'sagas/youtubeChannelPlaylistsSaga';
import youtubeChannelDataSaga from 'sagas/youtubeChannelDataSaga';

import youtubePlaylistsLinked from 'sagas/youtubePlaylistsLinkedSaga';
import userSaga from 'sagas/userSaga';

export type GeneratorType = Generator<*, *, *>;

export default function*(): GeneratorType {
  yield fork(bootstrap);
}

function* bootstrap(): GeneratorType {
  const apiExecutor = api(BASE_URL);
  const apiExecutorWithCredential = api(BASE_URL, { withCredentials: true });
  const staticApiExecutor = api(STATIC_BASE_URL);
  const youtubeApiExecutor = api(YOUTUBE_BASE_URL);
  const sportagraphApiExecutor = api(SPORTAGRAPH_BASE_URL);

  try {
    yield all([
      spawn(hitTagsSaga()),
      spawn(articleSaga(apiExecutor, staticApiExecutor)),
      spawn(clubSaga(apiExecutor, staticApiExecutor)),
      spawn(jobSaga(apiExecutor, staticApiExecutor)),
      spawn(joueurSaga(apiExecutor, staticApiExecutor)),
      spawn(auteurSaga(apiExecutor)),
      spawn(tagArticleSaga(apiExecutor)),
      spawn(staffSaga(apiExecutor, staticApiExecutor)),
      spawn(newsletterSaga(apiExecutor, staticApiExecutor)),
      spawn(eventsSaga(apiExecutor, staticApiExecutor)),
      spawn(matchSaga(apiExecutor)),
      spawn(competitionsSaga(apiExecutor, staticApiExecutor)),
      spawn(tagsSaga(apiExecutor)),
      spawn(pageSaga(apiExecutor)),
      spawn(sidebarSaga(apiExecutor).sidebarSaga),
      spawn(competitionSaga(apiExecutor)),
      spawn(faqSaga(apiExecutor)),
      spawn(searchSaga(apiExecutor)),
      spawn(formSaga(apiExecutor)),
      spawn(centenaireSaga(sportagraphApiExecutor, staticApiExecutor)),
      spawn(centenaireFormSaga(apiExecutor)),
      spawn(rematchFormSaga(apiExecutor)),
      spawn(newsletterCategorySaga(apiExecutor)),
      spawn(newsletterSubscriptionSaga(apiExecutor)),
      spawn(instagramSaga(staticApiExecutor)),
      spawn(settingsSaga(staticApiExecutor)),
      spawn(menuSaga(staticApiExecutor)),
      spawn(partnerSaga(staticApiExecutor)),
      spawn(facebookSaga(staticApiExecutor)),
      spawn(twitterSaga(staticApiExecutor)),
      spawn(youtubeSaga(staticApiExecutor)),
      spawn(teamlistSaga(staticApiExecutor)),
      spawn(seasonsSaga(staticApiExecutor)),
      spawn(logeSaga(apiExecutor, staticApiExecutor)),
      spawn(seminaireSaga(apiExecutor)),
      spawn(rencontreSaga(apiExecutor, staticApiExecutor)),
      spawn(youtubeTvSaga(youtubeApiExecutor, apiExecutor)),
      spawn(matchEventsSaga(apiExecutor)),
      spawn(youtubeVideosChannelSaga(youtubeApiExecutor)),
      spawn(youtubePlaylistSaga(youtubeApiExecutor)),
      spawn(youtubeVideosPlaylistSaga(youtubeApiExecutor)),
      spawn(youtubeMostPopularVideosChannelSaga(youtubeApiExecutor)),
      spawn(monthlyYoutubeVideosPlaylistSaga(youtubeApiExecutor)),
      spawn(homeYoutubePlaylistsSaga(youtubeApiExecutor)),
      spawn(youtubeVideoPlayerSaga(youtubeApiExecutor, apiExecutor)),
      spawn(youtubeVideosChannelPlayerSaga(youtubeApiExecutor)),
      spawn(youtubeRandomVideosSaga(youtubeApiExecutor)),
      spawn(youtubePlaylistsLinked(youtubeApiExecutor)),
      spawn(newVideosSaga(youtubeApiExecutor)),
      spawn(youtubeVideoPlayersSaga(apiExecutor)),
      spawn(youtubeChannelPlaylistsSaga(youtubeApiExecutor)),
      spawn(youtubeChannelDataSaga(youtubeApiExecutor)),
      spawn(userSaga(apiExecutorWithCredential))
    ]);
  } catch (e) {
    // @TODO
  }
}
