//@flow

import {
  FETCH_USER_PREF_START,
  FETCH_USER_PREF_SUCCESS,
  FETCH_USER_PREF_FAILURE,
  POST_USER_PREF_START,
  POST_USER_PREF_SUCCESS,
  POST_USER_PREF_FAILURE,
} from 'actions/userActions';
import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';

import type { FullUserType } from 'types/User';
import type { Status } from 'types/Status';
import type { Action } from 'actions/userActions';

import { nullUserPrefs } from 'types/User';

export type State = {
  userPref: FullUserType,
  status: Status,
  error: string,
};

const initialState: State = {
  userPref: nullUserPrefs,
  status: STATUS_LOADING,
  error: '',
};

export default function(state: State = initialState, action: Action): State {
  switch (action.type) {
    case FETCH_USER_PREF_START:
      return {
        ...state,
        status: STATUS_LOADING,
      };
    case FETCH_USER_PREF_SUCCESS:
      return {
        ...state,
        userPref: action.payload.userPref,
        status: STATUS_SUCCESS,
      };
    case FETCH_USER_PREF_FAILURE:
      return {
        ...state,
        userPref: nullUserPrefs,
        error: action.payload.error,
        status: STATUS_FAILURE,
      };
    case POST_USER_PREF_START:
      return {
        ...state,
        status: STATUS_LOADING,
      };
    case POST_USER_PREF_SUCCESS:
      return {
        ...state,
        status: STATUS_SUCCESS,
      };
    case POST_USER_PREF_FAILURE:
      return {
        ...state,
        userPref: nullUserPrefs,
        error: action.payload.error,
        status: STATUS_SUCCESS,
      };
    default:
      return state;
  }
}
