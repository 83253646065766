// @flow

import React, { PureComponent, Fragment } from 'react';

import CheckboxInput from 'components/fragments/input/CheckboxInput';
import type { CategoryType } from 'types/Article';
import type { NewsletterSubscriptionEntryType } from 'types/NewsletterSubscription';
import type { FullUserType } from 'types/User';

export type StateProps = {
  userPref: FullUserType,
  categories: Array<CategoryType>,
  error: string,
};

export type DispatchProps = {
  subscribeNewsletter: (
  subscription: NewsletterSubscriptionEntryType,
  campaignMonitorKey: string,
  typeNewsletter: string | boolean,
  categories?: Array<string>
  ) => void,
};

type State = {
  categories: Array<string>,
};

type Props = {
  content?: Object,
} & StateProps & DispatchProps;

export default class NewsletterInterestsList extends PureComponent<Props, State> {

  state: State = {
    categories: [],
  };

  handleChecked = (id: string) => {
    const { categories: stateCategories } = this.state;
    const categories = [...stateCategories];
    const isAlreadyInPref = categories.indexOf(id);
    isAlreadyInPref !== -1 ? categories.splice(isAlreadyInPref, 1) : categories.push(id);
    this.setState({ categories });
  }

  renderCheckboxList = () => {
    const { categories } = this.props;

    return categories.map(({ id, name }) => {
      return (
        <CheckboxInput
          key={id}
          id={name}
          label={name}
          onChange={() => this.handleChecked(`${id}`)}
          className='checkbox check-right ft-500'
        />
      );
    });
  }

  saveFavorites = () => {
    const { categories } = this.state;
    const  {
      subscribeNewsletter,
      content,
      userPref: {
        identifie
      },
      error,
    } = this.props;
    if (error === 'rest_logged_user' && !identifie) {
      localStorage.setItem('favCategories', categories.toString());
      content && subscribeNewsletter(content.options, content.campaignMonitorKey, content.typeNewsletter);
    } else {
      content && subscribeNewsletter(content.options, content.campaignMonitorKey, content.typeNewsletter, categories);
    }
  }

  render() {
    return (
      <Fragment>
        <div className='interests'>
          {this.renderCheckboxList()}
        </div>
        <a 
          className="btn btn--primary btn--full js-confirm" 
          onClick={this.saveFavorites}
          role='button'
          tabIndex='0'
        >
          {`Finaliser l'inscription`}
        </a>
      </Fragment>
    );
  }
}