// @flow
import TopBar from 'components/header/TopBar';
import {connect} from 'react-redux';
import type { DispatchType, StateType } from 'types/Actions';
import type { DispatchProps } from 'components/header/TopBar';
import { fetchArticlesBillboard } from 'actions/newsletterActions';
import { withRouter } from 'react-router-dom';
import { fetchUserPref } from 'actions/userActions';

export default withRouter(
  connect(
    (state: StateType, ...ownProps: any) => ({
      topBarElements: state.menuState.topBar,
      tickerElements: state.newsletterState.articles,
      userPref: state.userState.userPref,
      login_url: state.settingsState.login_url,
      logout_url: state.settingsState.logout_url,
    }), (dispatch: DispatchType): DispatchProps => ({
      fetchArticlesBillboard: () => dispatch(fetchArticlesBillboard()),
      fetchUserPref: (full:boolean, token: string) => dispatch(fetchUserPref(full, token)),
    })
)(TopBar));
