// @flow
import type { RawEventType, EventPreviewType, EventType, EventMatchType, RawEventMatchType } from 'types/Event';
import { mergeIntoDefault, convertToJsDate, convertToValidDateFormat } from 'utils/objectUtils';
import { getEventDate } from 'utils/eventUtils';
import { nullEvent, nullEventPreview, nullEventMatch } from 'types/Event';

export function convertEventPreviewFromApi(rawEvent: RawEventType): EventPreviewType {
  if (!rawEvent) {
    return nullEventPreview;
  }

  const {
    start_date,
    end_date,
    title,
    slug,
    venue: { venue, address, city, zip },
    sidebar_content,
    tags,
    equipe,
    categories,
  } = rawEvent;

  return mergeIntoDefault(nullEventPreview, {
    startDate: new Date(convertToJsDate(start_date)),
    endDate: new Date(convertToJsDate(end_date)),
    title: title.raw,
    label: categories[0] ? categories[0].name : '',
    tag: tags && tags[0] ? tags[0].name : '',
    categories,
    team: equipe && equipe.name ? equipe.name : '',
    categoryId: categories && categories[0] ? categories[0].id : 0,
    slug,
    venue,
    address,
    city,
    zip,
    sidebar: sidebar_content ? sidebar_content.data : [],
  });
}

export function convertEventFromApi(rawEvent: RawEventType): EventType {
  if (!rawEvent) {
    return nullEvent;
  }
  const {
    id,
    utc_start_date_details,
    utc_end_date_details,
    start_date,
    end_date,
    title,
    slug,
    image,
    equipe,
    description: { rendered },
    venue: { venue, address, city, zip, geo_lat, geo_lng },
    all_day,
    organizer,
    categories,
    sidebar_content,
  } = rawEvent;

  return mergeIntoDefault(nullEvent, {
    id,
    startDateDetail: getEventDate(utc_start_date_details),
    endDateDetail: getEventDate(utc_end_date_details),
    startDate: new Date(convertToValidDateFormat(start_date)),
    endDate: new Date(convertToValidDateFormat(end_date)),
    title: title.raw,
    team: equipe && equipe.name ? equipe.name : '',
    label: categories && categories.length > 0 ? categories[0].name : '',
    tag: rawEvent.tags && rawEvent.tags[0] ? rawEvent.tags[0].name : '',
    categories,
    categoryId: categories && categories[0] ? categories[0].id : 0,
    slug,
    image: image !== false ? image.sizes.thumbnail : '',
    description: rendered,
    venue,
    adress: address,
    zip,
    city,
    organizer: organizer.length > 0 && organizer[0].organizer,
    all_day,
    lat: geo_lat,
    long: geo_lng,
    sidebar: sidebar_content ? sidebar_content.data : [],
  });
}

export function convertMatchEventFromApi(rawMatchEvent: RawEventMatchType): EventMatchType {
  if (!rawMatchEvent) {
    return nullEventMatch;
  }
  const {
    meta: {
      date_rencontre,
      lieu,
      france_logo,
      adversaire_logo,
      adversaire,
      equipeId,
      reception,
      equipeNom,
      billetterie
    },
    id,
    title: { rendered },
    link
  } = rawMatchEvent;

  return mergeIntoDefault(nullEventMatch, {
    id,
    startDate: new Date(date_rencontre.replace(' ', 'T')),
    endDate: new Date(date_rencontre.replace(' ', 'T')),
    title: rendered,
    label: rendered,
    categoryId: equipeId,
    tag: rawMatchEvent.competition.name,
    team: equipeNom,
    venue: '',
    adress: lieu,
    zip: '',
    city: '',
    billetterie,
    reception: reception,
    link,
    adversaire: {
      logo: adversaire_logo,
      name: adversaire
    },
    france: {
      logo: france_logo
    }
  });
}
