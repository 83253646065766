// @flow

import { 
  APP_SET_SHOW_COOKIE_INFO,
  APP_SET_ACCEPT_COOKIE,
  APP_SET_PROFILE_LATER,
  APP_SET_REMATCH_VOTE,
  APP_USER_SUBSCRIBE_NEWSLETTER, 
  APP_NEWSLETTER_INCREASE_COUNT 
} from 'actions/appActions';

import type { ActionType } from 'types/Actions';

export type State = {
  showCookieInfo: boolean,
  acceptCookie: boolean,
  rematchVote: {club: string, id: string, url: string, thumb: string},
  newsletterSubscribed: boolean,
  newsletterPopInCount: number,
  profileLater: number,
};

const initialState: State = {
  showCookieInfo: true,
  acceptCookie: false,
  rematchVote: {club: '', id: '', url: '', thumb: ''},
  newsletterSubscribed: false,
  newsletterPopInCount: 0,
  profileLater: 0,
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case APP_SET_SHOW_COOKIE_INFO:
      return {
        ...state,
        showCookieInfo: action.payload.showCookieInfo
      };
    case APP_SET_ACCEPT_COOKIE:
      return {
        ...state,
        acceptCookie: action.payload.acceptCookie
      };
    case APP_SET_PROFILE_LATER:
      return {
        ...state,
        profileLater: action.payload.profileLater
      };
    case APP_SET_REMATCH_VOTE:
      return {
        ...state,
        rematchVote: action.payload.rematchVote
      };
    case APP_USER_SUBSCRIBE_NEWSLETTER: 
      return {
        ...state,
        newsletterSubscribed: action.payload.newsletterSubscribed,
      };
    case APP_NEWSLETTER_INCREASE_COUNT: 
      if (state.newsletterPopInCount < 2) {
        return {
          ...state,
          newsletterPopInCount: state.newsletterPopInCount +1,
        };
      }
      return { ...state };
    default:
      return state;
  }
}
