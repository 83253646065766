// @flow

import {
  DISPLAY_MODAL,
  HIDE_MODAL,
} from 'actions/modalActions';

import type { ActionType } from 'types/Actions';

export type State = {
  modalNb: number,
  content: Object,
  force: boolean,
};

const initialState: State = {
  modalNb: 0,
  content: {},
  force: false,
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case DISPLAY_MODAL:
      return {
        ...state,
        modalNb: action.payload.modalNb,
        content: action.payload.content,
        force: action.payload.force,
      };
    case HIDE_MODAL:
      return {
        ...state,
        modalNb: action.payload.modalNb,
        content: {},
        force: true,
      };
    default:
      return state;
  }
}
