// @flow
import React, { Component, Fragment } from 'react';

import ModalFormContact from 'components/entreprise/ModalFormContact';
import Loading from 'components/fragments/Loading';
import { STATUS_LOADING } from 'constants/statusConstants';
import { handleTagEvent, getGmtUserData } from 'utils/tagManagerUtils';

import type { ContactFormType } from 'types/ContactForm';
import type { Status } from 'types/Status';
import type { FullUserType } from 'types/User';

export type StateProps = {
  contactFormList: Array<ContactFormType>,
  fetchStatus: Status,
  userPref: FullUserType
};

export type DispatchProps = {
  onSubmit: (formInputs: Object, id: number) => void,
};

type Props = {
  content: {
    people: number,
    match: string,
    id: string,
    title: string,
  },
} & StateProps & DispatchProps;

class ModalFormLoge extends Component<Props> {

  shouldComponentUpdate(nextProps: Props) {
    if (nextProps.content !== this.props.content) {
      return true;
    }

    return false;
  }

  _getLogeForm = () => {
    const { contactFormList } = this.props;

    if (contactFormList && contactFormList.length > 0) {
      const logeForm = contactFormList.filter((form) => form.title === 'loge');

      if (logeForm && logeForm.length > 0) {
        return logeForm[0].id;
      }
    }

    return 0;
  }

  _handleSubmit = (values: Object) => {
    const { userPref, onSubmit, content } = this.props;
    const { name, email, company, message, phone } = values;

    const formId = this._getLogeForm();
    const recap = `Match: ${content && content.match} \n Nombre d'invités: ${content && content.people}`;
    const formData = {
      'loge-nom': name,
      'loge-email': email,
      'loge-message': message,
      'loge-societe': company,
      'loge-telephone': phone,
      'loge-id': content && content.id,
      'loge-recap': recap,
    };

    onSubmit(formData, formId);
    handleTagEvent(
      'offres_entreprises_match',
      'clic_contact',
      `${content.title}${userPref.identifie === false ? '' : ` | ${getGmtUserData(userPref)}`}`
    );
  }

  _renderFormInputs = () => {
    const { fetchStatus, content: { match } } = this.props;

    if (fetchStatus === STATUS_LOADING) {
      return <Loading />;
    }

    return <ModalFormContact onSubmit={this._handleSubmit} tagManagerContent={match}/>;
  }

  render() {
    const { content } = this.props;

    return (
      <Fragment>
        <h5 className="ft-400 mb-1">Récapitulatif de l’événement</h5>
        <div className="modal__event-abstract mb-4">
          <div>
            <h6>match</h6>
            <p>{content && content.match}</p>
          </div>
          <div>
            <h6>invités</h6>
            <p>{content && content.people} invités</p>
          </div>
        </div>
        <h5 className="ft-400 mb-1">Informations de contact</h5>
        {this._renderFormInputs()}
      </Fragment>
    );
  }
}

export default ModalFormLoge;
