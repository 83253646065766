// @flow

export function shuffle(array: Array<any>): Array<any> {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export function getPropertyAsFilterOptions(toFilter: Array<any>, property: string) {
  return toFilter.reduce((accumulator, currentValue) => {
    if (accumulator.find(option => option.value === currentValue[property])) return accumulator;
    else return [...accumulator, { value: currentValue[property], label: currentValue[property] }];
  }, []);
}
export function getRandomInts(max: number, num: number): Array<number> {
  let ints = [];
  while (ints.length < num) {
    let randNum = Math.floor(Math.random() * Math.floor(max));
    if (ints.indexOf(randNum) === -1) {
      ints.push(randNum);
    }
  }
  return ints;
}
