// @flow
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

type OwnProps = {
  acceptCookies: ()=> void,
};

export type StateProps = {
  showCookieInfo: boolean,
  title: string,
  cookies: number
};

export type DispatchProps = {
  closeCookieInfo: () => void,
  acceptCookie: () => void
};

type Props = OwnProps & StateProps & DispatchProps;

const cookieEndContent =
  ", vous acceptez les CGU ainsi que l'utilisation de cookies et de technologies similaires, y compris celle" +
  ' de partenaires tiers, pour vous proposer des contenus pertinents et des publicités ciblées en fonction' +
  " de vos centres 'intérêts.";

class Cookie extends PureComponent<Props> {
  render() {
    const { title, cookies, showCookieInfo, closeCookieInfo, acceptCookies, acceptCookie } = this.props;
    
    if (showCookieInfo) {
      return (
        <div className="modal visible">
          <div className="container">
            <div className="modal__overlay"></div>
            <div className="box cookie">
              <p>
                En poursuivant votre navigation sur{' '}
                {title}
                {cookieEndContent}
                <br />
                {cookies !== 0 &&
                  <Link to="/politique-de-cookies">
                    En savoir plus sur les cookies
                  </Link>
                }
                {' '}
                <Link to="/cgu">
                  Afficher les CGUs
                </Link>
              </p>
              <div className='btn-container'>
                <button 
                  onClick={
                    () => {
                      closeCookieInfo();
                      acceptCookies();
                      acceptCookie();
                  }}
                  className="btn btn--primary btn--full">
                  Accepter les cookies
                </button>
                <button 
                  onClick={closeCookieInfo}
                  className="btn btn--primary btn--full">
                  Refuser
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default Cookie;
