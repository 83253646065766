// @flow
import FilterOptions from 'components/header/FilterOptions';
import { connect } from 'react-redux';

import type { DispatchType, StateType } from 'types/Actions';
import type { StateProps, DispatchProps } from 'components/header/FilterOptions';
import { setQuery, setQueryCentenaire } from 'actions/filterOptionsActions';

export default connect(
  (state: StateType): StateProps => ({
    query: state.filterOptionsState.query,
    queryCentenaire: state.filterOptionsState.queryCentenaire
  }),
  (dispatch: DispatchType): DispatchProps => ({
    setQuery: (query: string) => dispatch(setQuery(query)),
    setQueryCentenaire: (query: string) => dispatch(setQueryCentenaire(query))
  })
)(FilterOptions);
