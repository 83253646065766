// @flow
import type {
  RawJoueurType,
  JoueurType,
} from 'types/Joueur';
import { nullJoueur } from 'types/Joueur';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertJoueurFromApi(rawJoueur: RawJoueurType): JoueurType {
  if (!rawJoueur) {
    return nullJoueur;
  }

  const {
    title,
    content,
    slug,
    featured_media,
    sidebar_content,
    meta,
    link,
    meta_title,
    meta_description,
    poste,
    nombre_selections,
    nombre_transformations,
    nombre_penalites,
    nombre_essais,
    nombre_drops,
    matchs_joues,
    youtube_playlist,
    equipes,
    historique,
    id,
  } = rawJoueur;

  return mergeIntoDefault(nullJoueur, {
    title: title.raw,
    logo: typeof featured_media === 'number' ? null : featured_media.src,
    featured_media: featured_media,
    content: content.data,
    dateNaissance: meta.dateNaissance,
    genre: meta.genre,
    numInternational: meta.numInternational,
    age: meta.age,
    poste: {id: poste.id, name: poste.name},
    sidebar: sidebar_content ? sidebar_content.data : [],
    slug,
    link,
    nombre_selections,
    nombre_transformations,
    nombre_penalites,
    nombre_essais,
    nombre_drops,
    matchs_joues,
    meta_title,
    meta_description,
    equipes,
    historique,
    id,
    videos: youtube_playlist,
  });
}
