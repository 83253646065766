// @flow
import type { BlocContent } from 'types/NewsletterBloc';
import { getPlaceholder } from 'utils/assetsUtils';

export const nullArticle = {
  slug: '',
  link: '',
  date: new Date(),
  label: {
    name: '',
    slug: ''
  },
  caption: '',
  title: 'Default title',
  srcset: {},
  illustration: getPlaceholder(),
  illustration_video:[],
  blocs: [],
  tags: [],
  twitter: 0,
  facebook: 0,
  sidebar: [],
  meta_title: '',
  meta_description: '',
  redirection: '',
  auteur: {
    nom: '',
    author_link: '',
    description: '',
    role_edition: '',
    avatar_urls: {}
  },
  typeFlow: 'ArticleType',
  category: {
    id: 0,
    slug: '',
    name: '',
  },
};

export const nullLoadingArticle = {
  ...nullArticle,
  title: 'Chargement de l\'article',
  illustration: getPlaceholder(),
  blocs: [
    {
      tagName: 'p',
      type: 'element',
      attributes: [],
      children: [
        {
          type: 'text',
          text: 'Chargement du contenu de l\'article...'
        }
      ]
    }
  ],
};

export const nullFailureArticle = {
  ...nullArticle,
  title: 'Oups',
  illustration: getPlaceholder(),
  blocs: [
    {
      tagName: 'p',
      type: 'element',
      attributes: [],
      children: [
        {
          type: 'text',
          text: 'Un problème est survenue, merci de réactualiser la page.'
        }
      ]
    }
  ],
};

export const nullCategory = {
  id: 0,
  name: '',
  slug: ''
};

export const nullCategoryArticle = {
  id: 0,
  name: '',
  slug: '',
  items: []
};

export type SourceSetType = {
  [key: string]: {
    src: string,
    width: number,
    height: number,
  },
};

type FeaturedMediaType = {
  id: number,
  src: string,
  legend: string,
  alt: string,
  width: number,
  height: number,
  sizes: Object
} | number;

export type RawNewsletterFavoritesArticleType = {
  id: number,
  link: string,
  title: string,
  date: string,
  featured_media: FeaturedMediaType,
  category: {
    id: number,
    name: string,
  }
};

export type CategoryType = {
  id: number,
  name: string,
  slug: string
};

export type ArticleType = {
  slug: string,
  typeFlow: string,
  link: string,
  date: Date | string,
  label: {
    name: string,
    slug: string
  },
  caption: string,
  title: string,
  srcset: SourceSetType,
  illustration: string,
  illustration_video: Array<BlocContent>,
  blocs: Array<BlocContent>,
  tags: Array<{
    id: number,
    name: string,
    slug: string
  }>,
  twitter: number,
  facebook: number,
  sidebar: Array<BlocContent>,
  meta_title: string,
  meta_description: string,
  redirection: string,
  auteur: {
    nom: string,
    author_link: string,
    description: string,
    role_edition: string,
    avatar_urls: {
      media_id?: number,
      '24'?: string,
      '26'?: string,
      '32'?: string,
      '48'?: string,
      '64'?: string,
      '96'?: string,
      full?: string,
    }
  },
  category: CategoryType,
};

export type RawArticleType = {
  id: number,
  date: string,
  data_gmt: string,
  featured_media: FeaturedMediaType,
  label: {
    id: number,
    name: string,
    slug: string
  },
  guid: { rendered: string },
  modified: string,
  modified_gmt: string,
  author: number,
  categories: Array<number>,
  comment_status: string,
  content: { rendered: string, protected: boolean, data: Array<BlocContent> },
  excerpt: { rendered: string, protected: boolean },
  featured_video: { rendered: string, data: Array<BlocContent> },
  format: string,
  link: string,
  meta: Object,
  ping_status: string,
  slug: string,
  sticky: boolean,
  tags_list: Array<{
    id: number,
    name: string,
    slug: string
  }>,
  template?: string,
  title: Object,
  meta_title: string,
  meta_description: string,
  type: string,
  _links: Object,
  sidebar_content: {
    data: Array<BlocContent>
  },
  redirect: {
    type: string,
    postid: string,
    title: string,
    url: string,
  },
  author: {
    id: number,
    name: string,
    author_link: string,
    description: string,
    editing_role: string,
    avatar_urls: {
      media_id?: number,
      '24'?: string,
      '26'?: string,
      '32'?: string,
      '48'?: string,
      '64'?: string,
      '96'?: string,
      full?: string,
    }
  },
  category?: CategoryType,
};

export type RawCategoryType = {
  id: number,
  count: number,
  description: string,
  link: string,
  name: string,
  slug: string,
  taxonomy: string,
  parent: number,
  meta: Array<any>,
  _links: {
    self: Array<{
      href: string
    }>,
    collection: Array<{
      href: string
    }>,
    about: Array<{
      href: string
    }>,
    'wp:post_type': Array<{
      href: string
    }>,
    curies: Array<{
      name: string,
      href: string,
      templated: boolean
    }>
  }
};

export type CategoryArticleType = {
  id: number,
  name: string,
  slug: string,
  items: Array<ArticleType>
};

export type RawCategoryArticleType = {
  id: number,
  count: number,
  description: string,
  link: string,
  name: string,
  slug: string,
  taxonomy: string,
  parent: number,
  meta: Array<any>,
  _links: {
    self: Array<{
      href: string
    }>,
    collection: Array<{
      href: string
    }>,
    about: Array<{
      href: string
    }>,
    'wp:post_type': Array<{
      href: string
    }>,
    curies: Array<{
      name: string,
      href: string,
      templated: boolean
    }>
  },
  items: Array<ArticleType>
};
