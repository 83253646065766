// @flow
import App from 'components/app/App';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { fetchSettings } from 'actions/appActions';
import { increaseNewsletterPopInCount } from 'actions/appActions';
import { fetchMenu } from 'actions/menuActions';
import { fetchPartners } from 'actions/partnerActions';
import { fetchCategories } from 'actions/categoriesActions';
import { fetchPageDetailPreview } from 'actions/pageActions';
import { displayModal } from 'actions/modalActions';
import type { StateProps, DispatchProps } from 'components/app/App';
import type { StateType, DispatchType } from 'types/Actions';
import type { EditProfileValueType } from 'types/User';
import { postUserPref } from 'actions/userActions';

export default withRouter(
  connect(
    (state: StateType): StateProps => ({
      acceptCookie: state.appState.acceptCookie,
      favicons: state.settingsState.favicons,
      titleLigue: state.settingsState.title,
      linkPreview: state.pageDetailPreviewState.link,
      newsletterSubscribed: state.appState.newsletterSubscribed,
      newsletterPopInCount: state.appState.newsletterPopInCount,
      userPref: state.userState.userPref,
      error: state.userState.error,
    }),
    (dispatch: DispatchType): DispatchProps => ({
      fetchSettings: () => dispatch(fetchSettings()),
      fetchMenu: () => dispatch(fetchMenu()),
      fetchCategories: () => dispatch(fetchCategories()),
      fetchPartners: () => dispatch(fetchPartners()),
      fetchPageDetailPreview: (id: number) => dispatch(fetchPageDetailPreview(id)),
      increaseNewsletterPopInCount: () => dispatch(increaseNewsletterPopInCount()),
      displayModal: (modalNb: number, content: Object) => dispatch(displayModal(modalNb, content)),
      postUserPref: (action: string, value: EditProfileValueType, token: string) => dispatch(postUserPref(action, value, token)),
    })
  )(App)
);
