// @flow
import React, { PureComponent } from 'react';
import 'components/footer/partners/Partners.css';
import LazyLoad from 'components/fragments/LazyLoad';

type Props = {
  partners: Array<Object>
};

type State = {
  current: number,
  isMobile: boolean,
};

class Partners extends PureComponent<Props, State> {
  timerID: IntervalID;
  constructor(props: Props) {
    super(props);

    this.state = {
      current: 0,
      isMobile: false,
    };
  }

  slidePartners = () => {
    const { partners } = this.props;
    const { current } = this.state;
    const sliderNumber = this._getNumberOfSlide();

    const currentValue = current + sliderNumber < partners.length ? current + sliderNumber : 0;

    this.setState({
      current: currentValue
    });
  }

  _getNumberOfSlide = () => {
    const { isMobile } = this.state;

    if (isMobile) {
      return 3;
    }

    return 8;
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', () => this.updateDimensions());

    this.timerID = setInterval(() => this.slidePartners(), 3500);
  }

  updateDimensions = () => {
    if (window.innerWidth <= 750) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
    window.removeEventListener('resize', () => this.updateDimensions());
  }

  renderPartners = () => {
    const { partners } = this.props;
    const { current } = this.state;

    const styleLogo = {
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat'
    };

    return partners.map((partner, index) => {
      if (index >= current && index < current + this._getNumberOfSlide()) {
        return (
          <li
            className="partner_logo"
            key={index}
            style={{ ...styleLogo, backgroundImage: `url(${partner.illustration})` }}
          >
            <a title={`Voir le site du partenaire : ${partner.title} (nouvel onglet)`}
              href={partner.link}
              rel="noopener noreferrer"
              target={partner.target} />
          </li>
        );
      }
      return null;
    });
  };

  render() {
    return (
      <LazyLoad isWhite>
        <ul className="partenaire">{this.renderPartners()}</ul>
      </LazyLoad>
    );
  }
}

export default Partners;
