// @flow
import type {
  RawRencontreType,
  RencontreType,
} from 'types/Rencontre';
import { nullRencontre } from 'types/Rencontre';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertRencontreFromApi(rawRencontre: RawRencontreType): RencontreType {
  if (!rawRencontre) {
    return nullRencontre;
  }

  const { 
    id,
    date,
    slug,
    link,
    title,
    content,
    featured_media,
    meta,
    meta_title,
    meta_description,
    saison,
    niveau,
    competition,
    faits_de_jeu,
    dernieres_rencontres,
    joueurs,
    staffs,
  } = rawRencontre;

  return mergeIntoDefault(nullRencontre, {
    id,
    date,
    slug,
    link,
    title,
    content,
    featured_media,
    meta,
    meta_title,
    meta_description,
    saison,
    niveau,
    competition,
    faits_de_jeu,
    dernieres_rencontres,
    joueurs,
    staffs,
  });
};
