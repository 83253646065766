// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import type { CentenaireClubPreviewType } from 'types/Centenaire';
import { convertCentenaireClubPreviewFromApi } from 'services/Centenaire';

import {
  convertCentenaireDocumentFromApi,
} from 'services/Centenaire';

function centenaireApi(executor: ApiExecutorType) {
  return { getCentenaireDocuments, getCentenaireDocument, getCentenaireDocumentDownload, getCentenaireClubs, getCentenaireDatas };

  function getCentenaireClubs(): Promise<Array<CentenaireClubPreviewType>> {
    return executor.get('centenaireclubs.json').then(response => Promise.all(
      response.clubs.map(convertCentenaireClubPreviewFromApi))
    );
  }

  function getCentenaireDatas() {
    return executor.get('centenaireclubs.json').then(response => {
      return {
        clubs: response.clubs.map(convertCentenaireClubPreviewFromApi),
        annees: response.annees,
        niveaux: response.niveaux,
        equipes: response.equipes,
        villes: response.villes,
      };
    });
  }

  function getCentenaireDocument(slug: string) {  
    let url = `media/${slug}/`;
    return executor
      .get(url, {
        headers: { Authorization: `bearer 89fb919d50383a610fb0cb437fadc39a4dfaf6f4a89b6039358d0d20f06c65c1` }
      })
      .then(response => {
        return convertCentenaireDocumentFromApi(response);
      })
      .catch(function (error) {
        throw new Error(error);
      });
  }

  function getCentenaireDocumentDownload(slug: string) {  
    let url = `media/${slug}/download`;
    return executor
      .get(url, {
        headers: { Authorization: `bearer 89fb919d50383a610fb0cb437fadc39a4dfaf6f4a89b6039358d0d20f06c65c1` }
      })
      .then(response => {
        return response.s3_file;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getCentenaireDocuments(data: any) {
    const nombre = data.params.request.length ? data.params.request.length : 10;
    const page = data.params.request.index ? ((data.params.request.index / nombre) + 1) : 1;
    const keyword = data.params.request.query ?  data.params.request.query : '*';
    let url = `media/?collectionId=8271BC2F-019B-4F7C-94D94307BE4A841B&total=1&page=${page}&limit=${nombre}&keyword=${keyword}`;
    const sort = data.params.request.sort.length > 0 ? data.params.request.sort : false;
    if (sort) {
      url += '&orderBy='+sort[0];
    }
    return executor
      .get(url, {
        headers: { Authorization: `bearer 89fb919d50383a610fb0cb437fadc39a4dfaf6f4a89b6039358d0d20f06c65c1` }
      })
      .then(response => {
        return {
          documents: response.media.map(document => 
            convertCentenaireDocumentFromApi(document)
          ), 
          total: response.total.count
        };
      })
      .catch(function (error) {
        throw new Error(error);
      });
  }
}
export default centenaireApi;
