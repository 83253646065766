// @flow
import Menu from 'components/header/Menu';
import { connect } from 'react-redux';

import type { StateType } from 'types/Actions';

export default connect((state: StateType, ...ownProps: any) => ({
  menuElements: state.menuState.header,
  logo: state.settingsState.logo,
  logo_transparent: state.settingsState.logo_transparent,
  titleLigue: state.settingsState.title,
  liens_rs: state.settingsState.liens_rs,
  userPref: state.userState.userPref,
  logout_url: state.settingsState.logout_url,
  login_url: state.settingsState.login_url,
}), null, null, { pure: false })(Menu);
