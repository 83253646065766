// @flow
import React, { PureComponent, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';

import Search from 'components/header/Search';
import MenuElement from 'components/header/MenuElement';
import MenuElementPlus from 'components/header/MenuElementPlus';
import MenuDashboard from 'containers/profile/MenuDashboardContainer';
import { logoWidth, isEspacePerso } from 'constants/menuConstants';
import { handleTagEvent, getGmtUserData } from 'utils/tagManagerUtils';
import { getCasEndpoint } from 'utils/urlUtils';
import { ffrUserName } from 'utils/nameUtils';
import type { liensRsType } from 'types/Settings';
import type { FullUserType } from 'types/User';
import type { MenuElementType } from 'types/MenuElement';
import type { RouterProps } from 'types/Router';
import userAvatar from 'assets/img/placeholder-profil.png';

type StateProps = {
  menuElements: MenuElementType[],
  logo: string,
  logo_transparent: string,
  titleLigue: string,
  liens_rs: liensRsType,
  userPref: FullUserType,
  login_url: string,
  logout_url: string
};

type Props = {
  onCloseMenu: Function,
  location: any,
} & StateProps & RouterProps;

type ComponentState = {
  menuItems: Array<{ id: number, size: number }>,
  isMobile: boolean,
  hiddenMenuItems: Array<number>,
  menuElements: Array<MenuElement>,
  menuLinkX: number,
  hiddenSubMenu: boolean,
  redirection: string,
  loggedIn: boolean,
};

class Menu extends PureComponent<Props, ComponentState> {
  _menuElements: Array<?MenuElement>;
  _menu: ?HTMLElement;
  _menuRight: ?HTMLElement;
  _menuLink: ?HTMLElement;
  _frameId: string;

  state: ComponentState = {
    menuItems: [],
    isMobile: false,
    hiddenMenuItems: [],
    menuElements: [],
    menuLinkX: 0,
    hiddenSubMenu: false,
    redirection: this.props.location.pathname,
    loggedIn: false,
  };

  static defaultProps = {
    menuElements: []
  };

  constructor(props: Props) {
    super(props);
    this._menuElements = [];
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  static getDerivedStateFromProps(props: Props, state: ComponentState) {
    const { userPref, userPref : { identifie }, location: { pathname } } = props;
    const { redirection } = state;
    let newState = {};

    if (pathname !== redirection) {
      newState.redirection = pathname;
    };
    if (userPref) {
      newState.loggedIn = identifie;
    };
    if (newState.loggedIn || newState.loggedIn !== state.loggedIn || newState.redirection) {
      return newState;
    };
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  resetMenuElementRef = (c: ?MenuElement) => {
    this._menuElements = [];
    this.addMenuElementRef(c);
  };

  addMenuElementRef = (c: ?MenuElement) => {
    this._menuElements.push(c);
  };

  isDisplayed = (id: number) => {
    return !this.state.hiddenMenuItems.includes(id);
  };

  hiddenTemporary = () => {
    this.setState({ hiddenSubMenu: true });

    setTimeout(() => {
      this.setState({ hiddenSubMenu: false });
    }, 500);
  };

  updateDimensions() {
    const menu = this._menu;
    const menuRight = this._menuRight;
    const menuLink = this._menuLink;
    if (menu && menuRight && menuLink) {
      const menuLinkBounds = menuLink.getBoundingClientRect();
      const menuLinkX = menuLinkBounds.left;

      if (menuLinkX !== this.state.menuLinkX) this.setState({ menuLinkX });
      const maxWidth = menu.offsetWidth;
      const width_logo = logoWidth;

      const width_menu_links =
        this._menuElements.reduce((total, item) => (item ? total + item.getSize() : total), 0) +
        this._menuElements.length * 12;

      const width_menu_right = menuRight.offsetWidth;
      const totalWidth = width_menu_links + width_menu_right + width_logo + 50;

      let currentWidth = totalWidth;
      let deleted = [];
      if (maxWidth > 1000) {
        for (let i = this._menuElements.length; i > 0; i--) {
          if (currentWidth > (maxWidth - logoWidth)) {
            if (this._menuElements[i]) currentWidth -= this._menuElements[i].getSize();
            deleted = [...deleted, i];
          }
        }
        if (this.state.isMobile) this.setState({ isMobile: false });
      } else {
        if (!this.state.isMobile) this.setState({ isMobile: true });
      }
      if (
        (deleted.length > 0 || this.state.hiddenMenuItems.length > 0) &&
        JSON.stringify(deleted) !== JSON.stringify(this.state.hiddenMenuItems)
      ) {
        this.setState({ hiddenMenuItems: deleted });
      }
    }
  }

  hiddenAndClose = () => {
    this.hiddenTemporary();
    this.props.onCloseMenu && this.props.onCloseMenu();

  }

  renderSubMenu = (subMenus: Array<Object>): any => {
    return subMenus.map((subMenu, index) => {
      if (subMenu.type === 'custom') {
        const subMenus = subMenu.items ? subMenu.items : [];
        if (subMenu.url === '#') {
          if (subMenus.length > 0) {
            const subMenusRender = [];
            if (subMenus.length > 7) {
              let subMenusSlice = subMenus.slice(0,6);
              subMenusRender.push(subMenusSlice);
              subMenusSlice = subMenus.slice(7,13);
              subMenusRender.push(subMenusSlice);
            } else {
              subMenusRender.push(subMenus);
            }
            const classe = this;
            return (
              <li key={index}>
                <span className="menu__category">{subMenu.title}</span>
                {subMenusRender.map(function(subMenus, i){
                    return <ul 
                      key={i}>
                        <React.Fragment>
                          {classe.renderSubMenu(subMenus)}
                        </React.Fragment>
                    </ul>;
                })}
              </li>
            );
          } else {
            return (
              <li key={index}>
                <span className="menu__category">{subMenu.title}</span>
              </li>
            );
          }
        } else {
          return (
            <li key={index}>
              <Link 
                to={`${subMenu.slug_complet}`}
                target="_blank"
                onClick={() => this.hiddenAndClose()}>
                  {subMenu.title}
              </Link>
            </li>
          );
        }
      } else if (subMenu.type === 'post_type') {
        return (
          <li key={index}>
            <Link to={`${subMenu.slug_complet}`} onClick={() => this.hiddenAndClose() }>{subMenu.title}</Link>
          </li>
        );
      }
      return null;
    });
  };

  renderChidrenMenuElement = (subMenus: Array<Object>) => {
    const { hiddenSubMenu } = this.state;

    if (!hiddenSubMenu) {
      return <Fragment>{this.renderSubMenu(subMenus)}</Fragment>;
    }

    return undefined;
  }

  renderMenuElement = () => {
    const { menuElements, onCloseMenu } = this.props;
    //const menus = menuElements.filter(item => item.parent === 0);
    let indexMenuElements = 0;

    if (!menuElements) return [];
    let renderedMenu = menuElements.map((menu, index) => {
      const subMenus = menu.items ? menu.items : [];
      if (menu.type === 'post_type') {
        if (menu.classes === 'bouton-vert') {
          return null;
        }
        const url = this.props.location.pathname.split('/');
        if (subMenus.length > 0) {
          let col = false;
          subMenus.forEach(function(subMenu) {
            if (subMenu.items && subMenu.items.length > 0) {
              col = true;
            }
          });
          const menuEl = (
            <MenuElement
              url={`/${menu.object_slug}`}
              urlType={menu.type}
              title={menu.title}
              titleAll={menu.title}
              isMobile={this.state.isMobile}
              ref={index === 0 ? this.resetMenuElementRef : this.addMenuElementRef}
              id={indexMenuElements}
              isDisplayed={this.isDisplayed}
              menuX={this.state.menuLinkX}
              key={index}
              col={!!col}
              isActive={url[1] === menu.object_slug}
              onCloseMenu={onCloseMenu}
            >
              {this.renderChidrenMenuElement(subMenus)}
            </MenuElement>
          );
          indexMenuElements++;
          return menuEl;
        } else {
          const menuEl = (
            <MenuElement
              url={`/${menu.object_slug}`}
              urlType={menu.type}
              title={menu.title}
              titleAll={menu.title}
              isMobile={this.state.isMobile}
              ref={index === 0 ? this.resetMenuElementRef : this.addMenuElementRef}
              id={indexMenuElements}
              isDisplayed={this.isDisplayed}
              menuX={this.state.menuLinkX}
              key={index}
              isActive={url[1] === menu.object_slug}
              onCloseMenu={onCloseMenu}
            />
          );
          indexMenuElements++;
          return menuEl;
        }
      }
      return null;
    });
    const menuPlus = menuElements.find(menu =>  menu.type === 'custom' && menu.title === 'Plus');
    if (menuPlus) {
      // const subMenusPlus = menuElements.filter(item => item.parent === menuPlus.id);
      // const url = this.props.location.pathname.split('/');
      // renderedMenu.push(
      //   <MenuElement
      //     url={`/${menuPlus.object_slug}`}
      //     urlType={menuPlus.type}
      //     title={menuPlus.title}
      //     titleAll={menuPlus.title}
      //     isMobile={this.state.isMobile}
      //     ref={indexMenuElements === 0 ? this.resetMenuElementRef : this.addMenuElementRef}
      //     id={indexMenuElements}
      //     isDisplayed={this.isDisplayed}
      //     menuX={this.state.menuLinkX}
      //     key={indexMenuElements}
      //     isActive={url[1] === menuPlus.object_slug}
      //     onCloseMenu={onCloseMenu}
      //   >
      //     <Fragment>{this.renderSubMenu(subMenusPlus)}</Fragment>
      //   </MenuElement>
      // );
    }
    return renderedMenu;
  };

  render() {
    const { hiddenMenuItems, loggedIn, redirection } = this.state;
    const {
      onCloseMenu,
      logo,
      logo_transparent,
      titleLigue,
      menuElements,
      liens_rs,
      location,
      userPref,
      userPref: { avatar, profil: { nom, prenom } },
      login_url,
      logout_url,
    } = this.props;

    const hiddenMenuElements = hiddenMenuItems.map(id => this._menuElements[id]);

    let logo_used = logo;
    const header_over = ! location.pathname.match('actualites/.*/.*') &&
                        ! location.pathname.match('resultats-de-recherche/') &&
                        ! location.pathname.match('clubs/') &&
                        (! location.pathname.match(/evenements\/[a-z-0-9]/g)
                          || location.pathname.match(/evenements\/[0-9]{4}\/[a-z]/g)
                        );
    if (header_over) {
      logo_used = logo_transparent;
    }

    if (hiddenMenuElements.length > 0) {
      hiddenMenuElements.shift();
      const menuPlus = menuElements.find(menu => menu.type === 'custom' && menu.title === 'Plus');
      if (menuPlus) {
        const menuPlusChildren = menuElements.filter(item => item.parent === menuPlus.id);

        menuPlusChildren.forEach((menu, index) => {
          hiddenMenuElements.splice(0, 0, (
              <MenuElement
                url={`/${menu.object_slug}`}
                urlType={menu.type}
                title={menu.title}
                titleAll={menu.title}
                isMobile={this.state.isMobile}
                id={menu.id}
                isDisplayed={this.isDisplayed}
                menuX={this.state.menuLinkX}
                key={index}
                onCloseMenu={onCloseMenu}
              />
          ));
        });
      }
    }

    if (!isEspacePerso(location.pathname)) {
      return (
        <nav className="menu" ref={c => (this._menu = c)}>
          <Link to="/" title="Retour à la page d'accueil" onClick={onCloseMenu}>
            <span style={{ width: 100, height: 58, display: 'inline-block' }}>
              {logo_used && <img alt="logo" className="menu__logo" src={logo_used} />}
            </span>
          </Link>
          {!loggedIn && (
            <a
              to="/"
              className="btn btn--primary menu__login"
              title="Se connecter ou s'inscrire sur le site de la TBD"
              href={getCasEndpoint(login_url, '/tableau-de-bord')}
              onClick={handleTagEvent('menu_transverse', 'clic_connexion_inscription', '')}
            >
              {"Se connecter / S'inscrire"}
            </a>
          )}
          {loggedIn && (
            <div className="menu__user">
              <p className="ft-truncat">
                {avatar && <img src={avatar} alt="" style={{ width: '24px', height: '24px' }} />}
                {!avatar && <img src={userAvatar} alt="" style={{ width: '24px', height: '24px' }} />}
                {(nom || prenom) &&`${ffrUserName(prenom)} ${ffrUserName(nom)}`}
              </p>
              <ul>
                <li>
                  <Link
                    to="/tableau-de-bord"
                    onClick={onCloseMenu}
                  >
                    <i className="icon icon-account is-inline"></i>
                    Espace personnel
                  </Link>
                </li>
                <li>
                  <a
                    href={getCasEndpoint(logout_url, redirection.match('tableau-de-bord') ? '/' : redirection)}
                  >
                    <i className="icon icon-logout is-inline"></i>
                    Se déconnecter
                  </a>
                </li>
              </ul>
            </div>
          )}
          <ul className="menu__link" ref={c => (this._menuLink = c)}>
            {this.renderMenuElement()}
            <MenuElementPlus
              hiddenMenuElements={hiddenMenuElements}
              isMobile={this.state.isMobile}
              id={10}
              isDisplayed={this.isDisplayed}
              menuX={this.state.menuLinkX}
              text="Plus"
            />
          </ul>
          <div className="menu__social">
            {
              liens_rs.fb_lien &&
              <a
                className="link-icon"
                href={liens_rs.fb_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Facebook de ${titleLigue} (nouvel onglet)`}>
                <i className="icon icon-facebook" />
              </a>
            }
            {
              liens_rs.twitter_lien &&
              <a
                className="link-icon"
                href={liens_rs.twitter_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Twitter de ${titleLigue} (nouvel onglet)`}>
                <i className="icon icon-twitter" />
              </a>
            }
            {
              liens_rs.instagram_lien &&
              <a
                className="link-icon"
                href={liens_rs.instagram_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Instagram de ${titleLigue} (nouvel onglet)`}>
                <i className="icon icon-instagram" />
              </a>
            }
            {
              liens_rs.youtube_lien &&
              <a
                className="link-icon"
                href={liens_rs.youtube_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Youtube de ${titleLigue} (nouvel onglet)`}>
                <i className="icon icon-youtube" />
              </a>
            }
          </div>
          <div className="menu__right" ref={c => (this._menuRight = c)}>
            <Link
              to="/trouver-un-club-de-rugby"
              title="Se rendre sur la page de recherche d'un club"
              className="btn btn--primary menu__club"
              onClick={handleTagEvent(
                'recherche geolocalisee',
                'clic trouver un club',
                `${this.props.location.pathname} | ${userPref.profil.id === '' ? '' : getGmtUserData(userPref)}`,
                onCloseMenu
              )}
            >
              <i className="icon icon-place is-inline" />
              <span>Trouver un club</span>
            </Link>
            <Search />
          </div>
          <i className="icon icon-close menu__close js-showMenu" role="button" tabIndex={0} onClick={onCloseMenu} aria-label="Fermer" />
        </nav>
      );
    } else {
      return (
        <nav ref={c => (this._menu = c)}>
          <MenuDashboard onCloseMenu={onCloseMenu} logout_url={logout_url} />
        </nav>
      );
    }
  }
}

export default withRouter(Menu);
