// @flow
import React, { PureComponent } from 'react';
import type { MenuElementType } from 'types/MenuElement';
import { Link } from 'react-router-dom';
import { convertEncodedHTML } from 'utils/convertUtils';
import { dynamicClassName } from 'utils/dynamicClassName';

type Props = {
  footerCategory: MenuElementType,
  footerElements: Array<MenuElementType>
};

type ComponentState = {
  isOpen: boolean
};

class FooterSelect extends PureComponent <Props, ComponentState> {
  static defaultProps = {
    footerElements: []
  };
  state: ComponentState = {
    isOpen: false
  }

  renderFooterSubCategory = (subCategories: Array<Object>): any => {
    return subCategories.map((subCategory, index) => {
      return (
        <li key={index}>
          <Link to={`${subCategory.slug_complet}`}>{convertEncodedHTML(subCategory.title)}</Link>
        </li>
      );
    });
  };

  toggleIsOpen = (e: MouseEvent) => {
    if (window.innerWidth < 1280) {
      e.preventDefault();
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  render() {
    const { footerCategory } = this.props;
    const { isOpen } = this.state;
    const subCategories = footerCategory.items;
    const isSubcategories = subCategories && subCategories.length > 0;
    const classNamesTitle = dynamicClassName('footer__category');
    isSubcategories && classNamesTitle.add('accordion-trigger');
    isOpen && classNamesTitle.add('is-selected');

    const classNamesChildrenList = dynamicClassName('accordion-panel');
    !isOpen && classNamesChildrenList.add('is-hidden');
    return (
      <div className="col col-lg-2">
        { isSubcategories ? (
            <Link
            to={`${footerCategory.slug_complet}`}
            className={classNamesTitle.build()}
            onClick={this.toggleIsOpen}
            >
              {convertEncodedHTML(footerCategory.title)}
            </Link>
        ) : (
            <Link
            to={`${footerCategory.slug_complet}`}
            className={classNamesTitle.build()}
            >
              {convertEncodedHTML(footerCategory.title)}
            </Link>
        ) }
        { isSubcategories ? (
          <ul className={classNamesChildrenList.build()}>
            {this.renderFooterSubCategory(subCategories)}
          </ul>
        ) : ('') }
      </div>
    );
  }
}

export default FooterSelect;
