// @flow

export const DISPLAY_MODAL = 'DISPLAY_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export type DISPLAY_MODAL_ACTION = {
  type: 'DISPLAY_MODAL',
  payload: {
    modalNb: number,
    content?: Object,
    force?: boolean,
  }
};

export type HIDE_MODAL_ACTION = {
  type: 'HIDE_MODAL',
  payload: {
    modalNb: number
  }
};

export type Action =
  | DISPLAY_MODAL_ACTION
  | HIDE_MODAL_ACTION;

export function displayModal(modalNb: number, content?: Object, force?: boolean): DISPLAY_MODAL_ACTION {
  return {
    type: DISPLAY_MODAL,
    payload: { modalNb, content, force }
  };
}

export function hideModal(): HIDE_MODAL_ACTION {
return {
  type: HIDE_MODAL,
  payload: { modalNb: 0 }
};
}
