// @flow

import { takeLatest, all, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import { hitTagsArray } from 'constants/hitTagsConstant';
import type { hitTagsArrayType } from 'constants/hitTagsConstant';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
import ReactPixie from '../tracking/pixie';

import { FETCH_ARTICLE_SUCCESS } from 'actions/articleActions';
import { FETCH_ARTICLES_SUCCESS, FETCH_ARTICLES_CATEGORIES_SUCCESS } from 'actions/newsletterActions';
import { FETCH_ARTICLES_CATEGORY_SUCCESS } from 'actions/newsletterCategoryActions';
import { FETCH_TAG_ARTICLE_SUCCESS } from 'actions/tagArticleActions';
import { FETCH_PAGE_LIST_SUCCESS, FETCH_PAGE_NODE_SUCCESS, FETCH_PAGE_DETAIL_SUCCESS } from 'actions/pageActions';
import { FETCH_CLUBS_SUCCESS } from 'actions/clubsActions';
import { FETCH_CLUB_SUCCESS } from 'actions/clubActions';
import { FETCH_EVENTS_SUCCESS, FETCH_EVENTS_FAILURE } from 'actions/eventsActions';
import { FETCH_EVENT_SUCCESS } from 'actions/eventActions';
import { SEND_CONTACT_FORM_START } from 'actions/formActions';
import { FETCH_FAQ_THEME_SUCCESS } from 'actions/faqActions';
import { FETCH_COMPETITION_SUCCESS } from 'actions/competitionActions';
import { FETCH_JOB_LIST_SUCCESS, FETCH_JOB_DETAIL_SUCCESS } from 'actions/jobActions';
import { FETCH_LOGE_LIST_SUCCESS, FETCH_LOGE_DETAIL_SUCCESS } from 'actions/logeActions';
import { FETCH_SEMINAIRE_SUCCESS } from 'actions/seminaireActions';
import { FETCH_ARTICLES_BILLBOARD_SUCCESS } from 'actions/newsletterActions';
import { FETCH_HOME_YOUTUBE_PLAYLISTS_SUCCESS } from 'actions/ffr-tv/homeYoutubePlaylistsActions';
import { DISPLAY_MODAL } from 'actions/modalActions';
import { getModalName } from 'utils/tagManagerUtils';

export default function() {

  return function* hitTagsSaga(): GeneratorType {
    yield all(hitTagsArray.map(hitTag =>
      takeLatest(hitTag, throwHitVirtualPages)
    ));
  };

  function* throwHitVirtualPages(action: hitTagsArrayType): Saga<void> {
    const getUserPref = (state) => state.userState.userPref;
    try {
      let userPref = yield select(getUserPref);
      const userId = userPref.profil.id ? userPref.profil.id : '';
      const licensee = userPref.licence !== ''
        ? 'yes'
        : 'no';
      const licenseeClub = userPref.club_actuel.nom ? userPref.club_actuel.nom : '';
      const licenseeFunctions = userPref.licenceefunctions ? userPref.licenceefunctions : '';
      const newsletterThematic = Object.values(userPref.newsletter_contenu).join(', ');
      const newsletterSubscriber = newsletterThematic.length > 0
        ? 'yes'
        : 'no';

      const url = window.location.pathname.split('/');

      switch (action.type) {
        case FETCH_ARTICLE_SUCCESS:
          if (action.payload.analytics) {    
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL' : window.location.pathname,
                'virtualPageTitle' : action.payload.article.title,
                'articleThematic': action.payload.article.label.name,
                'userId' : userId,
                'licensee': licensee,
                'licenseeClub': licenseeClub,
                'licenseeFunction' : licenseeFunctions,
                'newsletterSubscriber': newsletterSubscriber,
                'newsletterThematic': newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            ReactPixel.pageView();
            ReactPixie.pageView();
          }
          break;
        case FETCH_ARTICLES_SUCCESS:
          if (window.location.pathname === "/actualites") {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL' : window.location.pathname,
                'virtualPageTitle' : 'Dernières actualités',
                'userId' : userId,
                'licensee': licensee,
                'licenseeClub': licenseeClub,
                'licenseeFunction' : licenseeFunctions,
                'newsletterSubscriber': newsletterSubscriber,
                'newsletterThematic': newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            ReactPixel.pageView();
            ReactPixie.pageView();
          } else if (window.location.pathname === "/") {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL' : window.location.pathname,
                'virtualPageTitle' : 'Homepage',
                'userId' : userId,
                'licensee': licensee,
                'licenseeClub': licenseeClub,
                'licenseeFunction' : licenseeFunctions,
                'newsletterSubscriber': newsletterSubscriber,
                'newsletterThematic': newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            ReactPixel.pageView();
            ReactPixie.pageView();
          }
          break;
        case FETCH_ARTICLES_BILLBOARD_SUCCESS:
          if (window.location.pathname === "/actualites") {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL' : window.location.pathname,
                'virtualPageTitle' : 'Dernières actualités',
                'userId' : userId,
                'licensee': licensee,
                'licenseeClub': licenseeClub,
                'licenseeFunction' : licenseeFunctions,
                'newsletterSubscriber': newsletterSubscriber,
                'newsletterThematic': newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            ReactPixel.pageView();
            ReactPixie.pageView();
          } else if (window.location.pathname === "/") {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL' : window.location.pathname,
                'virtualPageTitle' : 'Homepage',
                'userId' : userId,
                'licensee': licensee,
                'licenseeClub': licenseeClub,
                'licenseeFunction' : licenseeFunctions,
                'newsletterSubscriber': newsletterSubscriber,
                'newsletterThematic': newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            ReactPixel.pageView();
            ReactPixie.pageView();
          }
          break;
        case FETCH_ARTICLES_CATEGORY_SUCCESS:
          if (url[1] === 'actualites') {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL' : window.location.pathname,
                'virtualPageTitle' : `Actualités de la categorie ${action.payload.articles[0].label.name}`,
                'userId' : userId,
                'licensee': licensee,
                'licenseeClub': licenseeClub,
                'licenseeFunction' : licenseeFunctions,
                'newsletterSubscriber': newsletterSubscriber,
                'newsletterThematic': newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            ReactPixel.pageView();
            ReactPixie.pageView();
          }
          break;
        case FETCH_TAG_ARTICLE_SUCCESS:
            if (url[1] === 'tags') {
              TagManager.dataLayer({
                dataLayer: {
                  'event': 'virtualPageview',
                  'virtualPageURL' : window.location.pathname,
                  'virtualPageTitle' : `Actualités du mot clé #${action.payload.tag.name}`,
                  'userId' : userId,
                  'licensee': licensee,
                  'licenseeClub': licenseeClub,
                  'licenseeFunction' : licenseeFunctions,
                  'newsletterSubscriber': newsletterSubscriber,
                  'newsletterThematic': newsletterThematic
                },
                dataLayerName: 'dataLayer'
              });
              ReactPixel.pageView();
              ReactPixie.pageView();
            }
            break;
        case FETCH_PAGE_LIST_SUCCESS:
          if (url[1] === 'histoires-de-rugby' && url.length !== 2) {
            if (url[2] && url[2] === 'resultats-de-recherche') {
              TagManager.dataLayer({
                dataLayer: {
                  'event': 'virtualPageview',
                  'virtualPageURL' : window.location.pathname,
                  'virtualPageTitle' : `Histoires de Rugby - Résultats de recherche`,
                  'userId' : userId,
                  'licensee': licensee,
                  'licenseeClub': licenseeClub,
                  'licenseeFunction' : licenseeFunctions,
                  'newsletterSubscriber': newsletterSubscriber,
                  'newsletterThematic': newsletterThematic
                },
                dataLayerName: 'dataLayer'
              });
              ReactPixel.pageView();
              ReactPixie.pageView();
            }
            break;
          }
          TagManager.dataLayer({
            dataLayer: {
              'event': 'virtualPageview',
              'virtualPageURL' : window.location.pathname,
              'virtualPageTitle' : action.payload.pageList.title,
              'userId' : userId,
              'licensee': licensee,
              'licenseeClub': licenseeClub,
              'licenseeFunction' : licenseeFunctions,
              'newsletterSubscriber': newsletterSubscriber,
              'newsletterThematic': newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          ReactPixel.pageView();
          ReactPixie.pageView();
          break;
        case FETCH_PAGE_NODE_SUCCESS:
          if (url.length === 3) {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL' : window.location.pathname,
                'virtualPageTitle' : action.payload.pageNode.title,
                'userId' : userId,
                'licensee': licensee,
                'licenseeClub': licenseeClub,
                'licenseeFunction' : licenseeFunctions,
                'newsletterSubscriber': newsletterSubscriber,
                'newsletterThematic': newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            ReactPixel.pageView();
            ReactPixie.pageView();
          }
          break;
        case FETCH_PAGE_DETAIL_SUCCESS:
          if (url.length === 4) {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL' : window.location.pathname,
                'virtualPageTitle' : action.payload.pageDetail.title,
                'userId' : userId,
                'licensee': licensee,
                'licenseeClub': licenseeClub,
                'licenseeFunction' : licenseeFunctions,
                'newsletterSubscriber': newsletterSubscriber,
                'newsletterThematic': newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            ReactPixel.pageView();
            ReactPixie.pageView();
          }
          break;
        case FETCH_CLUBS_SUCCESS:
          if (window.location.pathname === '/trouver-un-club-de-rugby/' || window.location.pathname === '/trouver-un-club-de-rugby') {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL' : window.location.pathname,
                'virtualPageTitle' : 'Rechercher un club',
                'userId' : userId,
                'licensee': licensee,
                'licenseeClub': licenseeClub,
                'licenseeFunction' : licenseeFunctions,
                'newsletterSubscriber': newsletterSubscriber,
                'newsletterThematic': newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            ReactPixel.pageView();
            ReactPixie.pageView();
          } else if (window.location.pathname === '/trouver-un-club-de-rugby/resultats-de-recherche/') {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL' : window.location.pathname,
                'virtualPageTitle' : 'Resultat de la recherche de club',
                'userId' : userId,
                'licensee': licensee,
                'licenseeClub': licenseeClub,
                'licenseeFunction' : licenseeFunctions,
                'newsletterSubscriber': newsletterSubscriber,
                'newsletterThematic': newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            ReactPixel.pageView();
            ReactPixie.pageView();
          }
          break;
        case FETCH_CLUB_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              'event': 'eventGA',
              'eventCategory' : 'page club',
              'eventAction' : 'consultation',
              'eventLabel' : action.payload.club.name
            },
            dataLayerName: 'dataLayer'
          });
          TagManager.dataLayer({
            dataLayer: {
              'event': 'virtualPageview',
              'virtualPageURL' : window.location.pathname,
              'virtualPageTitle' : action.payload.club.name,
              'userId' : userId,
              'licensee': licensee,
              'licenseeClub': licenseeClub,
              'licenseeFunction' : licenseeFunctions,
              'newsletterSubscriber': newsletterSubscriber,
              'newsletterThematic': newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          ReactPixel.pageView();
          ReactPixie.pageView();
          break;
        case FETCH_EVENTS_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              'event': 'virtualPageview',
              'virtualPageURL' : window.location.pathname,
              'virtualPageTitle' : 'Liste des événements',
              'userId' : userId,
              'licensee': licensee,
              'licenseeClub': licenseeClub,
              'licenseeFunction' : licenseeFunctions,
              'newsletterSubscriber': newsletterSubscriber,
              'newsletterThematic': newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          ReactPixel.pageView();
          ReactPixie.pageView();
          break;
        case FETCH_EVENTS_FAILURE:
          TagManager.dataLayer({
            dataLayer: {
              'event': 'virtualPageview',
              'virtualPageURL' : window.location.pathname,
              'virtualPageTitle' : 'Liste des événements',
              'userId' : userId,
              'licensee': licensee,
              'licenseeClub': licenseeClub,
              'licenseeFunction' : licenseeFunctions,
              'newsletterSubscriber': newsletterSubscriber,
              'newsletterThematic': newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          ReactPixel.pageView();
          ReactPixie.pageView();
          break;
        case FETCH_EVENT_SUCCESS:
          if (action.payload.analytics) {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL' : window.location.pathname,
                'virtualPageTitle' : `Détail de l'événement ${action.payload.event.title}`,
                'userId' : userId,
                'licensee': licensee,
                'licenseeClub': licenseeClub,
                'licenseeFunction' : licenseeFunctions,
                'newsletterSubscriber': newsletterSubscriber,
                'newsletterThematic': newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            ReactPixel.pageView();
            ReactPixie.pageView();
          }
          break;
        case SEND_CONTACT_FORM_START:
          const { pathname } = window.location;
          const { formInputs } = action.payload;
          const isSeminaireOrLogeForm = formInputs.hasOwnProperty('loge-id') || 
            formInputs.hasOwnProperty('seminaire-id');

          if (!isSeminaireOrLogeForm) {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'eventGA',
                'eventCategory' : 'contact',
                'eventAction' : 'formulaire envoye',
                'eventLabel' : pathname,
              },
              dataLayerName: 'dataLayer'
            });
          }
          break;
        case FETCH_FAQ_THEME_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              'event': 'virtualPageview',
              'virtualPageURL' : window.location.pathname,
              'virtualPageTitle' : url[1] === 'faq' ? 'FAQ' : 'Contact',
              'userId' : userId,
              'licensee': licensee,
              'licenseeClub': licenseeClub,
              'licenseeFunction' : licenseeFunctions,
              'newsletterSubscriber': newsletterSubscriber,
              'newsletterThematic': newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          ReactPixel.pageView();
          ReactPixie.pageView();
          break;
        case FETCH_COMPETITION_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              'event': 'virtualPageview',
              'virtualPageURL' : window.location.pathname,
              'virtualPageTitle' : `Détail de la compétition ${action.payload.competition.division}`,
              'userId' : userId,
              'licensee': licensee,
              'licenseeClub': licenseeClub,
              'licenseeFunction' : licenseeFunctions,
              'newsletterSubscriber': newsletterSubscriber,
              'newsletterThematic': newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          ReactPixel.pageView();
          ReactPixie.pageView();
          break;
        case FETCH_JOB_LIST_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              'event': 'virtualPageview',
              'virtualPageURL' : window.location.pathname,
              'virtualPageTitle' : 'Carrières',
              'userId' : userId,
              'licensee': licensee,
              'licenseeClub': licenseeClub,
              'licenseeFunction' : licenseeFunctions,
              'newsletterSubscriber': newsletterSubscriber,
              'newsletterThematic': newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          ReactPixel.pageView();
          ReactPixie.pageView();
          break;
        case FETCH_JOB_DETAIL_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              'event': 'virtualPageview',
              'virtualPageURL' : window.location.pathname,
              'virtualPageTitle' : `Recrutement ${action.payload.details.title}`,
              'userId' : userId,
              'licensee': licensee,
              'licenseeClub': licenseeClub,
              'licenseeFunction' : licenseeFunctions,
              'newsletterSubscriber': newsletterSubscriber,
              'newsletterThematic': newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          ReactPixel.pageView();
          ReactPixie.pageView();
          break;
        case FETCH_LOGE_LIST_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              'event': 'virtualPageview',
              'virtualPageURL' : window.location.pathname,
              'virtualPageTitle' : `matchs-${url[url.length - 1]}`,
              'userId' : userId,
              'licensee': licensee,
              'licenseeClub': licenseeClub,
              'licenseeFunction' : licenseeFunctions,
              'newsletterSubscriber': newsletterSubscriber,
              'newsletterThematic': newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          ReactPixel.pageView();
          ReactPixie.pageView();
          break;
        case FETCH_LOGE_DETAIL_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              'event': 'virtualPageview',
              'virtualPageURL' : window.location.pathname,
              'virtualPageTitle' : action.payload.details.title,
              'userId' : userId,
              'licensee': licensee,
              'licenseeClub': licenseeClub,
              'licenseeFunction' : licenseeFunctions,
              'newsletterSubscriber': newsletterSubscriber,
              'newsletterThematic': newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          ReactPixel.pageView();
          ReactPixie.pageView();
          break;
        case FETCH_SEMINAIRE_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              'event': 'virtualPageview',
              'virtualPageURL' : window.location.pathname,
              'virtualPageTitle' : url[url.length - 1],
              'userId' : userId,
              'licensee': licensee,
              'licenseeClub': licenseeClub,
              'licenseeFunction' : licenseeFunctions,
              'newsletterSubscriber': newsletterSubscriber,
              'newsletterThematic': newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          ReactPixel.pageView();
          ReactPixie.pageView();
          break;
        case FETCH_HOME_YOUTUBE_PLAYLISTS_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              'event': 'virtualPageview',
              'virtualPageURL' : window.location.pathname,
              'virtualPageTitle' : 'FFR TV Home',
              'userId' : userId,
              'licensee': licensee,
              'licenseeClub': licenseeClub,
              'licenseeFunction' : licenseeFunctions,
              'newsletterSubscriber': newsletterSubscriber,
              'newsletterThematic': newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          ReactPixel.pageView();
          ReactPixie.pageView();
          break;
        case DISPLAY_MODAL:
          const { modalNb } = action.payload;

          if (modalNb === 1 && action.payload.content) {
            const { analyticsData } = action.payload.content;
            
            TagManager.dataLayer({
              dataLayer: {
                'event': 'eventGA',
                'eventCategory' : 'inscription_newsletter',
                'eventAction' : analyticsData,
              },
              dataLayerName: 'dataLayer'
            });
          }

          if (modalNb === 10) {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL' : window.location.pathname+'/popin/partager-votre-histoire-step1',
                'virtualPageTitle' : 'Centenaire FFR - Partager votre histoire 1/2',
                'userId' : userId,
                'licensee': licensee,
                'licenseeClub': licenseeClub,
                'licenseeFunction' : licenseeFunctions,
                'newsletterSubscriber': newsletterSubscriber,
                'newsletterThematic': newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            ReactPixel.pageView();
            ReactPixie.pageView();
            break;
          }

          if (modalNb === 11) {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL' : window.location.pathname+'/popin/partager-votre-histoire-step2',
                'virtualPageTitle' : 'Centenaire FFR - Partager votre histoire 2/2',
                'userId' : userId,
                'licensee': licensee,
                'licenseeClub': licenseeClub,
                'licenseeFunction' : licenseeFunctions,
                'newsletterSubscriber': newsletterSubscriber,
                'newsletterThematic': newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            ReactPixel.pageView();
            ReactPixie.pageView();
            break;
          }

          if (modalNb === 12) {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'eventGA',
                'eventCategory' : 'page centenaire',
                'eventAction' : 'popin partager votre histoire',
                'eventLabel' : 'formulaire soumis'
              },
              dataLayerName: 'dataLayer'
            });
          }

          TagManager.dataLayer({
            dataLayer: {
              'event': 'virtualPageview',
              'virtualPageURL' : window.location.pathname,
              'virtualPageTitle' : getModalName(modalNb),
              'userId' : userId,
              'licensee': licensee,
              'licenseeClub': licenseeClub,
              'licenseeFunction' : licenseeFunctions,
              'newsletterSubscriber': newsletterSubscriber,
              'newsletterThematic': newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          ReactPixel.pageView();
          ReactPixie.pageView();
          break;
        case FETCH_ARTICLES_CATEGORIES_SUCCESS:
          if (window.location.pathname === "/actualites") {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL' : window.location.pathname,
                'virtualPageTitle' : 'Dernières actualités',
                'userId' : userId,
                'licensee': licensee,
                'licenseeClub': licenseeClub,
                'licenseeFunction' : licenseeFunctions,
                'newsletterSubscriber': newsletterSubscriber,
                'newsletterThematic': newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            ReactPixel.pageView();
          } else if (window.location.pathname === "/") {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL' : window.location.pathname,
                'virtualPageTitle' : 'Homepage',
                'userId' : userId,
                'licensee': licensee,
                'licenseeClub': licenseeClub,
                'licenseeFunction' : licenseeFunctions,
                'newsletterSubscriber': newsletterSubscriber,
                'newsletterThematic': newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            ReactPixel.pageView();
          }
          break;
        default:
      }
    } catch (e) {

    }
  }
}
