// @flow
import React, { Component } from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';

import ModalFormLoge from 'containers/entreprise/loge/ModalFormLogeContainer';
import ModalFormSeminaire from 'containers/entreprise/seminaire/ModalFormSeminaireContainer';
import ModalFormCentenaireEmail from 'containers/centenaire/ModalFormCentenaireEmailContainer';
import ModalFormCentenaireDownload from 'containers/centenaire/ModalFormCentenaireDownloadContainer';
import ModalFormCentenaire from 'containers/centenaire/ModalFormCentenaireContainer';
import NewsletterOptinModal from 'containers/footer/NewsletterOptinModalContainer';
import NextRencontreCard from 'components/competitions/NextRencontreCard';
import NewsletterInterestsList from 'containers/newslettermodal/NewsletterInterestsListContainer';
import RadioInput from 'components/fragments/input/RadioInput';
import { getCasEndpoint, getCasRegister } from 'utils/urlUtils';
import { convertEncodedHTML } from 'utils/convertUtils';
import { handleTagEvent, getGmtUserData } from 'utils/tagManagerUtils';
import type { RencontreType } from 'types/Rencontre';
import type { FullUserType, EditProfileValueType } from 'types/User';
import type { RouterProps } from 'types/Router';

import logoCentenaire from 'assets/img/logo/centenaire_histoire.svg';
import logoCentenaireDownload from 'assets/img/centenaire/centenaire-1.jpg';

export type StateProps = {
  userPref: FullUserType,
  push_rencontre?: RencontreType,
  titleLigue: string,
  modalNb?: number,
  content?: Object,
  force?: boolean,
  login_url: string,
  logout_url: string,
};

export type DispatchProps = {
  hideModal: () => void,
  displayModal: (modalNb: number) => void,
  postUserPref: (action: string, id: EditProfileValueType, token: string) => void,
};

type Props = RouterProps & StateProps & DispatchProps;

type State = {
  isOpen: boolean,
  redirect: boolean,
};
class Modal extends Component <Props, State> {
  state: State = {
    isOpen: false,
    redirect: false,
  }

  static defaultProps = {
    modalNb: 0,
    content: {},
    props: false,
  };

  /**
   * Avant l'update, vérifier que l'on puisse faire cet update
   * Une modale ouverte ne peut être remplacée, sauf par la modale 0 ou si on force le changement
   */
  shouldComponentUpdate(nextProps: Props) {
    const { modalNb, force } = nextProps;
    const { isOpen } = this.state;
    if (isOpen && (modalNb !== 0 && !force)) {
      return false;
    }
    return true;
  }

  /**
   * Au changement de numéro de modal,
   * si le numéro est à 0, on indique qu'elle est fermée, sinon ouverte
   */
  componentDidUpdate(prevProps: Props) {
    if (prevProps.modalNb !== this.props.modalNb) {
      if (this.props.modalNb === 0) {
        this.setState({ isOpen: false });
      }
    }
  }

  renderModalNewsletterOptin = () => {
    const { hideModal } = this.props;
    const content = {};

    return (
      <section className="modal visible">
        <div className="container" >
          <div className="modal__overlay"></div>
          <div className="modal-content box newsletter-input ">
            <i
              className="icon icon-close js-closeModal"
              onClick={hideModal}
              role='button'
              tabIndex='0'
            />
            <div className="modal__header">
              <h3 className="ft-h2 mb-2">Newsletter <br /> de la Fédération</h3>
            </div>
            <div className="modal__wrapper">
              <div className="modal__body modal__body--medium mb-3">
                <p className="ft-center mb-0">
                  Vous souhaitez être informé des dernières actualités des équipes de France,
                  mais aussi des grands projets du rugby en France ? Inscrivez-vous à la Newsletter FFR !
                </p>
              </div>
              <NewsletterOptinModal content={content}/>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderModalNewsletterSuccess = () => {
    
    const { userPref, hideModal, content } = this.props;

    if (content) {
      return (
        <section className="modal visible">
          <div className="container" >
            <div className="modal__overlay">
            </div>
            <div className="modal-content box newsletter-valid ">
              <i className="icon icon-close js-closeModal" onClick={hideModal} role='button' tabIndex='0' />
              <div className="modal__wrapper">
                <div className="modal__body modal__body--medium narrow-2">
                  <div className="circle-icon circle-icon--blue"></div>
                  <h3 className="mb-1 mt-2 ft-h2">Inscription validée</h3>
                  <p className="mb-1 ft-center">
                    Nous avons bien pris en compte votre inscription à la newsletter de la Fédération.
                  </p>
                  <p className="mb-5 ft-center">
                    Pour modifier vos centre d’intérêts ou vous désinscrire, créez votre espace perso ou connectez-vous.
                  </p>
                </div>
                <ul className='list list--check mb-5'>
                  <li><span>{`Un espace unique et personnalisé`}</span></li>
                  <li><span>{`La gestion de vos centres d'intérêts`}</span></li>
                  <li><span>{`Pour les licenciés : vos statistiques de jeu rassemblées`}</span></li>
                </ul>
                <a 
                  className='btn btn--primary btn--full js-confirm mb-2'
                  href={getCasRegister('/tableau-de-bord')}>
                  Créer mon espace perso (1 min)
                </a>
                <p className='modal__footer-option'>
                  {`J'ai déjà un compte ! `}
                  <a
                    className='footer-option__link'
                    href={getCasEndpoint(content && content.login_url ? content.login_url : 'https://api.www.ffr.fr/keycloak_login', '/tableau-de-bord')}
                    onClick={handleTagEvent( 
                      'connexion',
                      'Mail',
                      `pop-in inscription newsletter${userPref.identifie === false ? '' : ` | ${getGmtUserData(userPref)}`}`
                    )}>
                    Me connecter
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      );
    }
    return null;
  }

  renderModalNewsletterFailed = (wording: ?string) => {
    const text = wording || 'Nous avons rencontré un problème lors de l\'inscription';
    return (
      <section className="modal visible">
        <div className="container" >
          <div className="modal__overlay">
          </div>
          <div className="modal-content box newsletter-unsubscribe-done modal-content--stripe">
            <i className="icon icon-close js-closeModal" onClick={this.props.hideModal} role='button' tabIndex='0'></i>
            <div className="modal__wrapper">
              <h3 className="mb-2">{text}</h3>
              <div className="modal__body modal__body--medium narrow-2 mb-3">
                <div className="circle-icon circle-icon--alert"></div>
                <p>Merci de vérifier les informations envoyées !</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderModalNewsletterMissingOpt = () => {
    const { titleLigue, content } = this.props;
    return (
      <section className="modal visible">
        <div className="container" >
          <div className="modal__overlay">
          </div>
          <div className="modal-content box newsletter-unsubscribe-done modal-content--stripe">
            <i className="icon icon-close js-closeModal" onClick={this.props.hideModal} role='button' tabIndex='0'></i>
            <div className="modal__wrapper">
              <h3 className="mb-2">
              {content && content.text}
              {(!content || !content.text) && 
                `Pour vous inscrire, merci d'accepter les conditions de la ${titleLigue}`}
              </h3>
              <div className="modal__body modal__body--medium narrow-2 mb-3">
                <div className="circle-icon circle-icon--alert"></div>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderModalContactFormSuccess = () => {
    const { content } = this.props;
    return (
      <section className="modal visible">
        <div className="container" >
          <div className="modal__overlay">
          </div>
          <div className="modal-content box keep-in-touch-done modal-content--stripe">
            <i className="icon icon-close js-closeModal" onClick={this.props.hideModal} role='button' tabIndex='0'></i>
            <div className="modal__wrapper">
              <h3 className="mb-2">Votre demande a bien été envoyée</h3>
              <div className="modal__body modal__body--medium narrow-2">
                <div className="circle-icon circle-icon--check"></div>
                <p>Merci !</p>
                { content && content.redirection && 
                  <Redirect to={ content.redirection } />
                }
              </div>
              
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderFormSuccessSimple = () => {
    const { content } = this.props;
    return (
      <section className="modal visible">
        <div className="container" >
          <div className="modal__overlay">
          </div>
          <div className="modal-content box keep-in-touch-done modal-content--stripe">
            <i className="icon icon-close js-closeModal" onClick={this.props.hideModal} role='button' tabIndex='0'></i>
            <div className="modal__wrapper">
              <h3 className="mb-2">{content && content.title || `Votre demande a bien été envoyée`}</h3>
              <div className="modal__body modal__body--medium narrow-2">
                <div className="circle-icon circle-icon--check"></div>
                <p>{content && content.text || `Merci!`}</p>
                { content && content.redirection && 
                  <Redirect to={ content.redirection } />
                }
              </div>
              
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderModalContactFormFailed = () => {
    const { content } = this.props;
    return (
      <section className="modal visible">
        <div className="container" >
          <div className="modal__overlay">
          </div>
          <div className="modal-content box newsletter-unsubscribe-done modal-content--stripe">
            <i className="icon icon-close js-closeModal" onClick={this.props.hideModal} role='button' tabIndex='0'></i>
            <div className="modal__wrapper">
              <h3 className="mb-2">
                {(content && content.title) || "Nous avons rencontré un problème lors de l'envoi de votre message"}
              </h3>
              <div className="modal__body modal__body--medium narrow-2 mb-3">
                <div className="circle-icon circle-icon--alert"></div>
                <p>{(content && content.text) || `Merci de vérifier les informations envoyées!`}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderModalLogeForm = () => {
    const { hideModal, content } = this.props;

    return (
      <section className="modal visible">
        <div className="container" >
          <div className="modal__overlay"></div>
          <div className="modal-content box keep-in-touch">
            <i
              className="icon icon-close js-closeModal"
              onClick={hideModal}
              role='button'
              tabIndex={0}>
            </i>
            <div className="modal__wrapper">
              <h3 className="mb-2">{"Être recontacté pour obtenir une proposition"}</h3>
              <div className="modal__body modal__body--medium mb-4">
                <p className="mb-0">
                  Il ne vous reste plus qu’une étape !
                  Remplissez ce formulaire pour que l’un de nos commerciaux puisse vous recontacter
                  rapidement afin d’affiner votre demande et vous faire une proposition.
                </p>
              </div>
              {content && <ModalFormLoge content={content} />}
              {!content && <p>Erreur lors de la propagation des données, renvoyez le formulaire.</p>}
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderModalSeminaireForm = () => {
    const { hideModal, content } = this.props;

    return (
      <section className="modal visible">
        <div className="container" >
          <div className="modal__overlay"></div>
          <div className="modal-content box keep-in-touch">
            <i
              className="icon icon-close js-closeModal"
              onClick={hideModal}
              role='button'
              tabIndex={0}>
            </i>
            <div className="modal__wrapper">
              <h3 className="mb-2">{"Être recontacté pour obtenir une proposition"}</h3>
              <div className="modal__body modal__body--medium mb-4">
                <p className="mb-0">
                  Il ne vous reste plus qu’une étape !
                  Remplissez ce formulaire pour que l’un de nos commerciaux puisse vous recontacter
                  rapidement afin d’affiner votre demande et vous faire une proposition.
                </p>
              </div>
              {content && <ModalFormSeminaire content={content} />}
              {!content && <p>Erreur lors de la propagation des données, renvoyez le formulaire.</p>}
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderModalLogeSuccess = () => {
    const { hideModal } = this.props;

    return (
      <section className="modal visible">
        <div className="container" >
          <div className="modal__overlay"></div>
          <div className="modal-content box keep-in-touch-done modal-content--stripe">
            <i
              className="icon icon-close js-closeModal"
              onClick={hideModal}
              role='button'
              tabIndex={0}>
            </i>
            <div className="modal__wrapper">
              <h3 className="mb-4">Demande de proposition envoyée</h3>
              <div className="modal__body modal__body--medium narrow-2">
                <div className="circle-icon circle-icon--check"></div>
                <span>
                  Votre demande a bien été envoyée. Vous serez recontacté dès que possible par l’un de nos commerciaux.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderModalCentenaireFormEmail = () => {
    const { hideModal } = this.props;

    return (
      <section className="modal visible">
        <div className="container" >
          <div className="modal__overlay"></div>
          <div className="modal-content box modal-centenaire modal-centenaire--with-bg modal-centenaire--intro ">
            <i
              className="icon icon-close js-closeModal"
              onClick={hideModal}
              role='button'
              tabIndex={0}>
            </i>
            <div className="modal__header">
              <img src={logoCentenaire} alt="Centenaire FFR" />
              <h3 className="ft-h2 mb-1">{"Partager votre histoire"}</h3>
              <p>
                Bénévoles, joueurs, entraîneurs…<br />
                Passionnés, partagez vos souvenirs et ensemble, retraçons l’histoire du rugby.
              </p>
              <a href="https://youtu.be/mnHnODPOuaM" target="_blank" rel="noopener noreferrer">
                Comment faire ?
              </a>
            </div>
            <div className="modal__wrapper">
              <ModalFormCentenaireEmail />
            </div>
          </div>
        </div>
      </section>
    );
  }

renderModalTicketing = () => {
    const { hideModal, userPref, location: { pathname }, login_url } = this.props;

    return (
      <section className="modal visible">
        <div className="container" >
          <div className="modal__overlay"></div>
            <div className="modal-content box ticketing-signup modal-content--stripe">
              <i className="icon icon-close js-closeModal" onClick={hideModal} role='button' tabIndex='0'></i>
              <div className="modal__wrapper">
                <h3 className="mb-4">Alerte billetterie</h3>
                <div className="modal__body modal__body--medium narrow-2 mb-2">
                  <div className="circle-icon circle-icon--check"></div>
                  <p className="mb-1">
                    Entrez dans la mêlée, profitez-en pour créer votre <strong>espace perso FFR.</strong>
                  </p>
                  <p className="mb-0">Il ne vous manque plus qu’un mot de passe</p>
                </div>
                <ul className="list list--check mb-3">
                  <li><span>La gestion de vos alertes billetteries</span></li>
                  <li><span>La gestion de vos centres d’intérêts</span></li>
                  <li><span>Pour les licenciés : vos statistiques de jeu rassemblées</span></li>
                </ul>
                <a 
                  className="btn btn--primary btn--full js-confirm mb-2"
                  href={getCasRegister('/tableau-de-bord')}>
                  Créer mon espace perso (1 min)
                </a>
                <p className="modal__footer-option">
                  {`J'ai déjà un compte ! `}
                  <a
                    className="footer-option__link"
                    href={getCasEndpoint(login_url ? login_url : 'https://api.www.ffr.fr/keycloak_login', pathname)}
                    onClick={handleTagEvent(
                      'connexion',
                      'Mail',
                      `pop-in alerte billetterie${userPref.identifie === false ? '' : `| ${getGmtUserData(userPref)}`}`
                    )}>
                    Me connecter
                  </a>
                </p>
                </div>
              </div>
          </div>
      </section>
    );
  }

  renderModalCentenaireFormUpload = () => {
    const { hideModal, content } = this.props;
    return (
      <section className="modal visible">
        <div className="container" >
          <div className="modal__overlay"></div>
          <div className="modal-content box modal-centenaire modal-centenaire--form">
            <i
              className="icon icon-close js-closeModal"
              onClick={hideModal}
              role='button'
              tabIndex={0}>
            </i>
            <div className="modal__header">
              <h3 className="ft-400 mb-1">{"Partager votre histoire"}</h3>
            </div>
            <div className="modal__wrapper">
              <ModalFormCentenaire content={content} />
            </div>
          </div>
        </div>
      </section>
    );
  }

  showModalCentenaire = (e: MouseEvent) => {
    const { displayModal } = this.props;
    
    e.preventDefault();
    displayModal(15);
  }

  renderModalCentenaireFormOk = () => {
    const { hideModal } = this.props;
    return (
      <section className="modal visible">
        <div className="container" >
          <div className="modal__overlay"></div>
          <div className="modal-content box modal-centenaire modal-centenaire--with-bg modal-centenaire--end ">
            <i
              className="icon icon-close js-closeModal"
              onClick={hideModal}
              role='button'
              tabIndex={0}>
            </i>
            <div className="modal__header">
              <img src={logoCentenaire} alt="Centenaire FFR" />
              <h3 className="ft-h2 mb-1">{"Merci !"}</h3>
              <p>
                Votre souvenir a été envoyé avec succès. 
                Vous serez averti par email lors de la publication de celui-ci.
              </p>
              <button className="btn btn--primary btn--white btn--full" onClick={hideModal}>
                Retour au site
              </button>
              <button className="btn btn--primary btn--gold btn--full" onClick={this.showModalCentenaire}>
                Partager un souvenir
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderModalCentenaireFormError = () => {
    const { hideModal } = this.props;

    return (
      <section className="modal visible">
        <div className="container" >
          <div className="modal__overlay"></div>
          <div className="modal-content box modal-centenaire modal-centenaire--with-bg modal-centenaire--end ">
            <i
              className="icon icon-close js-closeModal"
              onClick={hideModal}
              role='button'
              tabIndex={0}>
            </i>
            <div className="modal__header">
              <img src={logoCentenaire} alt="Centenaire FFR" />
              <h3 className="ft-h2 mb-1">{"Oups !"}</h3>
              <p>
                {`Votre fichier n'a pas pu être envoyé.`}
                <br />
                {`Veuillez réessayer ultérieurement (la limite est de 50 Mo).`}
              </p>
              <button className="btn btn--primary btn--white btn--full" onClick={hideModal}>
                Retour au site
              </button>
              <button className="btn btn--primary btn--gold btn--full" onClick={this.showModalCentenaire}>
                Partager un souvenir
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderPushRencontre = () => {
    const { push_rencontre } = this.props;

    if (push_rencontre) {
      const { meta: rencontre, meta: { equipeNom }, competition: { name: competitionTitle} } = push_rencontre;

      return (
        <div className="modal__wrapper modal__wrapper--footer">
          <p className="ft-black ft-500 mb-2">Ça peut vous intéresser…</p>
          <NextRencontreCard
            isSmall
            rencontre={rencontre}
            competitionTitle={competitionTitle}
            equipeNom={equipeNom} />
        </div>
      );
    }

    return null;
  }

  renderModalTicketingSuccess = () => {
    const { hideModal, userPref: { profil: { prenom } } } = this.props;

    return (
      <section className="modal visible">
        <div className="container" >
          <div className="modal__overlay"></div>
          <div className="modal-content box ticketing-signup-validation modal-content--stripe">
              <i className="icon icon-close js-closeModal" onClick={hideModal} role='button' tabIndex='0'/>
              <div className="modal__wrapper">
                <h3 className="mb-4">C’est noté {prenom} !</h3>
                <div className="modal__body modal__body--medium narrow-2 mb-4">
                  <div className="circle-icon circle-icon--check"></div>
                    <p className="mb-0">
                      L’alerte a été ajoutée à votre espace perso FFR. N’hésitez pas à vous y rendre pour gérer toutes vos alertes.
                    </p>
                  </div>
                  <Link
                    className="btn btn--primary btn--full js-confirm mb-1"
                    onClick={hideModal}
                    to={'/tableau-de-bord/mes-alertes-billetterie'}>
                    Accéder à mon espace perso FFR
                  </Link>
                </div>
                {this.renderPushRencontre()}
              </div>
          </div>
      </section>
    );
  }

  renderModalCentenaireDownload = () => {
    const { hideModal, content } = this.props;

    return (
      <section className="modal visible">
        <div className="container">
          <div className="modal__overlay"></div>
          <div className="modal-content box modal-centenaire modal-centenaire--download">
            <i
              className="icon icon-close js-closeModal"
              onClick={hideModal}
              role='button'
              tabIndex={0}>
            </i>
            <div className="modal__header">
              <img src={logoCentenaireDownload} alt="" />
              <p>
                Vous êtes sur le point de télécharger une image <br />
                soumise à des droits d’auteurs, veuillez confirmez le respect de ses droits.
              </p>
            </div>
            <div className="modal__wrapper">
              <ModalFormCentenaireDownload {...content} />
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderAddFavoritePlayerModal = () => {
    const { userPref, hideModal, content, login_url } = this.props;
    if (content) {
      return (
        <section className='modal visible'>
          <div className='container'>
            <div className='modal__overlay'></div>
            <div className='modal-content box add-favorite-player modal-content--stripe'>
              <i
              className="icon icon-close js-closeModal"
              onClick={hideModal}
              role='button'
              tabIndex='0'
              />
              <div className='modal__wrapper'>
                <h3 className="mb-4">Ajout d’un joueur à vos favoris</h3>
                <div className='modal__body modal__body--medium narrow-2 mb-5'>
                  <div className="circle-icon circle-icon--star" />
                  <p className="mb-0">
                    {"Pour suivre l’actualité de "}
                    <strong>{content.title}</strong>
                    {" et de centaines d’autres joueurs, créez votre espace perso FFR ou connectez-vous."}
                  </p>
                </div>
                <ul className='list list--check mb-3'>
                  <li><span>Un espace unique et personnalisé</span></li>
                  <li><span>La gestion de vos centres d’intérêts</span></li>
                  <li><span>Pour les licenciés : vos statistiques de jeu rassemblées</span></li>
                </ul>
                <a
                  className="btn btn--primary btn--full js-confirm mb-2"
                  href={getCasRegister('/tableau-de-bord')}
                >
                  Créer mon espace perso (1 min)
                </a>
                <p className="modal__footer-option">
                  {"J'ai déjà un compte !"}
                  <a
                    className="footer-option__link"
                    href={getCasEndpoint(login_url ? login_url : 'https://api.www.ffr.fr/keycloak_login', content.redirection)}
                    onClick={handleTagEvent(
                      'connexion',
                      'Mail',
                      `pop-in ajout joueur favoris${userPref.identifie === false ? '' : `| ${getGmtUserData(userPref)}`}`
                    )}
                    role='button'
                    tabIndex='0'
                  >
                    Me connecter
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      );
    }
    return null;
  }

  renderRemoveFavoriteModal = () => {
    const { hideModal, postUserPref, content } = this.props;
    if (content) {
      return (
        <section className='modal visible'>
          <div className='container'>
            <div className='modal__overlay'></div>
            <div className='modal-content box remove-favorite-player modal-content--stripe'>
              <i
                className="icon icon-close js-closeModal"
                onClick={hideModal}
                role='button'
                tabIndex='0'
              />
              <div className="modal__wrapper">
                <h3 className="mb-4">Êtes-vous sûr de vous ?</h3>
                <div className="modal__body modal__body--medium narrow-2 mb-3">
                  <p className="mb-0">
                    {"Voulez-vous vraiment retirer "}
                    <strong>{content.title}</strong>
                    {" de la liste de vos joueur favoris ?"}
                  </p>
                </div>
                <a
                  className="btn btn--primary btn--full js-confirm mb-2"
                  onClick={() => {
                    postUserPref('joueurs-favoris-del', content.id, this.props.userPref.token);
                    hideModal();
                    }
                  }
                  role='button'
                  tabIndex='0'
                >
                  Retirer
                </a>
                <p className="modal__footer-option">
                  <a
                    onClick={hideModal}
                    role='button'
                    tabIndex='0'
                  >
                    Le garder parmi mes joueurs favoris
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      );
    }
    return null;
  }

  renderCustomizeNewsletterModal = () => {
    const { hideModal, content } = this.props;

    return (
        <section className='modal visible'>
          <div className='container'>
            <div className='modal__overlay' />
            <div className='modal-content box newsletter-subscription modal-content--stripe'>
              <i
                className="icon icon-close js-closeModal"
                onClick={hideModal}
                role='button'
                tabIndex='0'
              />
              <div className='modal__wrapper'>
                <h3 className="mb-2">Inscription newsletter</h3>
                <div className="modal__body mb-3">
                  <p>{"Recevez une newsletter encore plus personnalisée, en choisissant vos centres d'intérêts : "}</p>
                </div>
                <NewsletterInterestsList content={content} />
              </div>
            </div>
          </div>
        </section>
      );
  }

  sendUnsubscription = () => {
    const { displayModal, postUserPref } = this.props;
    const allInput = [...document.getElementsByTagName('input')];
    allInput.forEach(element => {
      element.checked = false;
    });
    postUserPref('change-newsletter', [], this.props.userPref.token);
    displayModal(22);
  }

  renderUnsubscribeNewsletterModal = () => {
    const { userPref, hideModal } = this.props;

    return (
      <section className='modal visible'>
        <div className='container'>
          <div className='modal__overlay' />
          <div className='modal-content box newsletter-unsubscribe modal-content--stripe'>
            <i
              className='icon icon-close js-closeModal'
              onClick={hideModal}
              role='button'
              tabIndex='0'
            />
            <div className='modal__wrapper'>
              <h3 className='mb-4'>Désinscription de la newsletter</h3>
              <div className='modal__body modal__body--medium narrow-2 mb-5'>
                <div className='circle-icon circle-icon--check' />
                <p className='mb-0'>Vous avez bien été <strong>désinscrit</strong> de la newsletter de la FFR.</p>
              </div>
              <p className='mb-2 ft-black-secondary'>En 2 clics, dites-nous pourquoi :</p>
              <RadioInput id='pivDvtQSZVaM' label='Le contenu ne m’intéresse pas'/>
              <RadioInput id='hJuETJFIioUI' label="J'en reçois trop"/>
              <RadioInput id='TowUtwMAVRzv' label="Je ne m'intéresse plus au rugby"/>
              <RadioInput id='yCvZnbmcQAyB' classNames='radio mb-3' label='Autre'/>
              <a
                className='btn btn--primary btn--full js-confirm mb-1'
                onClick={handleTagEvent(
                  'espace perso',
                  'desinscription_newsletter',
                  userPref.identifie === false ? '' : getGmtUserData(userPref),
                  this.sendUnsubscription)}
                role='button'
                tabIndex='0'
              >
                Envoyer
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderUnsubscribeNewsletterDoneModal = () => {
    const { hideModal } = this.props;

    return (
      <section className='modal visible'>
        <div className='container'>
          <div className='modal__overlay' />
          <div className='modal-content box newsletter-unsubscribe-done modal-content--stripe'>
            <i
              className='icon icon-close js-closeModal'
              onClick={hideModal}
              role='button'
              tabIndex='0'
            />
            <div className='modal__wrapper'>
              <h3 className='mb-4'>Désinscription de la newsletter</h3>
              <div className='modal__body modal__body--medium narrow-2 mb-5'>
                <div className='circle-icon circle-icon--check' />
                <span>Merci ! Et peut-être à bientôt !</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderModalTicketingRemove = () => {
    const { hideModal, postUserPref, content, displayModal } = this.props;

    if (content) {
      return (
        <section className='modal visible'>
          <div className='container'>
            <div className='modal__overlay'></div>
            <div className='modal-content box remove-favorite-player modal-content--stripe'>
              <i
                className="icon icon-close js-closeModal"
                onClick={hideModal}
                role='button'
                tabIndex='0'
              />
              <div className="modal__wrapper">
                <h3 className="mb-4">Êtes-vous sûr de vous ?</h3>
                <div className="modal__body modal__body--medium narrow-2 mb-3">
                  <p className="mb-0">
                    {"Voulez-vous vraiment retirer "}
                    <strong>{convertEncodedHTML(content.title)}</strong>
                    {" de la liste de vos alertes billetterie ?"}
                  </p>
                </div>
                <a
                  className="btn btn--primary btn--full js-confirm mb-2"
                  onClick={() => {
                    postUserPref('billetterie-alerte-del', content.id, this.props.userPref.token);
                    displayModal(24);
                    }
                  }
                  role='button'
                  tabIndex='0'
                >
                  Retirer
                </a>
                <p className="modal__footer-option">
                  <a
                    onClick={hideModal}
                    role='button'
                    tabIndex='0'
                  >
                    Garder cette alerte billetterie
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      );
    }
    return null;
  }

  renderModalTicketingRemoveSuccess = () => {
    const { hideModal } = this.props;

    return (
      <section className='modal visible'>
        <div className='container'>
          <div className='modal__overlay' />
          <div className='modal-content box newsletter-unsubscribe-done modal-content--stripe'>
            <i
              className='icon icon-close js-closeModal'
              onClick={hideModal}
              role='button'
              tabIndex='0'
            />
            <div className='modal__wrapper'>
              <h3 className='mb-4'>Alerte supprimée</h3>
              <div className='modal__body modal__body--medium narrow-2 mb-5'>
                <div className='circle-icon circle-icon--check' />
                <span>Merci ! Et peut-être à bientôt!</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  renderModalSuccess = () => {
    const { hideModal, content } = this.props;

    return (
      <section className='modal visible'>
        <div className='container'>
          <div className='modal__overlay' />
          <div className='modal-content box newsletter-valid '>
            <i
              className='icon icon-close js-closeModal'
              onClick={hideModal}
              role='button'
              tabIndex='0'
            />
            <div className='modal__wrapper'>
              <div className='modal__body modal__body--medium narrow-2'>
                <div className='circle-icon circle-icon--check' />
                <h3 className='mb-1 mt-2 ft-h2'>{content && content.title ? content.title : 'Demande enregistrée'}</h3>
                <span>{content && content.text ? content.text : 'Mise à jour réussie'}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  render(){
    const { modalNb } = this.props;
    switch (modalNb) {
      case 0:
        return null;
      case 1:
        return this.renderModalNewsletterSuccess();
      case 2:
        return this.renderModalNewsletterFailed();
      case 21:
        const wording = `Vous êtes déjà inscrit mais vous n’avez pas validé votre adresse. 
        Nous vous invitons à consulter vos emails.`;
        return this.renderModalNewsletterFailed(wording);
      case 3:
        return this.renderModalNewsletterMissingOpt();
      case 4:
        return this.renderModalContactFormSuccess();
      case 5:
        return this.renderModalContactFormFailed();
      case 6:
        return this.renderModalLogeForm();
      case 7:
        return this.renderModalLogeSuccess();
      case 8:
        return this.renderModalNewsletterOptin();
      case 9:
        return this.renderModalSeminaireForm();
      case 10:
        return this.renderModalCentenaireFormEmail();
      case 11:
        return this.renderModalCentenaireFormUpload();
      case 12:
        return this.renderModalCentenaireFormOk();
      case 13:
        return this.renderModalCentenaireFormError();
      case 14:
          return this.renderModalCentenaireDownload();
      case 15:
        return this.renderModalTicketing();
      case 16:
        return this.renderModalTicketingSuccess();
      case 17:
        return this.renderAddFavoritePlayerModal();
      case 18:
        return this.renderRemoveFavoriteModal();
      case 19:
        return this.renderCustomizeNewsletterModal();
      case 20:
        return this.renderUnsubscribeNewsletterModal();
      case 22:
        return this.renderUnsubscribeNewsletterDoneModal();
      case 23:
        return this.renderModalTicketingRemove();
      case 24:
        return this.renderModalTicketingRemoveSuccess();
      case 30:
        return this.renderModalSuccess();
      case 25:
        return this.renderFormSuccessSimple();
      default:
        return null;
    }
  }
}

export default withRouter(Modal);
