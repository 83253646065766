// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { FETCH_TEAMS_START, teamListFetched, teamListFetchError } from 'actions/teamlistActions';
import TeamsApi from 'api/teams/TeamsApi';
import type { FETCH_TEAMS_START_ACTION } from 'actions/teamlistActions';

export default function(apiExecutor: ApiExecutorType) {
  const teamsApi = new TeamsApi(apiExecutor);

  return function* teamlistSaga(): GeneratorType {
    yield takeLatest(FETCH_TEAMS_START, fetchTeamList);
  };

  function* fetchTeamList(action: FETCH_TEAMS_START_ACTION): Saga<void> {
    try {
      const teamlist = yield call(teamsApi.fetchTeamList);
      yield put(teamListFetched(teamlist));

    } catch (e) {
      console.log(e);
      yield put(teamListFetchError());
    }
  }
}
