/* eslint-disable no-undef */
// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import centenaireFormApi from 'api/centenaire/centenaireFormApi';
import { displayModal } from 'actions/modalActions';
import {
  SEND_CENTENAIRE_FORM_START,
  centenaireFormPosted,
  centenaireFormPostError,
  type SEND_CENTENAIRE_FORM_START_ACTION
} from 'actions/centenaireFormActions';

export default function(apiExecutor: ApiExecutorType) {
  const formApi = new centenaireFormApi(apiExecutor);

  return function* formSaga(): GeneratorType {
    yield takeLatest(SEND_CENTENAIRE_FORM_START, sendForm);
  };

  function* sendForm(action: SEND_CENTENAIRE_FORM_START_ACTION): Saga<void> {
    try {
      const { formInputs } = action.payload;
      const data = new FormData();

      for (const key in formInputs) {
        if (formInputs.hasOwnProperty(key)) {
          data.append(key, formInputs[key]);
        }
      };

      yield call(formApi.postFormCentenaire, data);
      yield put(displayModal(12, {}, true));
      yield put(centenaireFormPosted());
    } catch (e) {
      yield put(displayModal(13, {}, true));
      yield put(centenaireFormPostError());
    }
  }
}
