// @flow
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import MenuDashboard from 'components/header/MenuDashboard';
import type { StateProps } from 'components/header/MenuDashboard';
import type { StateType } from 'types/Actions';

export default withRouter(connect(
  (state: StateType): StateProps => ({
    userPref: state.userState.userPref,
    menuElements: state.menuState.quickAccess,
  }),
)(MenuDashboard));
