// @flow
import React, { PureComponent, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Search from 'components/header/Search';
import { dynamicClassName } from 'utils/dynamicClassName';
import { isEspacePerso } from 'constants/menuConstants';
import type { RouterProps } from 'types/Router';

type StateProps = {
  logo: string,
  logoOver: string
};

type Props = {
  onToggleMenu: Function,
  isMenuOpen: boolean,
  headerOver: boolean,
  onCloseMenu: Function,
  setOverlay: Function,
  hasOverlay: boolean,
} & StateProps & RouterProps;

class HeaderMobile extends PureComponent<Props> {

  handleToogleMenu = () => {
    const { onToggleMenu, setOverlay } = this.props;

    onToggleMenu();
    setOverlay(false);
  }

  render() {
    const {
      onCloseMenu,
      isMenuOpen,
      headerOver,
      logo,
      logoOver,
      location: { pathname },
      setOverlay,
      hasOverlay,
    } = this.props;
    const classNamesIconShowMenu= dynamicClassName('icon icon-menu js-showMenu');
    const classNamesOverlay = dynamicClassName('overlay');
    isMenuOpen && classNamesIconShowMenu.add('is-open');
    hasOverlay && classNamesOverlay.add('is-visible');

    if (isEspacePerso(pathname)) {
      return (
        <Fragment>
          <div className="header__mobile">
            <i className={classNamesIconShowMenu.build()} role="button" tabIndex={0} onClick={this.handleToogleMenu} />
            <Link title="Se rendre sur la page d'accueil" to="/tableau-de-bord">
              <img className="logo-blue" src={logo} alt="logo FFR" />
              <h1 className="ft-up ft-h5">ESPACE PERSO</h1>
            </Link>
          </div>
          <div
            className={classNamesOverlay.build()}
            onClick={this.handleToogleMenu}
            role='button'
            tabIndex={0}
            aria-label={'Afficher le menu'} />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="header__mobile">
            <i
              className={classNamesIconShowMenu.build()}
              role="button"
              tabIndex={0}
              onClick={this.props.onToggleMenu} />
            <Search mobile={true} onSetOverlay={setOverlay} onCloseMenu={onCloseMenu}/>
            <Link title="Se rendre sur la page d'accueil" to="/" onClick={onCloseMenu}>
              <img src={headerOver ? logoOver : logo} alt="" />
            </Link>
          </div>
          <div
            className={classNamesOverlay.build()}
            onClick={onCloseMenu}
            role='button'
            tabIndex={0}
            aria-label={'Fermer ce menu'} />
        </Fragment>
      );
    }
  }
}

export default HeaderMobile;
