// @flow
import React, { PureComponent, Fragment } from 'react';
import CryptoJS from 'crypto-js';
import ReCAPTCHA from "react-google-recaptcha";
import type { ClubPreviewType } from 'types/Club';
import type { Status } from 'types/Status';
import SelectInput from 'components/fragments/input/SelectInput';
import TextInput from 'components/fragments/input/material/TextInputMaterial';
import TextareaInput from 'components/fragments/input/material/TextareaInputMaterial';
import DropdownClubInput from 'components/fragments/input/material/DropdownClubInputMaterial';
import CheckboxInput from 'components/fragments/input/CheckboxInput';
import { STATUS_FAILURE } from 'constants/statusConstants';
import { FORM_CRYPTO_KEY, FORM_CRYPTO_IV } from 'constants/api';

import { getFileNameFromPath } from 'utils/urlUtils';
import { validateEmail } from 'utils/validatorUtils';
import { getCentenaireHash } from 'utils/centenaireUtils';
import Loading from 'components/fragments/Loading';

const KEY_RECAPTCHA = "6Lc5rq0UAAAAAPLMIJ33H4H6llGAULo6-pQ_Ga9Q";

export type StateProps = {
  clubs: Array<ClubPreviewType>,
  sendStatus: Status,
};

export type DispatchProps = {
  fetchClubsPreview: () => void,
  onSubmit: (values: Object) => void,
  displayModal: (modalNb: number, content: Object, force: boolean) => void,
};

type Props = {
  content?: Object,
} & StateProps & DispatchProps;

type State = {
  checked: boolean,
  name: string | null,
  title: string | null,
  email: string | null,
  file: string,
  description: string | null,
  club: string | null,
  year: string | null,
  copyright: string | null,
  team: string | null,
  level: string | null,
  instagram: string | null,
  facebook: string | null,
  twitter: string | null,
  formSent: boolean,
  captchaload: boolean,
  captchaOk: boolean | string,
  showEmailError: boolean,
  showFileError: boolean,
  fileError: string | null
};

class ModalFormCentenaire extends PureComponent<Props, State> {
  state: State = {
    checked: false,
    name: null,
    title: null,
    email: null,
    file: '',
    description: null,
    club: null,
    year: null,
    copyright: null,
    team: null,
    level: null,
    instagram: null,
    facebook: null,
    twitter: null,
    formSent: false,
    showEmailError: false,
    showFileError: false,
    fileError: '',
    captchaload: false,
    captchaOk: false
  };

  _reCaptchaRef = React.createRef();

  static defaultProps = {
    content: {},
  };

  componentDidMount = () => {
    const { content } = this.props;
    if (content && content.email) {
      this.setState({email : content.email});
    }
    if (!this.props.clubs || this.props.clubs.length < 10) {
      this.props.fetchClubsPreview();
    }
    setTimeout(() => {
      this.setState({ captchaload: true });
    }, 500);
  }

  _handleChange = (name: string, value: string) => {
    this.setState({ [name]: value });
  }

  _handleCaptchaChange = (value: string | null) => {
    if (value === null) this.setState({ captchaOk: false });
    else this.setState({ captchaOk: value });
  };

  _asyncCaptchaScriptOnLoad = () => {
    this.setState({ captchaload: true });
  };

  isFormValid = () => {
    const {
      name,
      email,
      title,
      description,
      copyright,
      team,
      level,
      club,
      year,
      facebook, 
      twitter,
      instagram,
      file,
      checked,
      captchaOk
    } = this.state;

    return (
      captchaOk && 
      name !== null && name !== '' &&
      email !== null && email !== '' &&
      title !== null && title !== '' &&
      description !== null && description !== '' &&
      copyright !== null && copyright !== '' &&
      team !== null && team !== '' &&
      level !== null && level !== '' &&
      club !== null && club !== '' &&
      this.isClubValid(club) && 
      year !== null && year !== '' &&
      this.isYearValid(year) &&
      file !== null && file !== '' &&
      this.isFileValid() && 
      this.isSocialValid(facebook) &&
      this.isSocialValid(twitter) &&
      this.isSocialValid(instagram) &&
      validateEmail(email) &&
      checked
    );
  }

  _handleSubmit = (event: Event) => {
    event.preventDefault();
    const { onSubmit } = this.props;
    const { 
      name, 
      email, 
      title, 
      description, 
      team, 
      level, 
      club, 
      year, 
      copyright, 
      facebook, 
      twitter, 
      instagram 
    } = this.state;
    const input: any = document.querySelector('input[type="file"]');
    var documentData: HTMLInputElement = input.files[0];

    const timestamp = getCentenaireHash();

    const data = [{
      'sportagraph_metadata_author': name,
      'sportagraph_metadata_email': email,
      'sportagraph_metadata_title': title,
      'sportagraph_metadata_description': description,
      'sportagraph_metadata_club': club,
      'sportagraph_metadata_year': year,
      'sportagraph_metadata_team': team,
      'sportagraph_metadata_level': level,
      'sportagraph_metadata_copyright_and_credits': copyright,
      'sportagraph_meta_hash': timestamp,
      'facebook': facebook ? facebook : '',
      'twitter': twitter ? twitter : '',
      'instagram': instagram ? instagram : '',
    }];
    const iv = CryptoJS.enc.Hex.parse(FORM_CRYPTO_IV);
    const data_crypt =  CryptoJS.AES.encrypt(
      JSON.stringify(data), 
      CryptoJS.enc.Hex.parse(FORM_CRYPTO_KEY), {
        iv,
        padding: CryptoJS.pad.ZeroPadding,
      }).toString();

    const data_final = {
      'data': data_crypt,
      'file_attachment': documentData,
    };

    if (this.isFormValid()) {
      if (this.isFileValid()) {
        onSubmit(data_final);
        this.setState({ formSent: true });
      }
    }
  }

  isFileValid = () => {
    // eslint-disable-next-line
    const input: any = document.querySelector('input[type="file"]');
    if (input) {
      const documentData = input.files[0];
      return documentData.size < 50000000 && 
        (
          documentData.type === 'image/jpeg' || 
          documentData.type === 'image/png' ||
          documentData.type === 'image/jpg' ||
          documentData.type === 'video/mp4' ||
          documentData.type === 'video/quicktime'
        );
    } else {
      return false;
    };
  };

  isYearValid = (value: string | null) => {
    const year = value ? parseInt(value, 10) : 0;
    return !value || (!isNaN(parseFloat(value)) && isFinite(value) && (year >= 1900 && year < 2025));
  };

  isSocialValid = (value: string | null) => {
    return !value || value === '' || (value.charAt(0) === '@' && value.length > 3);
  }

  isClubValid = (value: string | null) => {
    if (!value || value === "Équipe de France" || !this.props.clubs || this.props.clubs.length === 0)
      return true;
    const clubs_ok = this.props.clubs.filter((club) => {
      return club.name === value;
    });
    return clubs_ok.length > 0;
  }

  isTextValid = (value: string | null) => {
    return !value || !/http(s):/.test(value);
  }
  setFile = (e: any) => {
    const file = e.target.value;
    if (this.isFileValid()){
      this.setState({ file });
      this.setState({ showFileError: false });
    } else {
      let error = `Le fichier est obligatoire et doit être une image valide.`;
      const input: any = document.querySelector('input[type="file"]');
      const documentData = input.files[0];
      if (documentData.size > 50000000 ) {
        error = `La taille maximale de fichier autorisée est de 50 Mo.`;
      } else if (documentData.type !== 'image/jpeg' && 
        documentData.type !== 'image/png') {
        error = `Les types de fichiers autorisés sont PNG, JPEG.`;
      }
      this.setState({ file: '', showFileError: true, fileError: error });
    }
  };

  onFocusEmail = () => {
    this.setState({ showEmailError: false });
  }

  onBlurEmail = () => {
    this.setState({ showEmailError: true });
  }

  render() {
    const { clubs, sendStatus, displayModal } = this.props;
    const { 
      name,
      title,
      description,
      club,
      year,
      copyright,
      facebook,
      twitter,
      instagram,
      team,
      level,
      showFileError,
      fileError,
      formSent,
      captchaload
    } = this.state;
    const checkboxLabel = `Je certifie disposer des droits nécessaires pour partager et publier ce contenu et j’accepte les <a href="/cgu" target="_blank">conditions générales d’utilisation du site</a>`;
    const clubs_full = [
      {
        img:'https://api.www.ffr.fr/wp-content/uploads/2019/02/France-Italie-samedi-16-mars-2019-13-30-00.jpg', 
        name: 'Équipe de France',
        searchName: 'Équipe de France Marcoussis',
        slug: '',
        practices: [],
        lat: 0,
        long: 0,
        competitions: [],
        adress: {
          complement: '',
          street: '',
          postalCode: '',
          locality: 'Marcoussis',
        }
      }
    ].concat(clubs);
    if (sendStatus === STATUS_FAILURE) {
      displayModal(13, {}, true);
    }
    const isValid = this.isFormValid();
    return (
      <Fragment>
        { !formSent && 
        <Fragment>
          <TextInput
            id="centenaire_name"
            label="Nom *"
            placeholder="Prénom Nom"
            className={'mb-2'}
            hasError={name === ''}
            errorMessage="Ce champ est requis."
            onChange={(value) => this._handleChange('name', value)}
            />
          <div className="mb-3">
              {this.state.file !== '' ? (
                <Fragment>
                <div className={showFileError ? 
                  'input input--file input--file-centenaire has-file has-error' : 
                  'input input--file input--file-centenaire has-file'}>
                  <label htmlFor="file" style={{display: 'block'}}>
                    Changer de fichier
                  </label>
                  <input 
                    id="file_attachment"
                    name="inputFile"
                    type="file"
                    onChange={this.setFile}
                    accept="image/png, image/jpeg, image/jpg, video/mp4, video/quicktime" />
                </div>
                <p className="ft-center ft-black-secondary mt-1">
                  <i className="icon icon-valid"></i>{getFileNameFromPath(this.state.file)}
                </p>
                {showFileError && fileError && 
                  <span className="input__error">{fileError}</span>
                }
                </Fragment>
              ) : (
                <Fragment>
                  <div className={showFileError ? 
                    'input input--file input--file-centenaire has-error' : 
                    'input input--file input--file-centenaire'}>
                    <label htmlFor="file" style={{display: 'block'}}>
                      Sélectionner un fichier <small>(50 Mo max.)</small>
                    </label>
                    <input 
                      id="file"
                      name="inputFile"
                      type="file"
                      onChange={this.setFile}
                      accept="image/png, image/jpeg, image/jpg, video/mp4, video/quicktime" />
                  </div>
                  {showFileError && fileError &&
                    <span className="input__error">{fileError}</span>
                  }
                </Fragment>
              )}
            </div>
          <TextInput
            id="centenaire_titre"
            label="Titre *"
            placeholder="Match Hendaye-Dax finale fédérale 1"
            className={'mb-2'}
            hasError={title === '' || !this.isTextValid(title)}
            errorMessage={(!this.isTextValid(title) && title !== '') ? 
            "Ce champ ne peut contenir les chaines 'https://' et 'http://'" : "Ce champ est requis."}
            onChange={(value) => this._handleChange('title', value)}
            />
          <TextareaInput
            id="centenaire_description"
            label="Description *"
            placeholder="Photo de l'équipe avant la finale. De gauche à droite : Prénom Nom, Prénom Nom, Prénom Nom..."
            className={'mb-2'}
            hasError={description === '' || !this.isTextValid(description)}
            errorMessage={(!this.isTextValid(description) && description !== '') ? 
            "Ce champ ne peut contenir les chaines 'https://' et 'http://'" : "Ce champ est requis."}
            onChange={(value) => this._handleChange('description', value)}
            />
          <DropdownClubInput
            id="centenaire_club"
            label="Nom du club *"
            className={'mb-2'}
            options={clubs_full}
            placeholder="Stade hendayais"
            hasError={club === '' || !this.isClubValid(club)}
            errorMessage={(!this.isClubValid(club) && club !== '') ? 
            "Veuillez sélectionner un club valide." : "Ce champ est requis."}
            inputChanged={(value) => this._handleChange('club', value)}
            onBlur={(value) => this._handleChange('club', value)}
            />
          <TextInput
            id="centenaire_annee"
            label="Année *"
            placeholder="2015"
            className={'mb-2'}
            hasError={year === '' || !this.isYearValid(year)}
            errorMessage={(!this.isYearValid(year) && year !== '') ? 
              "L'année n'est pas valide" : "Ce champ est requis."}
            onChange={(value) => this._handleChange('year', value)}
            />
          <SelectInput
              isLight
              placeholder="Équipe *"
              className={'mb-2'}
              options={[
                { label: 'Équipe indéterminée', value: 'Équipe indéterminée' },
                { label: 'Senior masculin', value: 'Senior masculin' },
                { label: 'Senior féminine', value: 'Senior féminine' },
                { label: 'Cadet masculin', value: 'Cadet masculin' },
                { label: 'Cadet féminine', value: 'Cadet féminine' },
                { label: 'École de rugby', value: 'École de rugby' }
              ]}
              onChange={(value) => this._handleChange('team', value)}
              hasError={team === ''}
              errorMessage={'Ce champ est requis'}
              />
          <SelectInput
              isLight
              className={'mb-2'}
              placeholder="Niveau *"
              options={[
                { label: 'Niveau indéterminé', value: 'Niveau indéterminé' },
                { label: 'International', value: 'International' },
                { label: 'Fédérale 1', value: 'Fédérale 1' },
                { label: 'Fédérale 2', value: 'Fédérale 2' },
                { label: 'Fédérale 3', value: 'Fédérale 3' },
                { label: 'Honneur', value: 'Honneur' },
                { label: '1ère série', value: '1ère série' },
                { label: '2ème série', value: '2ème série' },
                { label: '3ème série', value: '3ème sériey' },
                { label: '4ème série', value: '4ème série' },
                { label: 'Élite 1', value: 'Élite 1' },
                { label: 'Élite 2', value: 'Élite 2' },
                { label: 'Fédérale 1 féminine', value: 'Fédérale 1 féminine' },
                { label: 'Fédérale 2 féminine', value: 'Fédérale 2 féminine' },
                { label: 'Reichel', value: 'Reichel' },
                { label: 'Crabos', value: 'Crabos' },
                { label: 'Balandrade', value: 'Balandrade' },
                { label: 'Philiponneau', value: 'Philiponneau' },
                { label: 'Alamercery', value: 'Alamercery' },
                { label: 'Gauderman', value: 'Gauderman' },
                { label: 'Teulières', value: 'Teulières' },
              ]}
              onChange={(value) => this._handleChange('level', value)}
              hasError={level === ''}
              errorMessage={'Ce champ est requis'}
              />
          <TextInput
            id="centenaire_club"
            label="Copyright - Auteur *"
            placeholder="© Prénom Nom"
            className={'mb-3'}
            hasError={copyright === '' || !this.isTextValid(copyright)}
            errorMessage={(!this.isTextValid(copyright) && copyright !== '') ? 
            "Ce champ ne peut contenir les chaines 'https://' et 'http://'" : "Ce champ est requis."}
            onChange={(value) => this._handleChange('copyright', value)}
            />
          <TextInput
            id="centenaire_ig"
            label="Instagram"
            placeholder="@francerugby"
            hasError={instagram !== null && (instagram !== '' && (instagram.charAt(0) !== '@' || instagram.length < 4))}
            errorMessage={ "Veuillez vérifier votre saisie." }
            className={'mb-2'}
            onChange={(value) => this._handleChange('instagram', value)}
            />
          <TextInput
            id="centenaire_fb"
            label="Facebook"
            placeholder="@FranceRugby"
            hasError={facebook !== null && (facebook !== '' && (facebook.charAt(0) !== '@' || facebook.length < 4))}
            errorMessage={ "Veuillez vérifier votre saisie." }
            className={'mb-2'}
            onChange={(value) => this._handleChange('facebook', value)}
            />
          <TextInput
            id="centenaire_tw"
            label="Twitter"
            placeholder="@FranceRugby"
            hasError={twitter !== null && (twitter !== '' && (twitter.charAt(0) !== '@' || twitter.length < 4))}
            errorMessage={ "Veuillez vérifier votre saisie." }
            className={'mb-2'}
            onChange={(value) => this._handleChange('twitter', value)}
            />
          <CheckboxInput
            label={checkboxLabel}
            id='centenaire_checked'
            className='checkbox checkbox--gold mb-4'
            onChange={(checked: boolean) => this.setState({ checked }) } />
          { captchaload && 
            <ReCAPTCHA
              ref={this._reCaptchaRef}
              sitekey={KEY_RECAPTCHA}
              onChange={this._handleCaptchaChange}
              onExpired={this._handleCaptchaChange}
              className={'mb-2'}
              asyncScriptOnLoad={this._asyncCaptchaScriptOnLoad}
            />
          }
        </Fragment> }
        {formSent ?
          <Loading isWhite style={{width: '100px', margin: '0 auto'}} /> :
          <button
          onClick={this._handleSubmit}
          className='btn btn--primary btn--gold btn--full'
          disabled={!isValid}
          type='submit'>
            Confirmer
        </button>}
      </Fragment>
    );
  }
}

export default ModalFormCentenaire;
