// @flow
import React, { Fragment, PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';
import { Link } from 'react-router-dom';
import { sendTags } from 'utils/tagManagerUtils';

export type StateProps = {
  query: string,
  queryCentenaire: string,
};

export type DispatchProps = {
  setQuery: (query: string) => void,
  setQueryCentenaire: (query: string) => void,
};

type Props = {
  changeOnEnter?: boolean,
  changeOnInput?: boolean,
  style?: string,
  children?: any
} & StateProps &
  DispatchProps;

type State = {
  open: boolean
};

class FilterOptions extends PureComponent<Props, State> {
  props: Props;
  static defaultProps = {
    changeOnInput: true,
    changeOnEnter: false,
    style: 'normal'
  };
  state: State = {
    open: false
  };

  toggleMenu = () => {
    this.setState({ open: !this.state.open });
  };

  handleInputChanged = (e: any) => {
    const key = e ? e.key : false;
    if (this.props.changeOnInput || 
      (this.props.changeOnEnter && key === 'Enter')
      || e.target.value.toLowerCase() === '') {
      const query = e ? e.target.value.toLowerCase() : '';
      if (this.props.style === 'centenaire') {
        this.props.setQueryCentenaire(query ? query : '*');
        sendTags(
          'recherche_centenaire',
          'recherche',
          query ? query : '*'
        );
      } else {
        this.props.setQuery(query);
      }
    } 
  };

  render() {
    const classNamesFilterButton = dynamicClassName('btn btn--white filter-option__cta js-showFilter ft-up');
    const classNamesFilterSection = dynamicClassName('filter');
    this.props.style === 'centenaire' && classNamesFilterSection.add('filter--centenaire');
    this.state.open && classNamesFilterButton.add('is-active');

    return (
      <Fragment>
        <section className={classNamesFilterSection.build()}>
          <div className="filter__wrapper">
            <div className="input input--search " data-overlay="false">
              <input
                type="search"
                id="form_recherche_filtre"
                placeholder="Recherche"
                defaultValue={this.props.style === 'centenaire' ? 
                  (this.props.queryCentenaire === '*' ? '' : this.props.queryCentenaire) : this.props.query}
                onChange={this.handleInputChanged}
                onKeyPress={this.handleInputChanged}
              />
              <label htmlFor="form_recherche_filtre">Recherche</label>
            </div>
            <div className="filter-option filter-option--mobile">
              <button
                className={classNamesFilterButton.build()}
                type="button"
                data-label={this.state.open ? 'Fermer' : 'Filtres'}
                onClick={this.toggleMenu}
              >
                {this.state.open ? 'Fermer' : 'Filtres'}
              </button>
              <div className="filter-option__list">
                {this.props.children}

                <div className="filter-option__action">
                  <button className="btn btn--white">
                    <i className="icon icon-sync" />
                  </button>
                  <Link to="#" className="btn btn--primary js-showFilter" onClick={() => this.toggleMenu()}>
                    <i className="icon icon-valid is-inline" />
                    <span>Appliquer</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {this.props.style === 'centenaire' &&
          <div className="overlay"></div>
        }
      </Fragment>
    );
  }
}

export default FilterOptions;
