// @flow

import type { SettingsType } from 'types/Settings';

export const APP_SET_SHOW_COOKIE_INFO = 'APP_SET_SHOW_COOKIE_INFO';

export const APP_SET_ACCEPT_COOKIE = 'APP_SET_ACCEPT_COOKIE';
export const APP_SET_PROFILE_LATER = 'APP_SET_PROFILE_LATER';
export const APP_SET_REMATCH_VOTE = 'APP_SET_REMATCH_VOTE';
export const APP_USER_SUBSCRIBE_NEWSLETTER = 'APP_USER_SUBSCRIBE_NEWSLETTER';
export const APP_NEWSLETTER_INCREASE_COUNT = 'APP_NEWSLETTER_INCREASE_COUNT';
export const FETCH_SETTINGS_START = 'FETCH_SETTINGS_START';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE';

export type APP_SET_SHOW_COOKIE_INFO_ACTION = {
  type: 'APP_SET_SHOW_COOKIE_INFO',
  payload: {
    showCookieInfo: boolean
  }
};

export type APP_SET_ACCEPT_COOKIE_ACTION = {
  type: 'APP_SET_ACCEPT_COOKIE',
  payload: {
    acceptCookie: boolean
  }
};

export type APP_SET_PROFILE_LATER_ACTION = {
  type: 'APP_SET_PROFILE_LATER',
  payload: {
    profileLater: number
  }
};

export type APP_SET_REMATCH_VOTE_ACTION = {
  type: 'APP_SET_REMATCH_VOTE',
  payload: {
    rematchVote: {club: string, id: string, url: string, thumb: string}
  }
};

export type APP_USER_SUBSCRIBE_NEWSLETTER_ACTION = {
  type: 'APP_USER_SUBSCRIBE_NEWSLETTER',
  payload: {
    newsletterSubscribed: boolean
  }
};

export type APP_NEWSLETTER_INCREASE_COUNT_ACTION = {
  type: 'APP_NEWSLETTER_INCREASE_COUNT',
}

export type FETCH_SETTINGS_START_ACTION = {
  type: 'FETCH_SETTINGS_START'
};

export type FETCH_SETTINGS_FAILURE_ACTION = {
  type: 'FETCH_SETTINGS_FAILURE'
};

export type FETCH_SETTINGS_SUCCESS_ACTION = {
  type: 'FETCH_SETTINGS_SUCCESS',
  payload: {
    settings: SettingsType
  }
};

export type Action = APP_SET_SHOW_COOKIE_INFO_ACTION
                  | APP_SET_ACCEPT_COOKIE_ACTION
                  | APP_SET_PROFILE_LATER_ACTION
                  | APP_SET_REMATCH_VOTE_ACTION
                  | APP_USER_SUBSCRIBE_NEWSLETTER_ACTION
                  | FETCH_SETTINGS_START_ACTION
                  | FETCH_SETTINGS_FAILURE_ACTION
                  | FETCH_SETTINGS_SUCCESS_ACTION
                  | APP_NEWSLETTER_INCREASE_COUNT_ACTION;

export function setShowCookieInfo(showCookieInfo: boolean): APP_SET_SHOW_COOKIE_INFO_ACTION {
  return {
    type: APP_SET_SHOW_COOKIE_INFO,
    payload: { showCookieInfo }
  };
}

export function setAcceptCookie(acceptCookie: boolean): APP_SET_ACCEPT_COOKIE_ACTION {
  return {
    type: APP_SET_ACCEPT_COOKIE,
    payload: { acceptCookie }
  };
}
export function setProfileLater(profileLater: number): APP_SET_PROFILE_LATER_ACTION {
  return {
    type: APP_SET_PROFILE_LATER,
    payload: { profileLater }
  };
}
export function setRematchVote(rematchVote: {
  club: string,
  id: string,
  url: string,
  thumb: string
}): APP_SET_REMATCH_VOTE_ACTION {
  return {
    type: APP_SET_REMATCH_VOTE,
    payload: { rematchVote }
  };
}

export function userHasSubscribedNewsletter(newsletterSubscribed: boolean): APP_USER_SUBSCRIBE_NEWSLETTER_ACTION {
  return {
    type: APP_USER_SUBSCRIBE_NEWSLETTER,
    payload: { newsletterSubscribed }
  };
}

export function increaseNewsletterPopInCount(): APP_NEWSLETTER_INCREASE_COUNT_ACTION {
  return {
    type: APP_NEWSLETTER_INCREASE_COUNT,
  };
}

export function fetchSettings(): FETCH_SETTINGS_START_ACTION {
  return {
    type: FETCH_SETTINGS_START
  };
}

export function settingsFetched(
  settings: SettingsType
): FETCH_SETTINGS_SUCCESS_ACTION {
  return {
    type: FETCH_SETTINGS_SUCCESS,
    payload: { settings }
  };
}

export function settingsFetchError(): FETCH_SETTINGS_FAILURE_ACTION {
  return {
    type: FETCH_SETTINGS_FAILURE
  };
}
