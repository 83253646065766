
// @flow

function ucwords (str: string) {
    return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
        return $1.toUpperCase();
    });
}

export function ffrClubName(club: string) {
    return club ? ucwords(club.toLowerCase()).split('(')[0]
      .replace('Xv ', 'XV ')
      .replace(' Xv', ' XV')
      .replace('A C B B ', 'ACBB ')
      .replace(' A C', ' AC')
      .replace('A C ', 'AC ')
      .replace('A S M ', 'ASM ')
      .replace('A S ', 'AS ')
      .replace('As ', 'AS ')
      .replace(' De ', ' de ')
      .replace('J A ', 'JA ')
      .replace('U M S', 'UMS ')
      .replace('U S ', 'US ')
      .replace(' U S', ' US')
      .replace('F C ', 'FC ')
      .replace('S C O R C', 'SCORC')
      .replace('S C U F', 'SCUF')
      .replace('S C ', 'SC ')
      .replace('Rc ', 'RC ')
      .replace('R C ', 'RC ')
      .replace(' R C', ' RC')
      .replace(' Rc ', ' RC ')
      .replace('C S ', 'CS ')
      .replace('S A ', 'SA ')
      .replace('C A ', 'CA ')
      .replace('C R ', 'CR ')
      .replace('Nicois', 'Niçois')
      .replace('Francais ', 'Français ')
      .replace('Cote D Or', 'Côte d\'Or')
      .replace('Federale', 'Fédérale').trim() : '';
}

export function ffrUserName(name: string) {
    const nom = name.toLowerCase().replace('-',' _-_ ');
    return ucwords(nom).replace('Xv ', 'XV ').replace('A S M', 'ASM').replace('As ', 'AS ').replace(' De ', ' de ').replace(' _-_ ', '-').trim();
}

export const removeAccents = (s: string) => {
    return s.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};