// @flow
import React, { PureComponent } from 'react';
import Routes from './../../routes';
import * as Sentry from '@sentry/browser';

import { withHelmet } from 'infrastructure/seo/Helmet';
import Partners from 'containers/footer/PartnersContainer';
import CookieContainer from 'containers/footer/CookieContainer';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import type { FullUserType } from 'types/User';
import type { EditProfileValueType } from 'types/User';
import type { FaviconType } from 'types/Settings';
import ModalContainer from 'containers/fragments/ModalContainer';

import {
  headerBlacklistWithSlugPaths,
  footerBlacklistWithSlugPaths,
  footerBlacklistPaths
} from 'constants/menuConstants';

type HistoryProps = {
  history: any
};

export type StateProps = {
  favicons: FaviconType,
  titleLigue: string,
  linkPreview: string,
  newsletterPopInCount: number,
  newsletterSubscribed: boolean,
  userPref: FullUserType,
  error: string,
  acceptCookie: boolean,
};

export type DispatchProps = {
  fetchSettings: () => void,
  fetchMenu: () => void,
  fetchCategories: () => void,
  fetchPartners: () => void,
  fetchPageDetailPreview: (id: number) => void,
  increaseNewsletterPopInCount: () => void,
  displayModal: (modalNb: number, content: Object) => void,
  postUserPref: (action: string, value: EditProfileValueType, token: string) => void,
};

type Props = {
  location: any,
  acceptCookies: ()=> void,
} & DispatchProps &
  StateProps &
  HistoryProps;

let TIMEOUT = 3000;
let INTERVAL = 300000;

class App extends PureComponent<Props> {

  timeout: TimeoutID;
  interval: IntervalID;

  displayModalNewsletterIfNotFocused = () => {
    const { displayModal } = this.props;
    if (!window.inputFocused) {
      displayModal(8, {});
    }
  }

  onInputEvent = (e: FocusEvent) => {
    if (e.type === 'focus') {
      window.inputFocused = true;
    } else if (e.type === 'blur') {
      window.inputFocused = false;
    }
  }

  newsletterModalAutoDisplays = () => {
    const { newsletterPopInCount, newsletterSubscribed } = this.props;

    if (newsletterPopInCount < 1 && !newsletterSubscribed) {
      this.timeout = setTimeout(() => this.displayModalNewsletterIfNotFocused(), TIMEOUT);      
    } 
  }

  componentDidMount() {
    const {acceptCookie, acceptCookies } = this.props;
    const inputs = document.querySelectorAll('input');
    if(acceptCookie) {
      acceptCookies();
    }

    /**
     * Est -on en mode preview
     */
    if (this.props.location.search && /preview=true/.test(this.props.location.search)) {
      const param = this.props.location.search.replace('&preview=true', '');
      if (/\?p=/.test(this.props.location.search)) {
        /**
         * Une actualité
         */
        this.props.history.push('/actualites/preview/post' + param);
        window.location.reload();
      } else if (/\?page_id=/.test(this.props.location.search)) {
        /**
         * Une page
         */
        if (this.props.linkPreview && this.props.location.pathname !== this.props.linkPreview) {
          window.location.href = this.props.linkPreview;
        } else {
          let id = param;
          id = id.replace('?page_id=', '');
          this.props.fetchPageDetailPreview(parseInt(id, 10));
        }
      }
    }

    inputs.forEach(input => {
      input.setAttribute('data-mounted', '1');
      input.addEventListener('focus', this.onInputEvent, false);
      input.addEventListener('blur', this.onInputEvent, false);
    });

    this.newsletterModalAutoDisplays();
    this.props.fetchSettings();
    this.props.fetchMenu();
    this.props.fetchCategories();
    this.props.fetchPartners();
  }

  componentDidUpdate(prevProps: Props) {
    const { newsletterPopInCount, userPref: { identifie }, error, newsletterSubscribed, linkPreview } = this.props;
    const { newsletterPopInCount: prevCount } = prevProps;
    const favCategories = localStorage.getItem('favCategories');
    
    if (favCategories && identifie && error !== 'rest_logged_user') {
        this.props.postUserPref('change-newsletter', favCategories.split(','), this.props.userPref.token);
        localStorage.removeItem('favCategories');
    }

    if (newsletterPopInCount > 0 && newsletterPopInCount !== 2 && !newsletterSubscribed) {
      this.interval = setInterval(() => this.displayModalNewsletterIfNotFocused(), INTERVAL);
    };

    if (prevProps !== this.props) {
      const inputs = document.querySelectorAll('input');
      inputs.forEach(input => {
        const isMounted = input.getAttribute('data-mounted') === '1';
        if (!isMounted) {
          input.setAttribute('data-mounted', '1');
          input.addEventListener('focus', this.onInputEvent, false);
          input.addEventListener('blur', this.onInputEvent, false);
        }
      });
    }

    if (prevCount !== newsletterPopInCount && newsletterPopInCount === 1) {
      clearTimeout(this.timeout);
    } else if (prevCount !== newsletterPopInCount && newsletterPopInCount === 2) {
      clearInterval(this.interval);
    }
    if (
      /\?page_id=/.test(this.props.location.search) &&
      linkPreview !== '' &&
      prevProps.linkPreview !== linkPreview
    ) {
      window.location.href = this.props.linkPreview;
    } else {
      const { pathname } = this.props.location;
      const splited = pathname.split('/');
      if (splited[1] === 'faq' || splited[1] === 'contact') {
        if (!splited[2]) {
          window.scrollTo(0, 0);
        } else {
          window.scrollTo(0, 250);
        }
      } else if (splited[1] === 'actualites' || (splited[4] && (splited[4] === 'joueurs' || splited[4] === 'staff'))) {
        window.scrollTo(0, 0);
      } else if (!splited[3] && splited[2] === 'carriere') {
        if (prevProps.location.pathname === '/ffr/carriere/offres') {
          window.scrollTo(0, 450);
        } else {
          window.scrollTo(0, 0);
        }
      } else if (!splited[4] && splited[3] && splited[2] === 'carriere') {
        if (prevProps.location.pathname === '/ffr/carriere/offres') {
          window.scrollTo(0, 450);
        } else {
          window.scrollTo(0, 0);
        }
      } else if (splited[1] === 'evenements') {
        const prevSplited = prevProps.location.pathname.split('/');
        if (prevSplited[1] === 'evenements') {
          window.scrollTo(0, 450);
        } else {
          window.scrollTo(0, 0);
        }
      } else if (prevProps.location.pathname.split('/')[1] === 'recherche' && splited[1] === 'recherche') {
        window.scrollTo(0, 350);
      } else if (splited[4] !== 'joueurs' && splited[4] !== 'staff') {
        window.scrollTo(0, 0);
      }
    }
  }

  renderFooter() {
    if (footerBlacklistPaths.includes(this.props.location.pathname)) return null;
    else if (footerBlacklistWithSlugPaths.includes(this.props.location.pathname.split('/')[1])) return null;
    return (
      <React.Fragment>
        <Partners />
        <Footer />
      </React.Fragment>
    );
  }
  
  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
    if (process.env.NODE_ENV === 'production') {
      this.props.history.push('/404');
      window.location.reload();
    }
  }

  renderHeader() {
    if (headerBlacklistWithSlugPaths.includes(this.props.location.pathname.split('/')[1])) return null;
    return <Header />;
  }

  componentWillUnmount() {
    const inputs = document.querySelectorAll('input');
    inputs.forEach(input => {
      input.removeEventListener('focus', this.onInputEvent, false);
      input.removeEventListener('blur', this.onInputEvent, false);
    });
  }

  render() {
    return (
      <React.Fragment>
        <ModalContainer />
        {this.renderHeader()}
        <Routes />
        <CookieContainer acceptCookies={this.props.acceptCookies} />
        {this.renderFooter()}
      </React.Fragment>
    );
  }
}

// [SEO] Set default meta tags here !
function renderHelmetDefault(Helmet, pageProps) {
  return (
    <Helmet titleTemplate={`%s`} defaultTitle={pageProps.titleLigue ? pageProps.titleLigue : window.location.href}>
      <link rel="shortcut icon" href={pageProps.favicons['favicon-32']} />
      <link rel="apple-touch-icon" sizes="180x180" href={pageProps.favicons['favicon-180']} />
      <link rel="icon" type="image/png" sizes="32x32" href={pageProps.favicons['favicon-32']} />
      <link rel="icon" type="image/png" sizes="16x16" href={pageProps.favicons['favicon-16']} />
    </Helmet>
  );
}

export default withHelmet(App)(renderHelmetDefault);
