// @flow
import Modal from 'components/fragments/Modal';
import { connect } from 'react-redux';

import type { DispatchType, StateType } from 'types/Actions';
import type { DispatchProps, StateProps } from 'components/fragments/Modal';
import type { EditProfileValueType } from 'types/User';
import { hideModal } from 'actions/modalActions';
import { displayModal } from 'actions/modalActions';
import { postUserPref } from 'actions/userActions';

export default connect(
  (state: StateType): StateProps => ({
    modalNb: state.modalState.modalNb,
    titleLigue: state.settingsState.title,
    content: state.modalState.content,
    userPref: state.userState.userPref,
    push_rencontre: state.settingsState.push_rencontre,
    showCookieInfo: state.appState.showCookieInfo,
    login_url: state.settingsState.login_url,
    logout_url: state.settingsState.logout_url,
  }),
  (dispatch: DispatchType): DispatchProps => ({
    hideModal: () => dispatch(hideModal()),
    postUserPref: (action: string, id: EditProfileValueType, token: string) => dispatch(postUserPref(action, id, token)),
    displayModal: (modalNb: number) => dispatch(displayModal(modalNb)),
  })
)(Modal);
