// @flow
import type { EventMatchType, RawEventMatchType } from 'types/Event';
import type { RawNewsletterFavoritesArticleType, ArticleType } from 'types/Article';

export type UserProfile = {
  id: string,
  nom: string,
  prenom: string,
  surnom: string,
  email: string,
  fonction: string,
  genre: string,
  date_naissance: string,
  ville_naissance: string,
  telephone: string,
  situation: string,
  licence: string
};

export type LastMatchsAgregatorType = {
  id: string,
  dateEffective: string,
  Competition: {
    id: string,
    nom: string,
    identifiant: string,
  },
  CompetitionEquipeLocale: {
    id: string,
    nom: string,
    Structure?: {
      id: string,
      nom: string,
      embleme: string
    },
    Regroupement?: {
      id: string,
      nom: string,
      StructuresItem?: {
        id: string,
        nom: string,
      }[]
    }
  },
  CompetitionEquipeVisiteuse: {
    id: string,
    nom: string,
    Structure?: {
      id: string,
      nom: string,
      embleme: string
    },
    Regroupement?: {
      id: string,
      nom: string,
      StructuresItem?: {
        id: string,
        nom: string,
      }[]
    }
  },
  RencontreResultatLocale: {
    pointsDeMarque: number,
    essais: number,
    butsApresEssai: number,
    butsPenalite: number,
    dropGoals: number,
    essaisDePenalite: number,
  },
  RencontreResultatVisiteuse: {
    pointsDeMarque: number,
    essais: number,
    butsApresEssai: number,
    butsPenalite: number,
    dropGoals: number,
    essaisDePenalite: number,
  }
}
type UserType = {
  identifie: boolean,
  login: string,
  joueurs_favoris: number[],
  alertes_billetterie: number[],
  newsletter: boolean,
  avatar: string,
  profil: UserProfile,
};

type SizeType = {
  src: string,
  width: number,
  height: number,
};

export type FeaturedMediaType = {
  id: number,
  src: string,
  title: string,
  legend: string,
  alt: string,
  width: number,
  height: number,
  sizes: {
    thumbnail: SizeType,
    medium: SizeType,
    medium_large: SizeType,
    large: SizeType,
  },
};

export type JoueurFavorisType = {
  id: number,
  title: string,
  equipe: string,
  link: string,
  featured_media: FeaturedMediaType,
  poste: {
    id: number,
    name: string,
  },
};

export type MyClubType = {
  id: number,
  nom: string,
  telephone: string,
  website: string,
  email: string,
  adresse: string,
  ville: string,
  code_postal: string,
  featured_media: string,
  id_competition?: string,
  clubs_ligue?: number,
  licencies?: number,
  clubs_importants?: any[],
  rs?: {
    facebook?: string,
    twitter?: string,
  },
  stade?: string,
  competitions?: any[],
  last_results?: LastMatchsAgregatorType[],
  last_article?: any,
  next_event?: any,
  link_news?: string,
  link_events?: string,
};

export type NewsletterContenuType = {
  [key: number] : string,
};

export type ClubActuelType = {
  id: string,
  code : string,
  nom: string,
}

type Sexe = {
  code: string,
  id: string,
  nom: string,
}

type ClasseAge = {
  Sexe: Sexe,
  code: string,
  id: string,
  nom: string,
};


type FullScoreType = {
  bonusDefensif: number,
  bonusOffensif: number,
  essaisConcedes: number,
  essaisMarques: number,
  gagnes: number,
  goalAverage: number,
  joues: number,
  nuls: number,
  perdus: number,
  pointTerrain: number,
  pointsDeMarqueAquis: number,
  pointsDeMarqueConcedes: number,
  position: number,
  regulationPointsTerrain: number | null,
};

type StructureType = {
  code_postal: string,
  couleurs: string,
  est_rassemblement: boolean,
  id: number,
  identifiant: string,
  logo: boolean,
  nom: string,
  region?: string,
  regroupementsParticipants?: any[],
  ville: string,
};

type ClassementType = {
  poule_id: number,
  poule_rang: number,
  score: FullScoreType,
  structure: StructureType,
  structure_id: number,
};

type ClassementAgregatorType = {
  positionIntraPoule: number,
  positionInterPoule: number,
  id: string,
  Equipe: {
    nom: string,
    identifiant: string,
    Structure?: {
      id: string,
      nom: string,
      embleme: string,
    },
    Regroupement?: {
      id: string,
      embleme: string,
      StructuresItem: {
        id: string,
        nom: string,
      }[]
    }
  }[],
  Classement: {
    gagnes: number,
    joues: number,
    nuls: number,
    perdus: number,
    pointTerrain: number,
  }
}

export type ClassementIntraAgregatorType = {
  id: string,
  ordrePoule: number,
  pouleId: string,
  position: number,
  Classement: {
    gagnes: number,
    joues: number,
    nuls: number,
    perdus: number,
    pointTerrain: number,
  },
  Classements: {
    position: number,
    Equipe : {
      nom: string,
      identifiant: string,
      Structure?: {
        id: string,
        nom: string,
        embleme: string,
      },
      Regroupement?: {
        id: string,
        embleme: string,
        StructuresItem: {
          id: string,
          nom: string,
        }[]
      }
    },
    Classement: {
      gagnes: number,
      joues: number,
      nuls: number,
      perdus: number,
      pointTerrain: number,
    }
  }[],
  dernieresRencontresPoule: LastMatchsAgregatorType[]
}

type CompetitionsPhases = {
  classement?: ClassementType[],
  id: string,
  nom: string,
  nombrePoules: number,
  CompetitionPhaseType: {id: string, nom: string  }
};

type LastMatchsType = {
  competition: string,
  date: string,
  id: number,
  journee: string,
  local_structure: StructureType,
  score_structure_locale: number,
  score_structure_visiteur: number,
  statut: string,
  terrain: {
    nom: string,
    lat: number,
    lng: number,
  },
  visitor_structure: StructureType,
}


export type UserTeamPoints = {
  structureId?: number,
  nom: string,
  embleme: string,
  totalpointdemarque: string
}


type UserCompetitionType = {
  id: string,
  nom: string,
  nomCategorie: string,
  currentPhaseId: string,
  idCategorie: string,
  identifiant: string,
  identifiantCategorie: string,
  nomCourt: string,
  classeAge: ClasseAge,
  Famille: {id: string, nom: string},
  classement_club: ClassementAgregatorType,
  classement_club_intra: ClassementIntraAgregatorType,
  Phases: CompetitionsPhases[],
  rencontres_club: LastMatchsAgregatorType[],
  Equipes: {
    id: string,
    nom: string,
    CompetitionPoule: {id: string, ordre: number},
    Statistique: {
      pointsMarques: number,
      pointsEncaisses: number,
    },
    Structure?: {
      id: string,
      nom: string,
      embleme: string,
    },
    Regroupement?: {
      id: string,
      nom: string,
      embleme: string,
      StructuresItem?: {
        id: string,
        nom: string,
        embleme: string,
      }[]
    }
  }[],
  pointsMarquesParClub: UserTeamPoints[],
  pointsEncaissesParClub: UserTeamPoints[],
}
type UserTeamType = {
  competitions: UserCompetitionType[],
  derniers_matchs : LastMatchsType[],
};

export type FavoriteClubsType = {
  fav_competition: null,
  id: number,
  rang: number,
  structure: StructureType,
}

export type NewsletterThemesType = {
  [key: string] : string,
};


export type FullUserType = {
  club: MyClubType,
  licence: string,
  licenceefunctions: string,
  ligue: MyClubType,
  newsletter_contenu: string[],
  newsletter_ids: number[],
  newsletter_themes: NewsletterThemesType,
  joueurs_favoris_detail: JoueurFavorisType[],
  alertes_billetterie_detail: EventMatchType[],
  newsletter_feed: ArticleType[],
  annees_pratiques: number,
  historique_pratiques: any[],
  club_actuel: ClubActuelType,
  clubs_pratiques: any[],
  is_player: boolean,
  is_player_club?: string,
  is_dirigeant: boolean,
  profile: string,
  mon_equipe: UserTeamType,
  competitions_clubs_favoris: FavoriteClubsType[],
  url_change_pass: string,
  url_lier_ovale: string,
  token: string,
} & UserType;

export type RawFullUserType = {
  club: MyClubType,
  licence: string,
  licenceefunctions: string,
  ligue: MyClubType,
  newsletter_contenu: string[],
  newsletter_ids: number[],
  newsletter_themes: NewsletterThemesType,
  joueurs_favoris_detail: JoueurFavorisType[],
  alertes_billetterie_detail: RawEventMatchType[],
  newsletter_feed: RawNewsletterFavoritesArticleType[],
  annees_pratiques: number,
  historique_pratiques: any[],
  club_actuel: ClubActuelType,
  clubs_pratiques: any[],
  is_player: boolean,
  is_player_club?: string,
  is_dirigeant: boolean,
  profile: string,
  mon_equipe: UserTeamType,
  competitions_clubs_favoris: FavoriteClubsType[],
  url_change_pass: string,
  url_lier_ovale: string,
  token: string,
} & UserType;

export type EditProfileValueType =
  | UserProfile
  | string[]
  | string;

export const nullUserPrefs: FullUserType = {
  identifie: false,
  licence: '',
  licenceefunctions: '',
  login: '',
  url_change_pass: '',
  url_lier_ovale: '',
  token: '',
  joueurs_favoris: [],
  alertes_billetterie: [],
  newsletter: false,
  avatar: '',
  profil: {
    id: '',
    nom: '',
    prenom: '',
    surnom: '',
    email: '',
    fonction: '',
    genre: '',
    date_naissance: '',
    ville_naissance: '',
    situation: '',
    telephone: '',
    licence: '',
  },
  annees_pratiques: 0,
  historique_pratiques: [],
  club_actuel: {
    id: '',
    code: '',
    nom: '',
  },
  clubs_pratiques: [],
  club: {
    id: 0,
    nom: '',
    telephone: '',
    website: '',
    email: '',
    adresse: '',
    ville: '',
    code_postal: '',
    featured_media: '',
    last_results: []
  },
  ligue: {
    id: 0,
    nom: '',
    telephone: '',
    website: '',
    email: '',
    adresse: '',
    ville: '',
    code_postal: '',
    featured_media: '',
  },
  newsletter_contenu: [],
  newsletter_ids: [],
  newsletter_themes: {},
  joueurs_favoris_detail: [],
  alertes_billetterie_detail: [],
  newsletter_feed: [],
  is_player: false,
  is_player_club: '',
  is_dirigeant: false,
  profile: 'simple',
  mon_equipe: {
    competitions: [],
    competition: {
      age: '',
      competition_type: {
        genre: 0,
        nom_famille: '',
        nom_age: '',
        nom: '',
      },
      id: 0,
      identifiant: '',
      nom: '',
      phases: {
        ClassementsIntraPoule: [],
        ClassementsInterPoule: [],
        id: 0,
        nom: '',
        nombrePoules: '',
        CompetitionPhaseType: {
          id: 0,
          nom: ''
        }
      },
    },
    pointsMarquesParClub: [],
    pointsEncaissesParClub: [],
    derniers_matchs : [],
  },
  competitions_clubs_favoris: [],
};
